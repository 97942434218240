// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { usePersistedOperations } from '@graphql-yoga/plugin-persisted-operations';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { ProtocolTypes } from './sources/protocol/types';
import * as importedModule$0 from "./sources/protocol/introspectionSchema";
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: string; output: string; }
  BigInt: { input: string; output: string; }
  Bytes: { input: string; output: string; }
  Int8: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
};

/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type Account = {
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * Indicates whether the address/account is a super app.
   *
   */
  isSuperApp: Scalars['Boolean']['output'];
  inflows: Array<Stream>;
  outflows: Array<Stream>;
  subscriptions: Array<IndexSubscription>;
  publishedIndexes: Array<Index>;
  pools: Array<Pool>;
  poolMemberships: Array<PoolMember>;
  sentTransferEvents: Array<TransferEvent>;
  receivedTransferEvents: Array<TransferEvent>;
  tokenUpgradedEvents: Array<TokenUpgradedEvent>;
  tokenDowngradedEvents: Array<TokenDowngradedEvent>;
  accountTokenSnapshots: Array<AccountTokenSnapshot>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountInflowsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Stream_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountOutflowsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Stream_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscription_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountPublishedIndexesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Index_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Index_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountPoolsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Pool_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountPoolMembershipsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolMember_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolMember_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountSentTransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransferEvent_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountReceivedTransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransferEvent_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountTokenUpgradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenUpgradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenUpgradedEvent_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountTokenDowngradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDowngradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenDowngradedEvent_Filter>;
};


/**
 * Account: A higher order entity created for any addresses which interact with Superfluid contracts.
 *
 */
export type AccountAccountTokenSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshot_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshot_Filter>;
};

/**
 * AccountTokenSnapshot: An aggregate entity which aggregates data between an `account`'s interaction with `token`.
 *
 */
export type AccountTokenSnapshot = {
  /**
   * ID composed of: accountID-tokenID
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * isLiquidationEstimateOptimistic, If `totalSubscriptionsWithUnits > 0`, it is true.
   * "Optimistic" can be thought of as conservative as it refers to the earliest time the user may be liquidated as they may receive ongoing distributions which aren't tracked by the subgraph.
   *
   */
  isLiquidationEstimateOptimistic: Scalars['Boolean']['output'];
  /**
   * Optimistic liquidation estimation property.
   *
   */
  maybeCriticalAtTimestamp?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The count of currently open streams for an account, both incoming and outgoing for all agreements.
   *
   */
  totalNumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of currently open streams for an account, both incoming and outgoing for the CFA.
   *
   */
  totalCFANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of currently open streams for an account, both incoming and outgoing for the GDA.
   *
   */
  totalGDANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account for all agreements.
   *
   */
  activeOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account for the CFA.
   *
   */
  activeCFAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account for the GDA.
   *
   */
  activeGDAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active incoming streams to this account for the CFA.
   * GDA incoming streams are *NOT* counted here.
   *
   */
  activeIncomingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed streams by `account`, both incoming and outgoing for all agreements.
   *
   */
  totalNumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams by `account`, both incoming and outgoing for the CFA.
   *
   */
  totalCFANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams by `account`, both incoming and outgoing for the GDA.
   *
   */
  totalGDANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for all agreements.
   *
   */
  inactiveOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for the CFA.
   *
   */
  inactiveCFAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for the GDA.
   *
   */
  inactiveGDAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed incoming streams by `account` for the CFA.
   * Close incoming GDA streams are *NOT* counted here.
   *
   */
  inactiveIncomingStreamCount: Scalars['Int']['output'];
  /**
   * The current (as of updatedAt) number of subscriptions with units allocated to them tied to this `account`.
   *
   */
  totalSubscriptionsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all currently (as of updatedAt) approved subscriptions whether or not they have units.
   *
   */
  totalApprovedSubscriptions: Scalars['Int']['output'];
  /**
   * The current (as of updatedAt) number of membership with units allocated to them tied to this `account`. (both IDA and GDA)
   *
   */
  totalMembershipsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all currently (as of updatedAt) approved membership whether or not they have units. (both IDA and GDA)
   *
   */
  totalConnectedMemberships: Scalars['Int']['output'];
  /**
   * Counts how many pools the account is a pool admin of. The pool admin can be set arbitrarily when creating a GDA pool. The pool admin might receive an "adjustment flow" if the pool has a flow distribution.
   *
   */
  adminOfPoolCount: Scalars['Int']['output'];
  /**
   * Balance of `account` as of `updatedAtTimestamp`/`updatedAtBlock`.
   *
   */
  balanceUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The last block the balance was queried from an RPC (the most accurate source for balance data).
   *
   */
  balanceLastUpdatedFromRpcBlocknumber?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The total deposit this account has held by all flow agreements for `account` active streams.
   *
   */
  totalDeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit this account has held by the CFA agreement for `account` active streams.
   *
   */
  totalCFADeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit this account has held by the GDA agreement for `account` active streams.
   *
   */
  totalGDADeposit: Scalars['BigInt']['output'];
  /**
   * The total net flow rate of the `account` as of `updatedAtTimestamp`/`updatedAtBlock` for all flow agreements.
   * This can be obtained by: `totalInflowRate - totalOutflowRate`.
   * NOTE: this property will NOT be 100% accurate all the time for receivers of GDA flows.
   *
   */
  totalNetFlowRate: Scalars['BigInt']['output'];
  /**
   * The total net flow rate of the `account` as of `updatedAtTimestamp`/`updatedAtBlock` for the CFA.
   *
   */
  totalCFANetFlowRate: Scalars['BigInt']['output'];
  /**
   * The total inflow rate (receive flowRate per second) of the `account` for the CFA.
   * GDA inflow rate is *NOT* included here.
   *
   */
  totalInflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate (send flowrate per second) of the `account` for all flow agreements.
   *
   */
  totalOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate (send flowrate per second) of the `account` for the CFA.
   *
   */
  totalCFAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate (send flowrate per second) of the `account` for the GDA.
   *
   */
  totalGDAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` streamed into this `account` until the `updatedAtTimestamp`/`updatedAtBlock` for the CFA.
   *
   */
  totalAmountStreamedInUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` streamed from this `account` until the `updatedAtTimestamp`/`updatedAtBlock` for all flow agreements.
   *
   */
  totalAmountStreamedOutUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` streamed from this `account` until the `updatedAtTimestamp`/`updatedAtBlock` for the CFA.
   *
   */
  totalCFAAmountStreamedOutUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` streamed through this `account` until the `updatedAtTimestamp`/`updatedAtBlock` for all flow agreements.
   *
   */
  totalAmountStreamedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` streamed through this `account` until the `updatedAtTimestamp`/`updatedAtBlock` for the CFA.
   *
   */
  totalCFAAmountStreamedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total amount of `token` this `account` has transferred.
   *
   */
  totalAmountTransferredUntilUpdatedAt: Scalars['BigInt']['output'];
  account: Account;
  token: Token;
  flowOperators: Array<FlowOperator>;
  accountTokenSnapshotLogs: Array<AccountTokenSnapshotLog>;
};


/**
 * AccountTokenSnapshot: An aggregate entity which aggregates data between an `account`'s interaction with `token`.
 *
 */
export type AccountTokenSnapshotFlowOperatorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperator_Filter>;
};


/**
 * AccountTokenSnapshot: An aggregate entity which aggregates data between an `account`'s interaction with `token`.
 *
 */
export type AccountTokenSnapshotAccountTokenSnapshotLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshotLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshotLog_Filter>;
};

/**
 * AccountTokenSnapshotLog: Historical entries of `AccountTokenSnapshot` updates.
 *
 */
export type AccountTokenSnapshotLog = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['BigInt']['output'];
  blockNumber: Scalars['BigInt']['output'];
  transactionHash: Scalars['Bytes']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  triggeredByEventName: Scalars['String']['output'];
  /**
   * Optimistic liquidation estimation property.
   *
   */
  maybeCriticalAtTimestamp?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The current (as of timestamp) number of open streams for all agreements.
   *
   */
  totalNumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) number of open streams.
   *
   */
  totalCFANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) number of open streams.
   *
   */
  totalGDANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account for all agreements.
   *
   */
  activeOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account.
   *
   */
  activeCFAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active outgoing streams from this account.
   *
   */
  activeGDAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of active incoming streams to this account for all agreements.
   *
   */
  activeIncomingStreamCount: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) count of closed streams for all agreements.
   *
   */
  totalNumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) count of closed streams for the CFA.
   *
   */
  totalCFANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) count of closed streams for the GDA.
   *
   */
  totalGDANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for all agreements.
   *
   */
  inactiveOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for the CFA.
   *
   */
  inactiveCFAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed outgoing streams by `account` for the GDA.
   *
   */
  inactiveGDAOutgoingStreamCount: Scalars['Int']['output'];
  /**
   * The count of closed incoming streams by `account` for the CFA.
   * Close incoming GDA streams are *NOT* counted here.
   *
   */
  inactiveIncomingStreamCount: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) number of subscriptions with units allocated to them tied to this `account`.
   *
   */
  totalSubscriptionsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all currently (as of timestamp) approved subscriptions whether or not they have units.
   *
   */
  totalApprovedSubscriptions: Scalars['Int']['output'];
  /**
   * The current (as of timestamp) number of membership with units allocated to them tied to this `account`.
   *
   */
  totalMembershipsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all currently (as of timestamp) connected membership whether or not they have units.
   *
   */
  totalConnectedMemberships: Scalars['Int']['output'];
  /**
   * Balance of `account` as of `timestamp`/`block`.
   *
   */
  balance: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) deposit this account has held by all flow agreements for `account` active streams.
   *
   */
  totalDeposit: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) deposit this account has held by the CFA agreement for `account` active streams.
   *
   */
  totalCFADeposit: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) deposit this account has held by the GDA agreement for `account` active streams.
   *
   */
  totalGDADeposit: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) net flow rate of the `account` as of `timestamp`/`block`.
   * This can be obtained by: `totalInflowRate - totalOutflowRate`
   *
   */
  totalNetFlowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) net flow rate of the `account` as of `timestamp`/`block` for the CFA.
   *
   */
  totalCFANetFlowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) inflow rate (receive flowRate per second) of the `account`.
   *
   */
  totalInflowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) outflow rate (send flowrate per second) of the `account`.
   *
   */
  totalOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) outflow rate (send flowrate per second) of the `account` for the CFA.
   *
   */
  totalCFAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) outflow rate (send flowrate per second) of the `account` for the GDA.
   *
   */
  totalGDAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) amount of `token` streamed into this `account` until the `timestamp`/`block`.
   *
   */
  totalAmountStreamedIn: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) amount of `token` streamed from this `account` until the `timestamp`/`block`.
   *
   */
  totalAmountStreamedOut: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) amount of `token` streamed from this `account` until the `timestamp`/`block` for the CFA.
   *
   */
  totalCFAAmountStreamedOut: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) net amount of `token` streamed through this `account` until the `timestamp`/`block`.
   *
   */
  totalAmountStreamed: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) net amount of `token` streamed through this `account` until the `timestamp`/`block` for the CFA.
   *
   */
  totalCFAAmountStreamed: Scalars['BigInt']['output'];
  /**
   * The total (as of timestamp) amount of `token` this `account` has transferred out until the `timestamp`/`block`.
   *
   */
  totalAmountTransferred: Scalars['BigInt']['output'];
  account: Account;
  token: Token;
  accountTokenSnapshot: AccountTokenSnapshot;
};

export type AccountTokenSnapshotLog_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  triggeredByEventName?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_gt?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_lt?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_gte?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_lte?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  triggeredByEventName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  triggeredByEventName_contains?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_contains?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_starts_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_ends_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  maybeCriticalAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maybeCriticalAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeCFAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeCFAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeGDAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeGDAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeIncomingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeIncomingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveCFAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveCFAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveGDAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveGDAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveIncomingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveIncomingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_not?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_not?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  balance?: InputMaybe<Scalars['BigInt']['input']>;
  balance_not?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNetFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNetFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFANetFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFANetFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalInflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalInflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedIn?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedIn_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedIn_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedOut?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOut_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedOut_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedOut?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOut_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedOut_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamed?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamed?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferred?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferred_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  accountTokenSnapshot?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_gt?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_lt?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_gte?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_lte?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_in?: InputMaybe<Array<Scalars['String']['input']>>;
  accountTokenSnapshot_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  accountTokenSnapshot_contains?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_contains?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_starts_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_ends_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_?: InputMaybe<AccountTokenSnapshot_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AccountTokenSnapshotLog_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AccountTokenSnapshotLog_Filter>>>;
};

export type AccountTokenSnapshotLog_OrderBy =
  | 'id'
  | 'timestamp'
  | 'blockNumber'
  | 'transactionHash'
  | 'logIndex'
  | 'order'
  | 'triggeredByEventName'
  | 'maybeCriticalAtTimestamp'
  | 'totalNumberOfActiveStreams'
  | 'totalCFANumberOfActiveStreams'
  | 'totalGDANumberOfActiveStreams'
  | 'activeOutgoingStreamCount'
  | 'activeCFAOutgoingStreamCount'
  | 'activeGDAOutgoingStreamCount'
  | 'activeIncomingStreamCount'
  | 'totalNumberOfClosedStreams'
  | 'totalCFANumberOfClosedStreams'
  | 'totalGDANumberOfClosedStreams'
  | 'inactiveOutgoingStreamCount'
  | 'inactiveCFAOutgoingStreamCount'
  | 'inactiveGDAOutgoingStreamCount'
  | 'inactiveIncomingStreamCount'
  | 'totalSubscriptionsWithUnits'
  | 'totalApprovedSubscriptions'
  | 'totalMembershipsWithUnits'
  | 'totalConnectedMemberships'
  | 'balance'
  | 'totalDeposit'
  | 'totalCFADeposit'
  | 'totalGDADeposit'
  | 'totalNetFlowRate'
  | 'totalCFANetFlowRate'
  | 'totalInflowRate'
  | 'totalOutflowRate'
  | 'totalCFAOutflowRate'
  | 'totalGDAOutflowRate'
  | 'totalAmountStreamedIn'
  | 'totalAmountStreamedOut'
  | 'totalCFAAmountStreamedOut'
  | 'totalAmountStreamed'
  | 'totalCFAAmountStreamed'
  | 'totalAmountTransferred'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'accountTokenSnapshot'
  | 'accountTokenSnapshot__id'
  | 'accountTokenSnapshot__createdAtTimestamp'
  | 'accountTokenSnapshot__createdAtBlockNumber'
  | 'accountTokenSnapshot__updatedAtTimestamp'
  | 'accountTokenSnapshot__updatedAtBlockNumber'
  | 'accountTokenSnapshot__isLiquidationEstimateOptimistic'
  | 'accountTokenSnapshot__maybeCriticalAtTimestamp'
  | 'accountTokenSnapshot__totalNumberOfActiveStreams'
  | 'accountTokenSnapshot__totalCFANumberOfActiveStreams'
  | 'accountTokenSnapshot__totalGDANumberOfActiveStreams'
  | 'accountTokenSnapshot__activeOutgoingStreamCount'
  | 'accountTokenSnapshot__activeCFAOutgoingStreamCount'
  | 'accountTokenSnapshot__activeGDAOutgoingStreamCount'
  | 'accountTokenSnapshot__activeIncomingStreamCount'
  | 'accountTokenSnapshot__totalNumberOfClosedStreams'
  | 'accountTokenSnapshot__totalCFANumberOfClosedStreams'
  | 'accountTokenSnapshot__totalGDANumberOfClosedStreams'
  | 'accountTokenSnapshot__inactiveOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveCFAOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveGDAOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveIncomingStreamCount'
  | 'accountTokenSnapshot__totalSubscriptionsWithUnits'
  | 'accountTokenSnapshot__totalApprovedSubscriptions'
  | 'accountTokenSnapshot__totalMembershipsWithUnits'
  | 'accountTokenSnapshot__totalConnectedMemberships'
  | 'accountTokenSnapshot__adminOfPoolCount'
  | 'accountTokenSnapshot__balanceUntilUpdatedAt'
  | 'accountTokenSnapshot__balanceLastUpdatedFromRpcBlocknumber'
  | 'accountTokenSnapshot__totalDeposit'
  | 'accountTokenSnapshot__totalCFADeposit'
  | 'accountTokenSnapshot__totalGDADeposit'
  | 'accountTokenSnapshot__totalNetFlowRate'
  | 'accountTokenSnapshot__totalCFANetFlowRate'
  | 'accountTokenSnapshot__totalInflowRate'
  | 'accountTokenSnapshot__totalOutflowRate'
  | 'accountTokenSnapshot__totalCFAOutflowRate'
  | 'accountTokenSnapshot__totalGDAOutflowRate'
  | 'accountTokenSnapshot__totalAmountStreamedInUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountStreamedOutUntilUpdatedAt'
  | 'accountTokenSnapshot__totalCFAAmountStreamedOutUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountStreamedUntilUpdatedAt'
  | 'accountTokenSnapshot__totalCFAAmountStreamedUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountTransferredUntilUpdatedAt';

export type AccountTokenSnapshot_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isLiquidationEstimateOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  isLiquidationEstimateOptimistic_not?: InputMaybe<Scalars['Boolean']['input']>;
  isLiquidationEstimateOptimistic_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isLiquidationEstimateOptimistic_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  maybeCriticalAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maybeCriticalAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maybeCriticalAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeCFAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeCFAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeCFAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeGDAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeGDAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeGDAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeIncomingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  activeIncomingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  activeIncomingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveCFAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveCFAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveCFAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveGDAOutgoingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveGDAOutgoingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveGDAOutgoingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveIncomingStreamCount?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_not?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_gt?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_lt?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_gte?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_lte?: InputMaybe<Scalars['Int']['input']>;
  inactiveIncomingStreamCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  inactiveIncomingStreamCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_not?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_not?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  adminOfPoolCount?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_not?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_gt?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_lt?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_gte?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_lte?: InputMaybe<Scalars['Int']['input']>;
  adminOfPoolCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  adminOfPoolCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  balanceUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balanceUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balanceUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balanceLastUpdatedFromRpcBlocknumber?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  balanceLastUpdatedFromRpcBlocknumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  balanceLastUpdatedFromRpcBlocknumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNetFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalNetFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNetFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFANetFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFANetFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFANetFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalInflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalInflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalInflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedInUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedInUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedInUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedOutUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedOutUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedOutUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedOutUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedOutUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedOutUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferredUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferredUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  flowOperators_?: InputMaybe<FlowOperator_Filter>;
  accountTokenSnapshotLogs_?: InputMaybe<AccountTokenSnapshotLog_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AccountTokenSnapshot_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AccountTokenSnapshot_Filter>>>;
};

export type AccountTokenSnapshot_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'isLiquidationEstimateOptimistic'
  | 'maybeCriticalAtTimestamp'
  | 'totalNumberOfActiveStreams'
  | 'totalCFANumberOfActiveStreams'
  | 'totalGDANumberOfActiveStreams'
  | 'activeOutgoingStreamCount'
  | 'activeCFAOutgoingStreamCount'
  | 'activeGDAOutgoingStreamCount'
  | 'activeIncomingStreamCount'
  | 'totalNumberOfClosedStreams'
  | 'totalCFANumberOfClosedStreams'
  | 'totalGDANumberOfClosedStreams'
  | 'inactiveOutgoingStreamCount'
  | 'inactiveCFAOutgoingStreamCount'
  | 'inactiveGDAOutgoingStreamCount'
  | 'inactiveIncomingStreamCount'
  | 'totalSubscriptionsWithUnits'
  | 'totalApprovedSubscriptions'
  | 'totalMembershipsWithUnits'
  | 'totalConnectedMemberships'
  | 'adminOfPoolCount'
  | 'balanceUntilUpdatedAt'
  | 'balanceLastUpdatedFromRpcBlocknumber'
  | 'totalDeposit'
  | 'totalCFADeposit'
  | 'totalGDADeposit'
  | 'totalNetFlowRate'
  | 'totalCFANetFlowRate'
  | 'totalInflowRate'
  | 'totalOutflowRate'
  | 'totalCFAOutflowRate'
  | 'totalGDAOutflowRate'
  | 'totalAmountStreamedInUntilUpdatedAt'
  | 'totalAmountStreamedOutUntilUpdatedAt'
  | 'totalCFAAmountStreamedOutUntilUpdatedAt'
  | 'totalAmountStreamedUntilUpdatedAt'
  | 'totalCFAAmountStreamedUntilUpdatedAt'
  | 'totalAmountTransferredUntilUpdatedAt'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'flowOperators'
  | 'accountTokenSnapshotLogs';

export type Account_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isSuperApp?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperApp_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperApp_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isSuperApp_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  inflows_?: InputMaybe<Stream_Filter>;
  outflows_?: InputMaybe<Stream_Filter>;
  subscriptions_?: InputMaybe<IndexSubscription_Filter>;
  publishedIndexes_?: InputMaybe<Index_Filter>;
  pools_?: InputMaybe<Pool_Filter>;
  poolMemberships_?: InputMaybe<PoolMember_Filter>;
  sentTransferEvents_?: InputMaybe<TransferEvent_Filter>;
  receivedTransferEvents_?: InputMaybe<TransferEvent_Filter>;
  tokenUpgradedEvents_?: InputMaybe<TokenUpgradedEvent_Filter>;
  tokenDowngradedEvents_?: InputMaybe<TokenDowngradedEvent_Filter>;
  accountTokenSnapshots_?: InputMaybe<AccountTokenSnapshot_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
};

export type Account_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'isSuperApp'
  | 'inflows'
  | 'outflows'
  | 'subscriptions'
  | 'publishedIndexes'
  | 'pools'
  | 'poolMemberships'
  | 'sentTransferEvents'
  | 'receivedTransferEvents'
  | 'tokenUpgradedEvents'
  | 'tokenDowngradedEvents'
  | 'accountTokenSnapshots';

export type Aggregation_Interval =
  | 'hour'
  | 'day';

export type AgreementClassRegisteredEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `code`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  agreementType: Scalars['Bytes']['output'];
  code: Scalars['Bytes']['output'];
};

export type AgreementClassRegisteredEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  agreementType?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementType_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementType_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code?: InputMaybe<Scalars['Bytes']['input']>;
  code_not?: InputMaybe<Scalars['Bytes']['input']>;
  code_gt?: InputMaybe<Scalars['Bytes']['input']>;
  code_lt?: InputMaybe<Scalars['Bytes']['input']>;
  code_gte?: InputMaybe<Scalars['Bytes']['input']>;
  code_lte?: InputMaybe<Scalars['Bytes']['input']>;
  code_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AgreementClassRegisteredEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AgreementClassRegisteredEvent_Filter>>>;
};

export type AgreementClassRegisteredEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'agreementType'
  | 'code';

export type AgreementClassUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `code`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  agreementType: Scalars['Bytes']['output'];
  code: Scalars['Bytes']['output'];
};

export type AgreementClassUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  agreementType?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementType_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementType_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementType_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code?: InputMaybe<Scalars['Bytes']['input']>;
  code_not?: InputMaybe<Scalars['Bytes']['input']>;
  code_gt?: InputMaybe<Scalars['Bytes']['input']>;
  code_lt?: InputMaybe<Scalars['Bytes']['input']>;
  code_gte?: InputMaybe<Scalars['Bytes']['input']>;
  code_lte?: InputMaybe<Scalars['Bytes']['input']>;
  code_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AgreementClassUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AgreementClassUpdatedEvent_Filter>>>;
};

export type AgreementClassUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'agreementType'
  | 'code';

/**
 * NOTE: This event was deprecated since the introduction of the 3Ps system.
 * Replaced by: `AgreementLiquidatedV2Event`
 * See: https://docs.superfluid.finance/superfluid/sentinels/liquidations-and-toga#patricians-plebs-and-pirates-3ps for more details on the 3Ps system.
 * See: https://github.com/superfluid-finance/protocol-monorepo/blob/dev/packages/ethereum-contracts/contracts/interfaces/superfluid/ISuperfluidToken.sol#L425 for more details on the events.
 *
 */
export type AgreementLiquidatedByEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = liquidatorAccount (executor of liquidation)
   * addresses[2] = penaltyAccount (the sender of the flow/stream)
   * addresses[3] = bondAccount (the address receiving the reward - the reward account for the token, pre 3Ps)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  liquidatorAccount: Scalars['Bytes']['output'];
  agreementClass: Scalars['Bytes']['output'];
  agreementId: Scalars['Bytes']['output'];
  penaltyAccount: Scalars['Bytes']['output'];
  bondAccount: Scalars['Bytes']['output'];
  rewardAmount: Scalars['BigInt']['output'];
  bailoutAmount: Scalars['BigInt']['output'];
  /**
   * The full deposit amount of the stream that was liquidated.
   *
   */
  deposit: Scalars['BigInt']['output'];
  /**
   * The flow rate of the stream at the time of liquidation.
   *
   */
  flowRateAtLiquidation: Scalars['BigInt']['output'];
};

export type AgreementLiquidatedByEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidatorAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidatorAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementClass_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementClass_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementId_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementId_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  penaltyAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  penaltyAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  penaltyAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  bondAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  bondAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  bondAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmount?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  bailoutAmount?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  bailoutAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  bailoutAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAtLiquidation?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAtLiquidation_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AgreementLiquidatedByEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AgreementLiquidatedByEvent_Filter>>>;
};

export type AgreementLiquidatedByEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'liquidatorAccount'
  | 'agreementClass'
  | 'agreementId'
  | 'penaltyAccount'
  | 'bondAccount'
  | 'rewardAmount'
  | 'bailoutAmount'
  | 'deposit'
  | 'flowRateAtLiquidation';

export type AgreementLiquidatedV2Event = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `liquidatorAccount` (executor of liquidation)
   * addresses[2] = `targetAccount` (the sender of the flow/stream)
   * addresses[3] = `rewardAmountReceiver` (the address receiving the reward) addresses
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  agreementClass: Scalars['Bytes']['output'];
  agreementId: Scalars['Bytes']['output'];
  liquidatorAccount: Scalars['Bytes']['output'];
  targetAccount: Scalars['Bytes']['output'];
  rewardAmountReceiver: Scalars['Bytes']['output'];
  rewardAmount: Scalars['BigInt']['output'];
  targetAccountBalanceDelta: Scalars['BigInt']['output'];
  version: Scalars['BigInt']['output'];
  liquidationType: Scalars['Int']['output'];
  /**
   * The full deposit amount of the stream that was liquidated.
   *
   */
  deposit: Scalars['BigInt']['output'];
  /**
   * The flow rate of the stream at the time of liquidation.
   *
   */
  flowRateAtLiquidation: Scalars['BigInt']['output'];
  /**
   * TO BE DEPRECATED in v2 endpoint - use rewardAmountReceiver instead
   *
   */
  rewardAccount: Scalars['Bytes']['output'];
};

export type AgreementLiquidatedV2Event_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementClass_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementClass_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementClass_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_not?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_gt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_lt?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_gte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_lte?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementId_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  agreementId_contains?: InputMaybe<Scalars['Bytes']['input']>;
  agreementId_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidatorAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidatorAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidatorAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  targetAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  targetAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  targetAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_not?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_gt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_lt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_gte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_lte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAmountReceiver_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAmountReceiver_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmountReceiver_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAmount?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  targetAccountBalanceDelta?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_not?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_gt?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_lt?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_gte?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_lte?: InputMaybe<Scalars['BigInt']['input']>;
  targetAccountBalanceDelta_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  targetAccountBalanceDelta_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  version?: InputMaybe<Scalars['BigInt']['input']>;
  version_not?: InputMaybe<Scalars['BigInt']['input']>;
  version_gt?: InputMaybe<Scalars['BigInt']['input']>;
  version_lt?: InputMaybe<Scalars['BigInt']['input']>;
  version_gte?: InputMaybe<Scalars['BigInt']['input']>;
  version_lte?: InputMaybe<Scalars['BigInt']['input']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationType?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_not?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_gt?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_lt?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_gte?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_lte?: InputMaybe<Scalars['Int']['input']>;
  liquidationType_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  liquidationType_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  deposit?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAtLiquidation?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAtLiquidation_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAtLiquidation_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardAccount?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_not?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_gt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_lt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_gte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_lte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAccount_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAccount_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAccount_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AgreementLiquidatedV2Event_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AgreementLiquidatedV2Event_Filter>>>;
};

export type AgreementLiquidatedV2Event_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'agreementClass'
  | 'agreementId'
  | 'liquidatorAccount'
  | 'targetAccount'
  | 'rewardAmountReceiver'
  | 'rewardAmount'
  | 'targetAccountBalanceDelta'
  | 'version'
  | 'liquidationType'
  | 'deposit'
  | 'flowRateAtLiquidation'
  | 'rewardAccount';

export type AppRegisteredEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `app`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  app: Scalars['Bytes']['output'];
};

export type AppRegisteredEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  app?: InputMaybe<Scalars['Bytes']['input']>;
  app_not?: InputMaybe<Scalars['Bytes']['input']>;
  app_gt?: InputMaybe<Scalars['Bytes']['input']>;
  app_lt?: InputMaybe<Scalars['Bytes']['input']>;
  app_gte?: InputMaybe<Scalars['Bytes']['input']>;
  app_lte?: InputMaybe<Scalars['Bytes']['input']>;
  app_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  app_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  app_contains?: InputMaybe<Scalars['Bytes']['input']>;
  app_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AppRegisteredEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<AppRegisteredEvent_Filter>>>;
};

export type AppRegisteredEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'app';

export type ApprovalEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `owner`
   * addresses[2] = `to`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address that will be granting allowance to transfer ERC20.
   *
   */
  owner: Account;
  /**
   * The address that will be granted allowance to transfer ERC20.
   *
   */
  to: Account;
  /**
   * If `amount` is non-zero, this event was emitted for the approval of an ERC20.
   * Tne amount of ERC20 tokens that will be granted allowance to transfer.
   *
   */
  amount: Scalars['BigInt']['output'];
};

export type ApprovalEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<Account_Filter>;
  to?: InputMaybe<Scalars['String']['input']>;
  to_not?: InputMaybe<Scalars['String']['input']>;
  to_gt?: InputMaybe<Scalars['String']['input']>;
  to_lt?: InputMaybe<Scalars['String']['input']>;
  to_gte?: InputMaybe<Scalars['String']['input']>;
  to_lte?: InputMaybe<Scalars['String']['input']>;
  to_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_contains?: InputMaybe<Scalars['String']['input']>;
  to_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_contains?: InputMaybe<Scalars['String']['input']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_?: InputMaybe<Account_Filter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ApprovalEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<ApprovalEvent_Filter>>>;
};

export type ApprovalEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'owner'
  | 'owner__id'
  | 'owner__createdAtTimestamp'
  | 'owner__createdAtBlockNumber'
  | 'owner__updatedAtTimestamp'
  | 'owner__updatedAtBlockNumber'
  | 'owner__isSuperApp'
  | 'to'
  | 'to__id'
  | 'to__createdAtTimestamp'
  | 'to__createdAtBlockNumber'
  | 'to__updatedAtTimestamp'
  | 'to__updatedAtBlockNumber'
  | 'to__isSuperApp'
  | 'amount';

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export type BondIncreasedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address of the `token` (supertoken).
   *
   */
  token: Scalars['Bytes']['output'];
  /**
   * The additional amount added to the bond by the current Patrician In Charge (PIC).
   *
   */
  additionalBond: Scalars['BigInt']['output'];
};

export type BondIncreasedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  additionalBond?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_not?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_gt?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_lt?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_gte?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_lte?: InputMaybe<Scalars['BigInt']['input']>;
  additionalBond_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  additionalBond_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BondIncreasedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<BondIncreasedEvent_Filter>>>;
};

export type BondIncreasedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'additionalBond';

export type BufferAdjustedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `distributor`
   * addresses[3] = `operator`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  bufferDelta: Scalars['BigInt']['output'];
  newBufferAmount: Scalars['BigInt']['output'];
  totalBufferAmount: Scalars['BigInt']['output'];
  pool: Pool;
  poolDistributor: PoolDistributor;
};

export type BufferAdjustedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  bufferDelta?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_not?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_gt?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_lt?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_gte?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_lte?: InputMaybe<Scalars['BigInt']['input']>;
  bufferDelta_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  bufferDelta_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newBufferAmount?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  newBufferAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newBufferAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBufferAmount?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBufferAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBufferAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolDistributor?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_?: InputMaybe<PoolDistributor_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BufferAdjustedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<BufferAdjustedEvent_Filter>>>;
};

export type BufferAdjustedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'bufferDelta'
  | 'newBufferAmount'
  | 'totalBufferAmount'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolDistributor'
  | 'poolDistributor__id'
  | 'poolDistributor__createdAtTimestamp'
  | 'poolDistributor__createdAtBlockNumber'
  | 'poolDistributor__updatedAtTimestamp'
  | 'poolDistributor__updatedAtBlockNumber'
  | 'poolDistributor__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountDistributedUntilUpdatedAt'
  | 'poolDistributor__totalBuffer'
  | 'poolDistributor__flowRate';

export type BurnedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `from`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  operator: Scalars['Bytes']['output'];
  from: Scalars['Bytes']['output'];
  token: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  data: Scalars['Bytes']['output'];
  operatorData: Scalars['Bytes']['output'];
};

export type BurnedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from?: InputMaybe<Scalars['Bytes']['input']>;
  from_not?: InputMaybe<Scalars['Bytes']['input']>;
  from_gt?: InputMaybe<Scalars['Bytes']['input']>;
  from_lt?: InputMaybe<Scalars['Bytes']['input']>;
  from_gte?: InputMaybe<Scalars['Bytes']['input']>;
  from_lte?: InputMaybe<Scalars['Bytes']['input']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  from_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  data?: InputMaybe<Scalars['Bytes']['input']>;
  data_not?: InputMaybe<Scalars['Bytes']['input']>;
  data_gt?: InputMaybe<Scalars['Bytes']['input']>;
  data_lt?: InputMaybe<Scalars['Bytes']['input']>;
  data_gte?: InputMaybe<Scalars['Bytes']['input']>;
  data_lte?: InputMaybe<Scalars['Bytes']['input']>;
  data_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_contains?: InputMaybe<Scalars['Bytes']['input']>;
  data_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BurnedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<BurnedEvent_Filter>>>;
};

export type BurnedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'operator'
  | 'from'
  | 'token'
  | 'amount'
  | 'data'
  | 'operatorData';

export type CfAv1LiquidationPeriodChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  liquidationPeriod: Scalars['BigInt']['output'];
};

export type CfAv1LiquidationPeriodChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  liquidationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CfAv1LiquidationPeriodChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<CfAv1LiquidationPeriodChangedEvent_Filter>>>;
};

export type CfAv1LiquidationPeriodChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'order'
  | 'logIndex'
  | 'host'
  | 'superToken'
  | 'isKeySet'
  | 'liquidationPeriod';

export type ConfigChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  key: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  value: Scalars['BigInt']['output'];
};

export type ConfigChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  key?: InputMaybe<Scalars['Bytes']['input']>;
  key_not?: InputMaybe<Scalars['Bytes']['input']>;
  key_gt?: InputMaybe<Scalars['Bytes']['input']>;
  key_lt?: InputMaybe<Scalars['Bytes']['input']>;
  key_gte?: InputMaybe<Scalars['Bytes']['input']>;
  key_lte?: InputMaybe<Scalars['Bytes']['input']>;
  key_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  key_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  key_contains?: InputMaybe<Scalars['Bytes']['input']>;
  key_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  value?: InputMaybe<Scalars['BigInt']['input']>;
  value_not?: InputMaybe<Scalars['BigInt']['input']>;
  value_gt?: InputMaybe<Scalars['BigInt']['input']>;
  value_lt?: InputMaybe<Scalars['BigInt']['input']>;
  value_gte?: InputMaybe<Scalars['BigInt']['input']>;
  value_lte?: InputMaybe<Scalars['BigInt']['input']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ConfigChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<ConfigChangedEvent_Filter>>>;
};

export type ConfigChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'host'
  | 'superToken'
  | 'key'
  | 'isKeySet'
  | 'value';

export type CustomSuperTokenCreatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
};

export type CustomSuperTokenCreatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CustomSuperTokenCreatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<CustomSuperTokenCreatedEvent_Filter>>>;
};

export type CustomSuperTokenCreatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token';

export type DistributionClaimedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `member`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  claimedAmount: Scalars['BigInt']['output'];
  totalClaimed: Scalars['BigInt']['output'];
  pool: Pool;
  poolMember: PoolMember;
};

export type DistributionClaimedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  claimedAmount?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  claimedAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  claimedAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalClaimed?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalClaimed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalClaimed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolMember?: InputMaybe<Scalars['String']['input']>;
  poolMember_not?: InputMaybe<Scalars['String']['input']>;
  poolMember_gt?: InputMaybe<Scalars['String']['input']>;
  poolMember_lt?: InputMaybe<Scalars['String']['input']>;
  poolMember_gte?: InputMaybe<Scalars['String']['input']>;
  poolMember_lte?: InputMaybe<Scalars['String']['input']>;
  poolMember_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_?: InputMaybe<PoolMember_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DistributionClaimedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<DistributionClaimedEvent_Filter>>>;
};

export type DistributionClaimedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'claimedAmount'
  | 'totalClaimed'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolMember'
  | 'poolMember__id'
  | 'poolMember__createdAtTimestamp'
  | 'poolMember__createdAtBlockNumber'
  | 'poolMember__updatedAtTimestamp'
  | 'poolMember__updatedAtBlockNumber'
  | 'poolMember__units'
  | 'poolMember__isConnected'
  | 'poolMember__totalAmountClaimed'
  | 'poolMember__poolTotalAmountDistributedUntilUpdatedAt'
  | 'poolMember__totalAmountReceivedUntilUpdatedAt'
  | 'poolMember__syncedPerUnitSettledValue'
  | 'poolMember__syncedPerUnitFlowRate';

/**
 * Event: An interface which is shared by all event entities and contains basic transaction data.
 *
 */
export type Event = {
  /**
   * The id of the event entity.
   *
   */
  id: Scalars['ID']['output'];
  /**
   * The block number which the event was logged in.
   *
   */
  blockNumber: Scalars['BigInt']['output'];
  /**
   * The index of the event, e.g. first event emitted would have `logIndex` of 0.
   *
   */
  logIndex: Scalars['BigInt']['output'];
  /**
   * A number used internally to sort the order of transactions.
   * The formula: `blockNumber * ORDER_MULTIPLIER + logIndex`
   * where: ORDER_MULTIPLIER = 10000
   *
   */
  order: Scalars['BigInt']['output'];
  /**
   * The name of the event - is a 1-to-1 match with the name in our smart contracts.
   *
   */
  name: Scalars['String']['output'];
  /**
   * Contains the addresses for accounts that were "impacted" by the event.
   * This typically involves accounts which experienced a state change as a result of the transaction which emitted this event.
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  /**
   * The block timestamp which the event was logged in.
   *
   */
  timestamp: Scalars['BigInt']['output'];
  /**
   * The transaction hash of the transaction that the event was logged in.
   *
   */
  transactionHash: Scalars['Bytes']['output'];
  /**
   * The gas price of the transaction that the event was logged in.
   *
   */
  gasPrice: Scalars['BigInt']['output'];
  /**
   * The gas used for this transaction.
   *
   */
  gasUsed: Scalars['BigInt']['output'];
};

export type Event_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Event_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Event_Filter>>>;
};

export type Event_OrderBy =
  | 'id'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'name'
  | 'addresses'
  | 'timestamp'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed';

export type ExitRateChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address of the `token` (supertoken).
   *
   */
  token: Scalars['Bytes']['output'];
  /**
   * The flowrate at which the bond is streamed back to the Patrician In Charge.
   *
   */
  exitRate: Scalars['BigInt']['output'];
};

export type ExitRateChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  exitRate?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  exitRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ExitRateChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<ExitRateChangedEvent_Filter>>>;
};

export type ExitRateChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'exitRate';

export type FlowDistributionUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `poolDistributor`
   * addresses[3] = `operator`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  operator: Scalars['Bytes']['output'];
  oldFlowRate: Scalars['BigInt']['output'];
  newDistributorToPoolFlowRate: Scalars['BigInt']['output'];
  newTotalDistributionFlowRate: Scalars['BigInt']['output'];
  adjustmentFlowRecipient: Scalars['Bytes']['output'];
  adjustmentFlowRate: Scalars['BigInt']['output'];
  totalUnits: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  pool: Pool;
  poolDistributor: PoolDistributor;
};

export type FlowDistributionUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newDistributorToPoolFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  newDistributorToPoolFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newDistributorToPoolFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newTotalDistributionFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  newTotalDistributionFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newTotalDistributionFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  adjustmentFlowRecipient?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_not?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_gt?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_lt?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_gte?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_lte?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  adjustmentFlowRecipient_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  adjustmentFlowRecipient_contains?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRecipient_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  adjustmentFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  adjustmentFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolDistributor?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_?: InputMaybe<PoolDistributor_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FlowDistributionUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<FlowDistributionUpdatedEvent_Filter>>>;
};

export type FlowDistributionUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'operator'
  | 'oldFlowRate'
  | 'newDistributorToPoolFlowRate'
  | 'newTotalDistributionFlowRate'
  | 'adjustmentFlowRecipient'
  | 'adjustmentFlowRate'
  | 'totalUnits'
  | 'userData'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolDistributor'
  | 'poolDistributor__id'
  | 'poolDistributor__createdAtTimestamp'
  | 'poolDistributor__createdAtBlockNumber'
  | 'poolDistributor__updatedAtTimestamp'
  | 'poolDistributor__updatedAtBlockNumber'
  | 'poolDistributor__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountDistributedUntilUpdatedAt'
  | 'poolDistributor__totalBuffer'
  | 'poolDistributor__flowRate';

/**
 * FlowOperator: A higher order entity that of a flow operator for an `AccountTokenSnapshot`.
 *
 */
export type FlowOperator = {
  /**
   * ID composed of: flowOperator-token-sender
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * The permissions granted to the `flowOperator`.
   * Bitmask representation:
   * Delete | Update | Create
   * | D | U | C |
   * | 0 | 0 | 0 |
   *
   */
  permissions: Scalars['Int']['output'];
  /**
   * The flow rate allowance granted to the `flowOperator` by the `sender`. This can be reset if the `sender` updates the `flowOperator` flow rate allowance.
   *
   */
  flowRateAllowanceGranted: Scalars['BigInt']['output'];
  /**
   * The remaining flow rate allowance the `flowOperator` has.
   * This will go down every time when the `flowOperator` uses the allowance, that is, if they increase flowRate for `sender` or create a new flow on behalf of `sender`.
   * It can only be reset if the `sender` updates the flow rate allowance.
   * NOTE: this value will NOT go down if max flow rate allowance is set.
   *
   */
  flowRateAllowanceRemaining: Scalars['BigInt']['output'];
  /**
   * The transfer allowance granted to the `flowOperator` by the `sender`.
   *
   */
  allowance: Scalars['BigInt']['output'];
  flowOperator: Scalars['Bytes']['output'];
  sender: Account;
  token: Token;
  accountTokenSnapshot: AccountTokenSnapshot;
  flowOperatorUpdatedEvents: Array<FlowOperatorUpdatedEvent>;
};


/**
 * FlowOperator: A higher order entity that of a flow operator for an `AccountTokenSnapshot`.
 *
 */
export type FlowOperatorFlowOperatorUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperatorUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperatorUpdatedEvent_Filter>;
};

export type FlowOperatorUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = sender
   * addresses[2] = `flowOperator`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address of the `token` being streamed.
   *
   */
  token: Scalars['Bytes']['output'];
  sender: Scalars['Bytes']['output'];
  /**
   * The permissions granted to the `flowOperator`.
   * Octo bitmask representation.
   *
   */
  permissions: Scalars['Int']['output'];
  flowRateAllowance: Scalars['BigInt']['output'];
  flowOperator: FlowOperator;
};

export type FlowOperatorUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  permissions?: InputMaybe<Scalars['Int']['input']>;
  permissions_not?: InputMaybe<Scalars['Int']['input']>;
  permissions_gt?: InputMaybe<Scalars['Int']['input']>;
  permissions_lt?: InputMaybe<Scalars['Int']['input']>;
  permissions_gte?: InputMaybe<Scalars['Int']['input']>;
  permissions_lte?: InputMaybe<Scalars['Int']['input']>;
  permissions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  permissions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  flowRateAllowance?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAllowance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowOperator?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not?: InputMaybe<Scalars['String']['input']>;
  flowOperator_gt?: InputMaybe<Scalars['String']['input']>;
  flowOperator_lt?: InputMaybe<Scalars['String']['input']>;
  flowOperator_gte?: InputMaybe<Scalars['String']['input']>;
  flowOperator_lte?: InputMaybe<Scalars['String']['input']>;
  flowOperator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  flowOperator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  flowOperator_contains?: InputMaybe<Scalars['String']['input']>;
  flowOperator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_contains?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_starts_with?: InputMaybe<Scalars['String']['input']>;
  flowOperator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_ends_with?: InputMaybe<Scalars['String']['input']>;
  flowOperator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  flowOperator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  flowOperator_?: InputMaybe<FlowOperator_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FlowOperatorUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<FlowOperatorUpdatedEvent_Filter>>>;
};

export type FlowOperatorUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'sender'
  | 'permissions'
  | 'flowRateAllowance'
  | 'flowOperator'
  | 'flowOperator__id'
  | 'flowOperator__createdAtTimestamp'
  | 'flowOperator__createdAtBlockNumber'
  | 'flowOperator__updatedAtTimestamp'
  | 'flowOperator__updatedAtBlockNumber'
  | 'flowOperator__permissions'
  | 'flowOperator__flowRateAllowanceGranted'
  | 'flowOperator__flowRateAllowanceRemaining'
  | 'flowOperator__allowance'
  | 'flowOperator__flowOperator';

export type FlowOperator_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  permissions?: InputMaybe<Scalars['Int']['input']>;
  permissions_not?: InputMaybe<Scalars['Int']['input']>;
  permissions_gt?: InputMaybe<Scalars['Int']['input']>;
  permissions_lt?: InputMaybe<Scalars['Int']['input']>;
  permissions_gte?: InputMaybe<Scalars['Int']['input']>;
  permissions_lte?: InputMaybe<Scalars['Int']['input']>;
  permissions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  permissions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  flowRateAllowanceGranted?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceGranted_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAllowanceGranted_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAllowanceRemaining?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRateAllowanceRemaining_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRateAllowanceRemaining_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowance?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_not?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_gt?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_lt?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_gte?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_lte?: InputMaybe<Scalars['BigInt']['input']>;
  allowance_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowance_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowOperator?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_not?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  flowOperator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  flowOperator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  sender_not?: InputMaybe<Scalars['String']['input']>;
  sender_gt?: InputMaybe<Scalars['String']['input']>;
  sender_lt?: InputMaybe<Scalars['String']['input']>;
  sender_gte?: InputMaybe<Scalars['String']['input']>;
  sender_lte?: InputMaybe<Scalars['String']['input']>;
  sender_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_contains?: InputMaybe<Scalars['String']['input']>;
  sender_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_?: InputMaybe<Account_Filter>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  accountTokenSnapshot?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_gt?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_lt?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_gte?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_lte?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_in?: InputMaybe<Array<Scalars['String']['input']>>;
  accountTokenSnapshot_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  accountTokenSnapshot_contains?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_contains?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_starts_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_ends_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  accountTokenSnapshot_?: InputMaybe<AccountTokenSnapshot_Filter>;
  flowOperatorUpdatedEvents_?: InputMaybe<FlowOperatorUpdatedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FlowOperator_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<FlowOperator_Filter>>>;
};

export type FlowOperator_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'permissions'
  | 'flowRateAllowanceGranted'
  | 'flowRateAllowanceRemaining'
  | 'allowance'
  | 'flowOperator'
  | 'sender'
  | 'sender__id'
  | 'sender__createdAtTimestamp'
  | 'sender__createdAtBlockNumber'
  | 'sender__updatedAtTimestamp'
  | 'sender__updatedAtBlockNumber'
  | 'sender__isSuperApp'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'accountTokenSnapshot'
  | 'accountTokenSnapshot__id'
  | 'accountTokenSnapshot__createdAtTimestamp'
  | 'accountTokenSnapshot__createdAtBlockNumber'
  | 'accountTokenSnapshot__updatedAtTimestamp'
  | 'accountTokenSnapshot__updatedAtBlockNumber'
  | 'accountTokenSnapshot__isLiquidationEstimateOptimistic'
  | 'accountTokenSnapshot__maybeCriticalAtTimestamp'
  | 'accountTokenSnapshot__totalNumberOfActiveStreams'
  | 'accountTokenSnapshot__totalCFANumberOfActiveStreams'
  | 'accountTokenSnapshot__totalGDANumberOfActiveStreams'
  | 'accountTokenSnapshot__activeOutgoingStreamCount'
  | 'accountTokenSnapshot__activeCFAOutgoingStreamCount'
  | 'accountTokenSnapshot__activeGDAOutgoingStreamCount'
  | 'accountTokenSnapshot__activeIncomingStreamCount'
  | 'accountTokenSnapshot__totalNumberOfClosedStreams'
  | 'accountTokenSnapshot__totalCFANumberOfClosedStreams'
  | 'accountTokenSnapshot__totalGDANumberOfClosedStreams'
  | 'accountTokenSnapshot__inactiveOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveCFAOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveGDAOutgoingStreamCount'
  | 'accountTokenSnapshot__inactiveIncomingStreamCount'
  | 'accountTokenSnapshot__totalSubscriptionsWithUnits'
  | 'accountTokenSnapshot__totalApprovedSubscriptions'
  | 'accountTokenSnapshot__totalMembershipsWithUnits'
  | 'accountTokenSnapshot__totalConnectedMemberships'
  | 'accountTokenSnapshot__adminOfPoolCount'
  | 'accountTokenSnapshot__balanceUntilUpdatedAt'
  | 'accountTokenSnapshot__balanceLastUpdatedFromRpcBlocknumber'
  | 'accountTokenSnapshot__totalDeposit'
  | 'accountTokenSnapshot__totalCFADeposit'
  | 'accountTokenSnapshot__totalGDADeposit'
  | 'accountTokenSnapshot__totalNetFlowRate'
  | 'accountTokenSnapshot__totalCFANetFlowRate'
  | 'accountTokenSnapshot__totalInflowRate'
  | 'accountTokenSnapshot__totalOutflowRate'
  | 'accountTokenSnapshot__totalCFAOutflowRate'
  | 'accountTokenSnapshot__totalGDAOutflowRate'
  | 'accountTokenSnapshot__totalAmountStreamedInUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountStreamedOutUntilUpdatedAt'
  | 'accountTokenSnapshot__totalCFAAmountStreamedOutUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountStreamedUntilUpdatedAt'
  | 'accountTokenSnapshot__totalCFAAmountStreamedUntilUpdatedAt'
  | 'accountTokenSnapshot__totalAmountTransferredUntilUpdatedAt'
  | 'flowOperatorUpdatedEvents';

/**
 * FlowUpdated: An `Event` entity that is emitted
 * when a flow is created, updated, or deleted.
 *
 */
export type FlowUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (supertoken)
   * addresses[1] = `sender`
   * addresses[2] = `receiver`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address of the `token` (supertoken) being streamed.
   *
   */
  token: Scalars['Bytes']['output'];
  /**
   * The address of the flow sender.
   *
   */
  sender: Scalars['Bytes']['output'];
  /**
   * The address of the flow receiver.
   *
   */
  receiver: Scalars['Bytes']['output'];
  /**
   * The address that is executing the flow update transaction.
   * This will be the zero address until the flowOperator feature is live.
   *
   */
  flowOperator: Scalars['Bytes']['output'];
  /**
   * The flow rate per second.
   *
   */
  flowRate: Scalars['BigInt']['output'];
  /**
   * The total (global/account level) flow rate of `sender` for `token` as of this event.
   *
   */
  totalSenderFlowRate: Scalars['BigInt']['output'];
  /**
   * The total (global/account level) flow rate of `receiver` for `token` as of this event.
   *
   */
  totalReceiverFlowRate: Scalars['BigInt']['output'];
  /**
   * The deposit amount put up for the creation of the flow.
   *
   */
  deposit: Scalars['BigInt']['output'];
  /**
   * Arbitrary bytes (additional data) passed upon flow creation.
   *
   */
  userData: Scalars['Bytes']['output'];
  /**
   * The previous flow rate, the absolute (positive) value.
   *
   */
  oldFlowRate: Scalars['BigInt']['output'];
  /**
   * The "type" of the `FlowUpdated` event.
   * 0 = create
   * 1 = update
   * 2 = terminate
   *
   */
  type: Scalars['Int']['output'];
  /**
   * The total amount streamed until the timestamp
   * for the Stream entity linked to this event.
   *
   */
  totalAmountStreamedUntilTimestamp: Scalars['BigInt']['output'];
  /**
   * The stream entity which is being modified.
   *
   */
  stream: Stream;
};

export type FlowUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  receiver?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_not?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_lt?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  receiver_contains?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_not?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  flowOperator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  flowOperator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  flowOperator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  flowRate?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSenderFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSenderFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSenderFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalReceiverFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalReceiverFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalReceiverFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  type?: InputMaybe<Scalars['Int']['input']>;
  type_not?: InputMaybe<Scalars['Int']['input']>;
  type_gt?: InputMaybe<Scalars['Int']['input']>;
  type_lt?: InputMaybe<Scalars['Int']['input']>;
  type_gte?: InputMaybe<Scalars['Int']['input']>;
  type_lte?: InputMaybe<Scalars['Int']['input']>;
  type_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  type_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalAmountStreamedUntilTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedUntilTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stream?: InputMaybe<Scalars['String']['input']>;
  stream_not?: InputMaybe<Scalars['String']['input']>;
  stream_gt?: InputMaybe<Scalars['String']['input']>;
  stream_lt?: InputMaybe<Scalars['String']['input']>;
  stream_gte?: InputMaybe<Scalars['String']['input']>;
  stream_lte?: InputMaybe<Scalars['String']['input']>;
  stream_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stream_contains?: InputMaybe<Scalars['String']['input']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_contains?: InputMaybe<Scalars['String']['input']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_starts_with?: InputMaybe<Scalars['String']['input']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_ends_with?: InputMaybe<Scalars['String']['input']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_?: InputMaybe<Stream_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FlowUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<FlowUpdatedEvent_Filter>>>;
};

export type FlowUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'sender'
  | 'receiver'
  | 'flowOperator'
  | 'flowRate'
  | 'totalSenderFlowRate'
  | 'totalReceiverFlowRate'
  | 'deposit'
  | 'userData'
  | 'oldFlowRate'
  | 'type'
  | 'totalAmountStreamedUntilTimestamp'
  | 'stream'
  | 'stream__id'
  | 'stream__createdAtTimestamp'
  | 'stream__createdAtBlockNumber'
  | 'stream__updatedAtTimestamp'
  | 'stream__updatedAtBlockNumber'
  | 'stream__currentFlowRate'
  | 'stream__deposit'
  | 'stream__streamedUntilUpdatedAt'
  | 'stream__userData';

export type GovernanceReplacedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `oldGovernance`
   * addresses[1] = `newGovernance`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  oldGovernance: Scalars['Bytes']['output'];
  newGovernance: Scalars['Bytes']['output'];
};

export type GovernanceReplacedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  oldGovernance?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_not?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_gt?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_lt?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_gte?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_lte?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  oldGovernance_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  oldGovernance_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldGovernance_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_not?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newGovernance_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newGovernance_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newGovernance_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GovernanceReplacedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<GovernanceReplacedEvent_Filter>>>;
};

export type GovernanceReplacedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'addresses'
  | 'oldGovernance'
  | 'newGovernance';

/**
 * Index: An Index higher order entity.
 *
 */
export type Index = {
  /**
   * ID composed of: publisherAddress-tokenAddress-indexId
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * NOTE: indexId is not the same as the id of the `Index` entity.
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  indexValue: Scalars['BigInt']['output'];
  /**
   * The number of subscriptions which have units allocated to them on the `Index`.
   *
   */
  totalSubscriptionsWithUnits: Scalars['Int']['output'];
  /**
   * The number of units allocated by the `Index` that are pending.
   * This refers to the current (as of updatedAt) `totalUnitsPending`-not all that has ever been pending.
   *
   */
  totalUnitsPending: Scalars['BigInt']['output'];
  /**
   * The number of units allocated by the `Index` that are approved.
   * This refers to the current (as of updatedAt) `totalUnitsApproved`-not all that has ever been approved.
   *
   */
  totalUnitsApproved: Scalars['BigInt']['output'];
  /**
   * The sum of `totalUnitsPending` and `totalUnitsApproved`.
   *
   */
  totalUnits: Scalars['BigInt']['output'];
  /**
   * The total amount distributed from this `Index`.
   *
   */
  totalAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  token: Token;
  publisher: Account;
  /**
   * The subscriptions of the index, it will include approved, unapproved
   * and deleted subscriptions.
   *
   */
  subscriptions: Array<IndexSubscription>;
  /**
   * IndexCreated event, there will only be one.
   *
   */
  indexCreatedEvent: IndexCreatedEvent;
  indexDistributionClaimedEvents: Array<IndexDistributionClaimedEvent>;
  indexUpdatedEvents: Array<IndexUpdatedEvent>;
  indexSubscribedEvents: Array<IndexSubscribedEvent>;
  indexUnitsUpdatedEvents: Array<IndexUnitsUpdatedEvent>;
  indexUnsubscribedEvents: Array<IndexUnsubscribedEvent>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscription_Filter>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexIndexDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexDistributionClaimedEvent_Filter>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexIndexUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUpdatedEvent_Filter>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexIndexSubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscribedEvent_Filter>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexIndexUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnitsUpdatedEvent_Filter>;
};


/**
 * Index: An Index higher order entity.
 *
 */
export type IndexIndexUnsubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnsubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnsubscribedEvent_Filter>;
};

export type IndexCreatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  index: Index;
};

export type IndexCreatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexCreatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexCreatedEvent_Filter>>>;
};

export type IndexCreatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'userData'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

export type IndexDistributionClaimedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  index: Index;
};

export type IndexDistributionClaimedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexDistributionClaimedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexDistributionClaimedEvent_Filter>>>;
};

export type IndexDistributionClaimedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'subscriber'
  | 'amount'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

export type IndexSubscribedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  userData: Scalars['Bytes']['output'];
  index: Index;
};

export type IndexSubscribedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexSubscribedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexSubscribedEvent_Filter>>>;
};

export type IndexSubscribedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'subscriber'
  | 'userData'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

/**
 * IndexSubscription: A higher order entity that contains subscription data for a `subscriber` account of a particular `Index`.
 *
 */
export type IndexSubscription = {
  /**
   * ID composed of: subscriberAddress-publisherAddress-tokenAddress-IndexId
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  subscriber: Account;
  /**
   * A boolean indicating whether the `IndexSubscription` is approved.
   * Approved subscriptions don't require `subscriber` to claim tokens that are distributed from the publisher.
   *
   */
  approved: Scalars['Boolean']['output'];
  /**
   * If `units` is `0`, it indicates that the subscription is "deleted" and `subscriber` is no longer subscribed to `index`.
   *
   */
  units: Scalars['BigInt']['output'];
  /**
   * The total amount of tokens you've received via IDA until
   * `updatedAtTimestamp`/`updatedAtBlock`.
   *
   */
  totalAmountReceivedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The previous index value - used to calculate `totalAmountReceivedUntilUpdatedAt` field as of the `index.updatedAtTimestamp`.
   * The formula to get this value is:
   * `IndexSubscription.totalAmountReceivedUntilUpdatedAt + ((index.newIndexValue - indexSubscription.indexValueUntilUpdatedAt) * indexSubscription.units)`.
   *
   */
  indexValueUntilUpdatedAt: Scalars['BigInt']['output'];
  index: Index;
  /**
   * IndexSubscription approved events on the subscription.
   *
   */
  subscriptionApprovedEvents: Array<SubscriptionApprovedEvent>;
  subscriptionDistributionClaimedEvents: Array<SubscriptionDistributionClaimedEvent>;
  subscriptionRevokedEvents: Array<SubscriptionRevokedEvent>;
  subscriptionUnitsUpdatedEvents: Array<SubscriptionUnitsUpdatedEvent>;
};


/**
 * IndexSubscription: A higher order entity that contains subscription data for a `subscriber` account of a particular `Index`.
 *
 */
export type IndexSubscriptionSubscriptionApprovedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionApprovedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionApprovedEvent_Filter>;
};


/**
 * IndexSubscription: A higher order entity that contains subscription data for a `subscriber` account of a particular `Index`.
 *
 */
export type IndexSubscriptionSubscriptionDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionDistributionClaimedEvent_Filter>;
};


/**
 * IndexSubscription: A higher order entity that contains subscription data for a `subscriber` account of a particular `Index`.
 *
 */
export type IndexSubscriptionSubscriptionRevokedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionRevokedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionRevokedEvent_Filter>;
};


/**
 * IndexSubscription: A higher order entity that contains subscription data for a `subscriber` account of a particular `Index`.
 *
 */
export type IndexSubscriptionSubscriptionUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>;
};

export type IndexSubscription_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscriber?: InputMaybe<Scalars['String']['input']>;
  subscriber_not?: InputMaybe<Scalars['String']['input']>;
  subscriber_gt?: InputMaybe<Scalars['String']['input']>;
  subscriber_lt?: InputMaybe<Scalars['String']['input']>;
  subscriber_gte?: InputMaybe<Scalars['String']['input']>;
  subscriber_lte?: InputMaybe<Scalars['String']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['String']['input']>;
  subscriber_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscriber_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscriber_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscriber_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscriber_?: InputMaybe<Account_Filter>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  approved_not?: InputMaybe<Scalars['Boolean']['input']>;
  approved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  approved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  units?: InputMaybe<Scalars['BigInt']['input']>;
  units_not?: InputMaybe<Scalars['BigInt']['input']>;
  units_gt?: InputMaybe<Scalars['BigInt']['input']>;
  units_lt?: InputMaybe<Scalars['BigInt']['input']>;
  units_gte?: InputMaybe<Scalars['BigInt']['input']>;
  units_lte?: InputMaybe<Scalars['BigInt']['input']>;
  units_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountReceivedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountReceivedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexValueUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexValueUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexValueUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  subscriptionApprovedEvents_?: InputMaybe<SubscriptionApprovedEvent_Filter>;
  subscriptionDistributionClaimedEvents_?: InputMaybe<SubscriptionDistributionClaimedEvent_Filter>;
  subscriptionRevokedEvents_?: InputMaybe<SubscriptionRevokedEvent_Filter>;
  subscriptionUnitsUpdatedEvents_?: InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexSubscription_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexSubscription_Filter>>>;
};

export type IndexSubscription_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'subscriber'
  | 'subscriber__id'
  | 'subscriber__createdAtTimestamp'
  | 'subscriber__createdAtBlockNumber'
  | 'subscriber__updatedAtTimestamp'
  | 'subscriber__updatedAtBlockNumber'
  | 'subscriber__isSuperApp'
  | 'approved'
  | 'units'
  | 'totalAmountReceivedUntilUpdatedAt'
  | 'indexValueUntilUpdatedAt'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt'
  | 'subscriptionApprovedEvents'
  | 'subscriptionDistributionClaimedEvents'
  | 'subscriptionRevokedEvents'
  | 'subscriptionUnitsUpdatedEvents';

export type IndexUnitsUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  units: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  oldUnits: Scalars['BigInt']['output'];
  index: Index;
};

export type IndexUnitsUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  units?: InputMaybe<Scalars['BigInt']['input']>;
  units_not?: InputMaybe<Scalars['BigInt']['input']>;
  units_gt?: InputMaybe<Scalars['BigInt']['input']>;
  units_lt?: InputMaybe<Scalars['BigInt']['input']>;
  units_gte?: InputMaybe<Scalars['BigInt']['input']>;
  units_lte?: InputMaybe<Scalars['BigInt']['input']>;
  units_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldUnits?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexUnitsUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexUnitsUpdatedEvent_Filter>>>;
};

export type IndexUnitsUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'subscriber'
  | 'units'
  | 'userData'
  | 'oldUnits'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

export type IndexUnsubscribedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  userData: Scalars['Bytes']['output'];
  index: Index;
};

export type IndexUnsubscribedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexUnsubscribedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexUnsubscribedEvent_Filter>>>;
};

export type IndexUnsubscribedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'subscriber'
  | 'userData'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

export type IndexUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  oldIndexValue: Scalars['BigInt']['output'];
  newIndexValue: Scalars['BigInt']['output'];
  totalUnitsPending: Scalars['BigInt']['output'];
  totalUnitsApproved: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  index: Index;
};

export type IndexUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldIndexValue?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldIndexValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldIndexValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newIndexValue?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  newIndexValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newIndexValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsPending?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsPending_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsApproved?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsApproved_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  index_not?: InputMaybe<Scalars['String']['input']>;
  index_gt?: InputMaybe<Scalars['String']['input']>;
  index_lt?: InputMaybe<Scalars['String']['input']>;
  index_gte?: InputMaybe<Scalars['String']['input']>;
  index_lte?: InputMaybe<Scalars['String']['input']>;
  index_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  index_contains?: InputMaybe<Scalars['String']['input']>;
  index_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_contains?: InputMaybe<Scalars['String']['input']>;
  index_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  index_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  index_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  index_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  index_?: InputMaybe<Index_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<IndexUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<IndexUpdatedEvent_Filter>>>;
};

export type IndexUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'publisher'
  | 'indexId'
  | 'oldIndexValue'
  | 'newIndexValue'
  | 'totalUnitsPending'
  | 'totalUnitsApproved'
  | 'userData'
  | 'index'
  | 'index__id'
  | 'index__createdAtTimestamp'
  | 'index__createdAtBlockNumber'
  | 'index__updatedAtTimestamp'
  | 'index__updatedAtBlockNumber'
  | 'index__indexId'
  | 'index__indexValue'
  | 'index__totalSubscriptionsWithUnits'
  | 'index__totalUnitsPending'
  | 'index__totalUnitsApproved'
  | 'index__totalUnits'
  | 'index__totalAmountDistributedUntilUpdatedAt';

export type Index_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexValue?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSubscriptionsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalUnitsPending?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsPending_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsPending_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsApproved?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnitsApproved_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnitsApproved_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  publisher_not?: InputMaybe<Scalars['String']['input']>;
  publisher_gt?: InputMaybe<Scalars['String']['input']>;
  publisher_lt?: InputMaybe<Scalars['String']['input']>;
  publisher_gte?: InputMaybe<Scalars['String']['input']>;
  publisher_lte?: InputMaybe<Scalars['String']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['String']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  publisher_contains?: InputMaybe<Scalars['String']['input']>;
  publisher_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['String']['input']>;
  publisher_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_starts_with?: InputMaybe<Scalars['String']['input']>;
  publisher_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  publisher_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_ends_with?: InputMaybe<Scalars['String']['input']>;
  publisher_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  publisher_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  publisher_?: InputMaybe<Account_Filter>;
  subscriptions_?: InputMaybe<IndexSubscription_Filter>;
  indexCreatedEvent?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_gt?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_lt?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_gte?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_lte?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_in?: InputMaybe<Array<Scalars['String']['input']>>;
  indexCreatedEvent_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  indexCreatedEvent_contains?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_contains?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_starts_with?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_ends_with?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  indexCreatedEvent_?: InputMaybe<IndexCreatedEvent_Filter>;
  indexDistributionClaimedEvents_?: InputMaybe<IndexDistributionClaimedEvent_Filter>;
  indexUpdatedEvents_?: InputMaybe<IndexUpdatedEvent_Filter>;
  indexSubscribedEvents_?: InputMaybe<IndexSubscribedEvent_Filter>;
  indexUnitsUpdatedEvents_?: InputMaybe<IndexUnitsUpdatedEvent_Filter>;
  indexUnsubscribedEvents_?: InputMaybe<IndexUnsubscribedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Index_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Index_Filter>>>;
};

export type Index_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'indexId'
  | 'indexValue'
  | 'totalSubscriptionsWithUnits'
  | 'totalUnitsPending'
  | 'totalUnitsApproved'
  | 'totalUnits'
  | 'totalAmountDistributedUntilUpdatedAt'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'publisher'
  | 'publisher__id'
  | 'publisher__createdAtTimestamp'
  | 'publisher__createdAtBlockNumber'
  | 'publisher__updatedAtTimestamp'
  | 'publisher__updatedAtBlockNumber'
  | 'publisher__isSuperApp'
  | 'subscriptions'
  | 'indexCreatedEvent'
  | 'indexCreatedEvent__id'
  | 'indexCreatedEvent__transactionHash'
  | 'indexCreatedEvent__gasPrice'
  | 'indexCreatedEvent__gasUsed'
  | 'indexCreatedEvent__timestamp'
  | 'indexCreatedEvent__name'
  | 'indexCreatedEvent__blockNumber'
  | 'indexCreatedEvent__logIndex'
  | 'indexCreatedEvent__order'
  | 'indexCreatedEvent__token'
  | 'indexCreatedEvent__publisher'
  | 'indexCreatedEvent__indexId'
  | 'indexCreatedEvent__userData'
  | 'indexDistributionClaimedEvents'
  | 'indexUpdatedEvents'
  | 'indexSubscribedEvents'
  | 'indexUnitsUpdatedEvents'
  | 'indexUnsubscribedEvents';

export type InstantDistributionUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `poolDistributor`
   * addresses[3] = `operator`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  operator: Scalars['Bytes']['output'];
  requestedAmount: Scalars['BigInt']['output'];
  actualAmount: Scalars['BigInt']['output'];
  totalUnits: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  pool: Pool;
  poolDistributor: PoolDistributor;
};

export type InstantDistributionUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  requestedAmount?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  requestedAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  requestedAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  actualAmount?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  actualAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  actualAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolDistributor?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lt?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_gte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_lte?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolDistributor_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolDistributor_?: InputMaybe<PoolDistributor_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<InstantDistributionUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<InstantDistributionUpdatedEvent_Filter>>>;
};

export type InstantDistributionUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'operator'
  | 'requestedAmount'
  | 'actualAmount'
  | 'totalUnits'
  | 'userData'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolDistributor'
  | 'poolDistributor__id'
  | 'poolDistributor__createdAtTimestamp'
  | 'poolDistributor__createdAtBlockNumber'
  | 'poolDistributor__updatedAtTimestamp'
  | 'poolDistributor__updatedAtBlockNumber'
  | 'poolDistributor__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'poolDistributor__totalAmountDistributedUntilUpdatedAt'
  | 'poolDistributor__totalBuffer'
  | 'poolDistributor__flowRate';

export type JailEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `app`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  app: Scalars['Bytes']['output'];
  reason: Scalars['BigInt']['output'];
};

export type JailEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  app?: InputMaybe<Scalars['Bytes']['input']>;
  app_not?: InputMaybe<Scalars['Bytes']['input']>;
  app_gt?: InputMaybe<Scalars['Bytes']['input']>;
  app_lt?: InputMaybe<Scalars['Bytes']['input']>;
  app_gte?: InputMaybe<Scalars['Bytes']['input']>;
  app_lte?: InputMaybe<Scalars['Bytes']['input']>;
  app_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  app_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  app_contains?: InputMaybe<Scalars['Bytes']['input']>;
  app_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  reason?: InputMaybe<Scalars['BigInt']['input']>;
  reason_not?: InputMaybe<Scalars['BigInt']['input']>;
  reason_gt?: InputMaybe<Scalars['BigInt']['input']>;
  reason_lt?: InputMaybe<Scalars['BigInt']['input']>;
  reason_gte?: InputMaybe<Scalars['BigInt']['input']>;
  reason_lte?: InputMaybe<Scalars['BigInt']['input']>;
  reason_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  reason_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<JailEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<JailEvent_Filter>>>;
};

export type JailEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'app'
  | 'reason';

export type MemberUnitsUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `member`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  oldUnits: Scalars['BigInt']['output'];
  units: Scalars['BigInt']['output'];
  totalUnits: Scalars['BigInt']['output'];
  pool: Pool;
  poolMember: PoolMember;
};

export type MemberUnitsUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldUnits?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units?: InputMaybe<Scalars['BigInt']['input']>;
  units_not?: InputMaybe<Scalars['BigInt']['input']>;
  units_gt?: InputMaybe<Scalars['BigInt']['input']>;
  units_lt?: InputMaybe<Scalars['BigInt']['input']>;
  units_gte?: InputMaybe<Scalars['BigInt']['input']>;
  units_lte?: InputMaybe<Scalars['BigInt']['input']>;
  units_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolMember?: InputMaybe<Scalars['String']['input']>;
  poolMember_not?: InputMaybe<Scalars['String']['input']>;
  poolMember_gt?: InputMaybe<Scalars['String']['input']>;
  poolMember_lt?: InputMaybe<Scalars['String']['input']>;
  poolMember_gte?: InputMaybe<Scalars['String']['input']>;
  poolMember_lte?: InputMaybe<Scalars['String']['input']>;
  poolMember_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_?: InputMaybe<PoolMember_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MemberUnitsUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<MemberUnitsUpdatedEvent_Filter>>>;
};

export type MemberUnitsUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'oldUnits'
  | 'units'
  | 'totalUnits'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolMember'
  | 'poolMember__id'
  | 'poolMember__createdAtTimestamp'
  | 'poolMember__createdAtBlockNumber'
  | 'poolMember__updatedAtTimestamp'
  | 'poolMember__updatedAtBlockNumber'
  | 'poolMember__units'
  | 'poolMember__isConnected'
  | 'poolMember__totalAmountClaimed'
  | 'poolMember__poolTotalAmountDistributedUntilUpdatedAt'
  | 'poolMember__totalAmountReceivedUntilUpdatedAt'
  | 'poolMember__syncedPerUnitSettledValue'
  | 'poolMember__syncedPerUnitFlowRate';

export type MintedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `operator`
   * addresses[2] = `to`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  operator: Scalars['Bytes']['output'];
  to: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  data: Scalars['Bytes']['output'];
  token: Scalars['Bytes']['output'];
  operatorData: Scalars['Bytes']['output'];
};

export type MintedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to?: InputMaybe<Scalars['Bytes']['input']>;
  to_not?: InputMaybe<Scalars['Bytes']['input']>;
  to_gt?: InputMaybe<Scalars['Bytes']['input']>;
  to_lt?: InputMaybe<Scalars['Bytes']['input']>;
  to_gte?: InputMaybe<Scalars['Bytes']['input']>;
  to_lte?: InputMaybe<Scalars['Bytes']['input']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  to_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  data?: InputMaybe<Scalars['Bytes']['input']>;
  data_not?: InputMaybe<Scalars['Bytes']['input']>;
  data_gt?: InputMaybe<Scalars['Bytes']['input']>;
  data_lt?: InputMaybe<Scalars['Bytes']['input']>;
  data_gte?: InputMaybe<Scalars['Bytes']['input']>;
  data_lte?: InputMaybe<Scalars['Bytes']['input']>;
  data_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_contains?: InputMaybe<Scalars['Bytes']['input']>;
  data_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MintedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<MintedEvent_Filter>>>;
};

export type MintedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'operator'
  | 'to'
  | 'amount'
  | 'data'
  | 'token'
  | 'operatorData';

export type NewPicEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pic` (new Patrician In Charge)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * The address of the `token` (supertoken) the PIC is posting a bond for.
   *
   */
  token: Scalars['Bytes']['output'];
  /**
   * The address of the new Patrician In Charge (PIC).
   *
   */
  pic: Scalars['Bytes']['output'];
  /**
   * The bond the new PIC staked in order to claim the position.
   *
   */
  bond: Scalars['BigInt']['output'];
  /**
   * The flowrate at which the bond is streamed back to the PIC.
   *
   */
  exitRate: Scalars['BigInt']['output'];
};

export type NewPicEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pic?: InputMaybe<Scalars['Bytes']['input']>;
  pic_not?: InputMaybe<Scalars['Bytes']['input']>;
  pic_gt?: InputMaybe<Scalars['Bytes']['input']>;
  pic_lt?: InputMaybe<Scalars['Bytes']['input']>;
  pic_gte?: InputMaybe<Scalars['Bytes']['input']>;
  pic_lte?: InputMaybe<Scalars['Bytes']['input']>;
  pic_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  pic_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  pic_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pic_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  bond?: InputMaybe<Scalars['BigInt']['input']>;
  bond_not?: InputMaybe<Scalars['BigInt']['input']>;
  bond_gt?: InputMaybe<Scalars['BigInt']['input']>;
  bond_lt?: InputMaybe<Scalars['BigInt']['input']>;
  bond_gte?: InputMaybe<Scalars['BigInt']['input']>;
  bond_lte?: InputMaybe<Scalars['BigInt']['input']>;
  bond_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  bond_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  exitRate?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  exitRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  exitRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NewPicEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<NewPicEvent_Filter>>>;
};

export type NewPicEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'pic'
  | 'bond'
  | 'exitRate';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type PppConfigurationChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  liquidationPeriod: Scalars['BigInt']['output'];
  patricianPeriod: Scalars['BigInt']['output'];
};

export type PppConfigurationChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  liquidationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  patricianPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  patricianPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PppConfigurationChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<PppConfigurationChangedEvent_Filter>>>;
};

export type PppConfigurationChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'host'
  | 'superToken'
  | 'isKeySet'
  | 'liquidationPeriod'
  | 'patricianPeriod';

export type Pool = {
  /**
   * ID: poolAddress
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  totalUnits: Scalars['BigInt']['output'];
  totalConnectedUnits: Scalars['BigInt']['output'];
  totalDisconnectedUnits: Scalars['BigInt']['output'];
  totalAmountInstantlyDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalAmountFlowedDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  perUnitSettledValue: Scalars['BigInt']['output'];
  perUnitFlowRate: Scalars['BigInt']['output'];
  /**
   * A member is any account which has more than 0 units in the pool.
   *
   */
  totalMembers: Scalars['Int']['output'];
  /**
   * A connected member is any account which has more than 0 units in the pool and is connected.
   *
   */
  totalConnectedMembers: Scalars['Int']['output'];
  /**
   * A disconnected member is any account which has more than 0 units in the pool and is not connected.
   *
   */
  totalDisconnectedMembers: Scalars['Int']['output'];
  adjustmentFlowRate: Scalars['BigInt']['output'];
  flowRate: Scalars['BigInt']['output'];
  totalBuffer: Scalars['BigInt']['output'];
  token: Token;
  admin: Account;
  poolDistributors: Array<PoolDistributor>;
  poolMembers: Array<PoolMember>;
  poolCreatedEvent: PoolCreatedEvent;
  poolConnectionUpdatedEvents: Array<PoolConnectionUpdatedEvent>;
  bufferAdjustedEvents: Array<BufferAdjustedEvent>;
  instantDistributionUpdatedEvents: Array<InstantDistributionUpdatedEvent>;
  flowDistributionUpdatedEvents: Array<FlowDistributionUpdatedEvent>;
  memberUnitsUpdatedEvents: Array<MemberUnitsUpdatedEvent>;
  distributionClaimedEvents: Array<DistributionClaimedEvent>;
};


export type PoolPoolDistributorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolDistributor_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolDistributor_Filter>;
};


export type PoolPoolMembersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolMember_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolMember_Filter>;
};


export type PoolPoolConnectionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolConnectionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
};


export type PoolBufferAdjustedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BufferAdjustedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BufferAdjustedEvent_Filter>;
};


export type PoolInstantDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InstantDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
};


export type PoolFlowDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
};


export type PoolMemberUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MemberUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
};


export type PoolDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DistributionClaimedEvent_Filter>;
};

export type PoolConnectionUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `poolMember`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  connected: Scalars['Boolean']['output'];
  userData: Scalars['Bytes']['output'];
  pool: Pool;
  poolMember: PoolMember;
};

export type PoolConnectionUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  connected?: InputMaybe<Scalars['Boolean']['input']>;
  connected_not?: InputMaybe<Scalars['Boolean']['input']>;
  connected_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  connected_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolMember?: InputMaybe<Scalars['String']['input']>;
  poolMember_not?: InputMaybe<Scalars['String']['input']>;
  poolMember_gt?: InputMaybe<Scalars['String']['input']>;
  poolMember_lt?: InputMaybe<Scalars['String']['input']>;
  poolMember_gte?: InputMaybe<Scalars['String']['input']>;
  poolMember_lte?: InputMaybe<Scalars['String']['input']>;
  poolMember_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  poolMember_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  poolMember_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  poolMember_?: InputMaybe<PoolMember_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolConnectionUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<PoolConnectionUpdatedEvent_Filter>>>;
};

export type PoolConnectionUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'connected'
  | 'userData'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolMember'
  | 'poolMember__id'
  | 'poolMember__createdAtTimestamp'
  | 'poolMember__createdAtBlockNumber'
  | 'poolMember__updatedAtTimestamp'
  | 'poolMember__updatedAtBlockNumber'
  | 'poolMember__units'
  | 'poolMember__isConnected'
  | 'poolMember__totalAmountClaimed'
  | 'poolMember__poolTotalAmountDistributedUntilUpdatedAt'
  | 'poolMember__totalAmountReceivedUntilUpdatedAt'
  | 'poolMember__syncedPerUnitSettledValue'
  | 'poolMember__syncedPerUnitFlowRate';

export type PoolCreatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `pool`
   * addresses[2] = `caller`
   * addresses[3] = `admin`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  caller: Scalars['Bytes']['output'];
  admin: Scalars['Bytes']['output'];
  pool: Pool;
};

export type PoolCreatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  caller?: InputMaybe<Scalars['Bytes']['input']>;
  caller_not?: InputMaybe<Scalars['Bytes']['input']>;
  caller_gt?: InputMaybe<Scalars['Bytes']['input']>;
  caller_lt?: InputMaybe<Scalars['Bytes']['input']>;
  caller_gte?: InputMaybe<Scalars['Bytes']['input']>;
  caller_lte?: InputMaybe<Scalars['Bytes']['input']>;
  caller_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  caller_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  caller_contains?: InputMaybe<Scalars['Bytes']['input']>;
  caller_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  admin?: InputMaybe<Scalars['Bytes']['input']>;
  admin_not?: InputMaybe<Scalars['Bytes']['input']>;
  admin_gt?: InputMaybe<Scalars['Bytes']['input']>;
  admin_lt?: InputMaybe<Scalars['Bytes']['input']>;
  admin_gte?: InputMaybe<Scalars['Bytes']['input']>;
  admin_lte?: InputMaybe<Scalars['Bytes']['input']>;
  admin_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  admin_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  admin_contains?: InputMaybe<Scalars['Bytes']['input']>;
  admin_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolCreatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<PoolCreatedEvent_Filter>>>;
};

export type PoolCreatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'caller'
  | 'admin'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer';

export type PoolDistributor = {
  /**
   * ID composed of: "poolDistributor"-pool-poolDistributorAddress
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  totalAmountInstantlyDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalAmountFlowedDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalBuffer: Scalars['BigInt']['output'];
  flowRate: Scalars['BigInt']['output'];
  account: Account;
  pool: Pool;
  bufferAdjustedEvents: Array<BufferAdjustedEvent>;
  instantDistributionUpdatedEvents: Array<InstantDistributionUpdatedEvent>;
  flowDistributionUpdatedEvents: Array<FlowDistributionUpdatedEvent>;
};


export type PoolDistributorBufferAdjustedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BufferAdjustedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BufferAdjustedEvent_Filter>;
};


export type PoolDistributorInstantDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InstantDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
};


export type PoolDistributorFlowDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
};

export type PoolDistributor_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountInstantlyDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountInstantlyDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountFlowedDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountFlowedDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBuffer?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBuffer_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  bufferAdjustedEvents_?: InputMaybe<BufferAdjustedEvent_Filter>;
  instantDistributionUpdatedEvents_?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
  flowDistributionUpdatedEvents_?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolDistributor_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<PoolDistributor_Filter>>>;
};

export type PoolDistributor_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'totalAmountFlowedDistributedUntilUpdatedAt'
  | 'totalAmountDistributedUntilUpdatedAt'
  | 'totalBuffer'
  | 'flowRate'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'bufferAdjustedEvents'
  | 'instantDistributionUpdatedEvents'
  | 'flowDistributionUpdatedEvents';

export type PoolMember = {
  /**
   * ID composed of: "poolMember"-poolAddress-poolMemberAddress
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  units: Scalars['BigInt']['output'];
  isConnected: Scalars['Boolean']['output'];
  totalAmountClaimed: Scalars['BigInt']['output'];
  poolTotalAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  totalAmountReceivedUntilUpdatedAt: Scalars['BigInt']['output'];
  syncedPerUnitSettledValue: Scalars['BigInt']['output'];
  syncedPerUnitFlowRate: Scalars['BigInt']['output'];
  account: Account;
  pool: Pool;
  poolConnectionUpdatedEvents: Array<PoolConnectionUpdatedEvent>;
  memberUnitsUpdatedEvents: Array<MemberUnitsUpdatedEvent>;
  distributionClaimedEvents: Array<DistributionClaimedEvent>;
};


export type PoolMemberPoolConnectionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolConnectionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
};


export type PoolMemberMemberUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MemberUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
};


export type PoolMemberDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DistributionClaimedEvent_Filter>;
};

export type PoolMember_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units?: InputMaybe<Scalars['BigInt']['input']>;
  units_not?: InputMaybe<Scalars['BigInt']['input']>;
  units_gt?: InputMaybe<Scalars['BigInt']['input']>;
  units_lt?: InputMaybe<Scalars['BigInt']['input']>;
  units_gte?: InputMaybe<Scalars['BigInt']['input']>;
  units_lte?: InputMaybe<Scalars['BigInt']['input']>;
  units_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isConnected?: InputMaybe<Scalars['Boolean']['input']>;
  isConnected_not?: InputMaybe<Scalars['Boolean']['input']>;
  isConnected_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isConnected_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  totalAmountClaimed?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountClaimed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountClaimed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  poolTotalAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  poolTotalAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  poolTotalAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountReceivedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountReceivedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountReceivedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  syncedPerUnitSettledValue?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitSettledValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  syncedPerUnitSettledValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  syncedPerUnitFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  syncedPerUnitFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  syncedPerUnitFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  pool?: InputMaybe<Scalars['String']['input']>;
  pool_not?: InputMaybe<Scalars['String']['input']>;
  pool_gt?: InputMaybe<Scalars['String']['input']>;
  pool_lt?: InputMaybe<Scalars['String']['input']>;
  pool_gte?: InputMaybe<Scalars['String']['input']>;
  pool_lte?: InputMaybe<Scalars['String']['input']>;
  pool_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pool_contains?: InputMaybe<Scalars['String']['input']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains?: InputMaybe<Scalars['String']['input']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pool_?: InputMaybe<Pool_Filter>;
  poolConnectionUpdatedEvents_?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
  memberUnitsUpdatedEvents_?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
  distributionClaimedEvents_?: InputMaybe<DistributionClaimedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolMember_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<PoolMember_Filter>>>;
};

export type PoolMember_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'units'
  | 'isConnected'
  | 'totalAmountClaimed'
  | 'poolTotalAmountDistributedUntilUpdatedAt'
  | 'totalAmountReceivedUntilUpdatedAt'
  | 'syncedPerUnitSettledValue'
  | 'syncedPerUnitFlowRate'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'pool'
  | 'pool__id'
  | 'pool__createdAtTimestamp'
  | 'pool__createdAtBlockNumber'
  | 'pool__updatedAtTimestamp'
  | 'pool__updatedAtBlockNumber'
  | 'pool__totalUnits'
  | 'pool__totalConnectedUnits'
  | 'pool__totalDisconnectedUnits'
  | 'pool__totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'pool__totalAmountFlowedDistributedUntilUpdatedAt'
  | 'pool__totalAmountDistributedUntilUpdatedAt'
  | 'pool__totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'pool__perUnitSettledValue'
  | 'pool__perUnitFlowRate'
  | 'pool__totalMembers'
  | 'pool__totalConnectedMembers'
  | 'pool__totalDisconnectedMembers'
  | 'pool__adjustmentFlowRate'
  | 'pool__flowRate'
  | 'pool__totalBuffer'
  | 'poolConnectionUpdatedEvents'
  | 'memberUnitsUpdatedEvents'
  | 'distributionClaimedEvents';

export type Pool_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalConnectedUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalConnectedUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalConnectedUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDisconnectedUnits?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDisconnectedUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDisconnectedUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountInstantlyDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountInstantlyDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountInstantlyDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountFlowedDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountFlowedDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountFlowedDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  perUnitSettledValue?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitSettledValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  perUnitSettledValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  perUnitFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  perUnitFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  perUnitFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalMembers?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_not?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMembers_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembers_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMembers?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_not?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_gt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_lt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_gte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_lte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMembers_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMembers_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalDisconnectedMembers?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_not?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_gt?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_lt?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_gte?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_lte?: InputMaybe<Scalars['Int']['input']>;
  totalDisconnectedMembers_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalDisconnectedMembers_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  adjustmentFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  adjustmentFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  adjustmentFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBuffer?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalBuffer_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalBuffer_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  admin?: InputMaybe<Scalars['String']['input']>;
  admin_not?: InputMaybe<Scalars['String']['input']>;
  admin_gt?: InputMaybe<Scalars['String']['input']>;
  admin_lt?: InputMaybe<Scalars['String']['input']>;
  admin_gte?: InputMaybe<Scalars['String']['input']>;
  admin_lte?: InputMaybe<Scalars['String']['input']>;
  admin_in?: InputMaybe<Array<Scalars['String']['input']>>;
  admin_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  admin_contains?: InputMaybe<Scalars['String']['input']>;
  admin_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_not_contains?: InputMaybe<Scalars['String']['input']>;
  admin_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_starts_with?: InputMaybe<Scalars['String']['input']>;
  admin_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  admin_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_ends_with?: InputMaybe<Scalars['String']['input']>;
  admin_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  admin_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  admin_?: InputMaybe<Account_Filter>;
  poolDistributors_?: InputMaybe<PoolDistributor_Filter>;
  poolMembers_?: InputMaybe<PoolMember_Filter>;
  poolCreatedEvent_?: InputMaybe<PoolCreatedEvent_Filter>;
  poolConnectionUpdatedEvents_?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
  bufferAdjustedEvents_?: InputMaybe<BufferAdjustedEvent_Filter>;
  instantDistributionUpdatedEvents_?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
  flowDistributionUpdatedEvents_?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
  memberUnitsUpdatedEvents_?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
  distributionClaimedEvents_?: InputMaybe<DistributionClaimedEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
};

export type Pool_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'totalUnits'
  | 'totalConnectedUnits'
  | 'totalDisconnectedUnits'
  | 'totalAmountInstantlyDistributedUntilUpdatedAt'
  | 'totalAmountFlowedDistributedUntilUpdatedAt'
  | 'totalAmountDistributedUntilUpdatedAt'
  | 'totalFlowAdjustmentAmountDistributedUntilUpdatedAt'
  | 'perUnitSettledValue'
  | 'perUnitFlowRate'
  | 'totalMembers'
  | 'totalConnectedMembers'
  | 'totalDisconnectedMembers'
  | 'adjustmentFlowRate'
  | 'flowRate'
  | 'totalBuffer'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'admin'
  | 'admin__id'
  | 'admin__createdAtTimestamp'
  | 'admin__createdAtBlockNumber'
  | 'admin__updatedAtTimestamp'
  | 'admin__updatedAtBlockNumber'
  | 'admin__isSuperApp'
  | 'poolDistributors'
  | 'poolMembers'
  | 'poolCreatedEvent'
  | 'poolCreatedEvent__id'
  | 'poolCreatedEvent__transactionHash'
  | 'poolCreatedEvent__gasPrice'
  | 'poolCreatedEvent__gasUsed'
  | 'poolCreatedEvent__timestamp'
  | 'poolCreatedEvent__name'
  | 'poolCreatedEvent__blockNumber'
  | 'poolCreatedEvent__logIndex'
  | 'poolCreatedEvent__order'
  | 'poolCreatedEvent__token'
  | 'poolCreatedEvent__caller'
  | 'poolCreatedEvent__admin'
  | 'poolConnectionUpdatedEvents'
  | 'bufferAdjustedEvents'
  | 'instantDistributionUpdatedEvents'
  | 'flowDistributionUpdatedEvents'
  | 'memberUnitsUpdatedEvents'
  | 'distributionClaimedEvents';

export type Query = {
  flowUpdatedEvent?: Maybe<FlowUpdatedEvent>;
  flowUpdatedEvents: Array<FlowUpdatedEvent>;
  flowOperatorUpdatedEvent?: Maybe<FlowOperatorUpdatedEvent>;
  flowOperatorUpdatedEvents: Array<FlowOperatorUpdatedEvent>;
  indexCreatedEvent?: Maybe<IndexCreatedEvent>;
  indexCreatedEvents: Array<IndexCreatedEvent>;
  indexDistributionClaimedEvent?: Maybe<IndexDistributionClaimedEvent>;
  indexDistributionClaimedEvents: Array<IndexDistributionClaimedEvent>;
  indexUpdatedEvent?: Maybe<IndexUpdatedEvent>;
  indexUpdatedEvents: Array<IndexUpdatedEvent>;
  indexSubscribedEvent?: Maybe<IndexSubscribedEvent>;
  indexSubscribedEvents: Array<IndexSubscribedEvent>;
  indexUnitsUpdatedEvent?: Maybe<IndexUnitsUpdatedEvent>;
  indexUnitsUpdatedEvents: Array<IndexUnitsUpdatedEvent>;
  indexUnsubscribedEvent?: Maybe<IndexUnsubscribedEvent>;
  indexUnsubscribedEvents: Array<IndexUnsubscribedEvent>;
  subscriptionApprovedEvent?: Maybe<SubscriptionApprovedEvent>;
  subscriptionApprovedEvents: Array<SubscriptionApprovedEvent>;
  subscriptionDistributionClaimedEvent?: Maybe<SubscriptionDistributionClaimedEvent>;
  subscriptionDistributionClaimedEvents: Array<SubscriptionDistributionClaimedEvent>;
  subscriptionRevokedEvent?: Maybe<SubscriptionRevokedEvent>;
  subscriptionRevokedEvents: Array<SubscriptionRevokedEvent>;
  subscriptionUnitsUpdatedEvent?: Maybe<SubscriptionUnitsUpdatedEvent>;
  subscriptionUnitsUpdatedEvents: Array<SubscriptionUnitsUpdatedEvent>;
  poolCreatedEvent?: Maybe<PoolCreatedEvent>;
  poolCreatedEvents: Array<PoolCreatedEvent>;
  poolConnectionUpdatedEvent?: Maybe<PoolConnectionUpdatedEvent>;
  poolConnectionUpdatedEvents: Array<PoolConnectionUpdatedEvent>;
  bufferAdjustedEvent?: Maybe<BufferAdjustedEvent>;
  bufferAdjustedEvents: Array<BufferAdjustedEvent>;
  instantDistributionUpdatedEvent?: Maybe<InstantDistributionUpdatedEvent>;
  instantDistributionUpdatedEvents: Array<InstantDistributionUpdatedEvent>;
  flowDistributionUpdatedEvent?: Maybe<FlowDistributionUpdatedEvent>;
  flowDistributionUpdatedEvents: Array<FlowDistributionUpdatedEvent>;
  distributionClaimedEvent?: Maybe<DistributionClaimedEvent>;
  distributionClaimedEvents: Array<DistributionClaimedEvent>;
  memberUnitsUpdatedEvent?: Maybe<MemberUnitsUpdatedEvent>;
  memberUnitsUpdatedEvents: Array<MemberUnitsUpdatedEvent>;
  agreementClassRegisteredEvent?: Maybe<AgreementClassRegisteredEvent>;
  agreementClassRegisteredEvents: Array<AgreementClassRegisteredEvent>;
  agreementClassUpdatedEvent?: Maybe<AgreementClassUpdatedEvent>;
  agreementClassUpdatedEvents: Array<AgreementClassUpdatedEvent>;
  appRegisteredEvent?: Maybe<AppRegisteredEvent>;
  appRegisteredEvents: Array<AppRegisteredEvent>;
  governanceReplacedEvent?: Maybe<GovernanceReplacedEvent>;
  governanceReplacedEvents: Array<GovernanceReplacedEvent>;
  jailEvent?: Maybe<JailEvent>;
  jailEvents: Array<JailEvent>;
  superTokenFactoryUpdatedEvent?: Maybe<SuperTokenFactoryUpdatedEvent>;
  superTokenFactoryUpdatedEvents: Array<SuperTokenFactoryUpdatedEvent>;
  superTokenLogicUpdatedEvent?: Maybe<SuperTokenLogicUpdatedEvent>;
  superTokenLogicUpdatedEvents: Array<SuperTokenLogicUpdatedEvent>;
  roleAdminChangedEvent?: Maybe<RoleAdminChangedEvent>;
  roleAdminChangedEvents: Array<RoleAdminChangedEvent>;
  roleGrantedEvent?: Maybe<RoleGrantedEvent>;
  roleGrantedEvents: Array<RoleGrantedEvent>;
  roleRevokedEvent?: Maybe<RoleRevokedEvent>;
  roleRevokedEvents: Array<RoleRevokedEvent>;
  setEvent?: Maybe<SetEvent>;
  setEvents: Array<SetEvent>;
  cfav1LiquidationPeriodChangedEvent?: Maybe<CfAv1LiquidationPeriodChangedEvent>;
  cfav1LiquidationPeriodChangedEvents: Array<CfAv1LiquidationPeriodChangedEvent>;
  configChangedEvent?: Maybe<ConfigChangedEvent>;
  configChangedEvents: Array<ConfigChangedEvent>;
  rewardAddressChangedEvent?: Maybe<RewardAddressChangedEvent>;
  rewardAddressChangedEvents: Array<RewardAddressChangedEvent>;
  pppconfigurationChangedEvent?: Maybe<PppConfigurationChangedEvent>;
  pppconfigurationChangedEvents: Array<PppConfigurationChangedEvent>;
  superTokenMinimumDepositChangedEvent?: Maybe<SuperTokenMinimumDepositChangedEvent>;
  superTokenMinimumDepositChangedEvents: Array<SuperTokenMinimumDepositChangedEvent>;
  trustedForwarderChangedEvent?: Maybe<TrustedForwarderChangedEvent>;
  trustedForwarderChangedEvents: Array<TrustedForwarderChangedEvent>;
  agreementLiquidatedByEvent?: Maybe<AgreementLiquidatedByEvent>;
  agreementLiquidatedByEvents: Array<AgreementLiquidatedByEvent>;
  agreementLiquidatedV2Event?: Maybe<AgreementLiquidatedV2Event>;
  agreementLiquidatedV2Events: Array<AgreementLiquidatedV2Event>;
  burnedEvent?: Maybe<BurnedEvent>;
  burnedEvents: Array<BurnedEvent>;
  mintedEvent?: Maybe<MintedEvent>;
  mintedEvents: Array<MintedEvent>;
  sentEvent?: Maybe<SentEvent>;
  sentEvents: Array<SentEvent>;
  transferEvent?: Maybe<TransferEvent>;
  transferEvents: Array<TransferEvent>;
  tokenDowngradedEvent?: Maybe<TokenDowngradedEvent>;
  tokenDowngradedEvents: Array<TokenDowngradedEvent>;
  tokenUpgradedEvent?: Maybe<TokenUpgradedEvent>;
  tokenUpgradedEvents: Array<TokenUpgradedEvent>;
  approvalEvent?: Maybe<ApprovalEvent>;
  approvalEvents: Array<ApprovalEvent>;
  customSuperTokenCreatedEvent?: Maybe<CustomSuperTokenCreatedEvent>;
  customSuperTokenCreatedEvents: Array<CustomSuperTokenCreatedEvent>;
  superTokenCreatedEvent?: Maybe<SuperTokenCreatedEvent>;
  superTokenCreatedEvents: Array<SuperTokenCreatedEvent>;
  superTokenLogicCreatedEvent?: Maybe<SuperTokenLogicCreatedEvent>;
  superTokenLogicCreatedEvents: Array<SuperTokenLogicCreatedEvent>;
  newPICEvent?: Maybe<NewPicEvent>;
  newPICEvents: Array<NewPicEvent>;
  exitRateChangedEvent?: Maybe<ExitRateChangedEvent>;
  exitRateChangedEvents: Array<ExitRateChangedEvent>;
  bondIncreasedEvent?: Maybe<BondIncreasedEvent>;
  bondIncreasedEvents: Array<BondIncreasedEvent>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  poolMember?: Maybe<PoolMember>;
  poolMembers: Array<PoolMember>;
  poolDistributor?: Maybe<PoolDistributor>;
  poolDistributors: Array<PoolDistributor>;
  index?: Maybe<Index>;
  indexes: Array<Index>;
  indexSubscription?: Maybe<IndexSubscription>;
  indexSubscriptions: Array<IndexSubscription>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  flowOperator?: Maybe<FlowOperator>;
  flowOperators: Array<FlowOperator>;
  streamPeriod?: Maybe<StreamPeriod>;
  streamPeriods: Array<StreamPeriod>;
  tokenGovernanceConfig?: Maybe<TokenGovernanceConfig>;
  tokenGovernanceConfigs: Array<TokenGovernanceConfig>;
  streamRevision?: Maybe<StreamRevision>;
  streamRevisions: Array<StreamRevision>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  resolverEntry?: Maybe<ResolverEntry>;
  resolverEntries: Array<ResolverEntry>;
  accountTokenSnapshot?: Maybe<AccountTokenSnapshot>;
  accountTokenSnapshots: Array<AccountTokenSnapshot>;
  accountTokenSnapshotLog?: Maybe<AccountTokenSnapshotLog>;
  accountTokenSnapshotLogs: Array<AccountTokenSnapshotLog>;
  tokenStatistic?: Maybe<TokenStatistic>;
  tokenStatistics: Array<TokenStatistic>;
  tokenStatisticLog?: Maybe<TokenStatisticLog>;
  tokenStatisticLogs: Array<TokenStatisticLog>;
  sfmeta?: Maybe<SfMeta>;
  sfmetas: Array<SfMeta>;
  event?: Maybe<Event>;
  events: Array<Event>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryFlowUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowOperatorUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowOperatorUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperatorUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperatorUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexDistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexSubscribedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexSubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscribedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUnsubscribedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexUnsubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnsubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnsubscribedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionApprovedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionApprovedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionApprovedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionApprovedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionDistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionRevokedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionRevokedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionRevokedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionRevokedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySubscriptionUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolConnectionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolConnectionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolConnectionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBufferAdjustedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBufferAdjustedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BufferAdjustedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BufferAdjustedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInstantDistributionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryInstantDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InstantDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowDistributionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMemberUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMemberUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MemberUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementClassRegisteredEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementClassRegisteredEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementClassRegisteredEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementClassRegisteredEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementClassUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementClassUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementClassUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementClassUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAppRegisteredEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAppRegisteredEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AppRegisteredEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AppRegisteredEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGovernanceReplacedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGovernanceReplacedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GovernanceReplacedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<GovernanceReplacedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryJailEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryJailEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<JailEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JailEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenFactoryUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenFactoryUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenFactoryUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenFactoryUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenLogicUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenLogicUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenLogicUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenLogicUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleAdminChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleAdminChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleAdminChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleAdminChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleGrantedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleGrantedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleGrantedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleGrantedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleRevokedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRoleRevokedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleRevokedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleRevokedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySetEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySetEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SetEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SetEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCfav1LiquidationPeriodChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCfav1LiquidationPeriodChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CfAv1LiquidationPeriodChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CfAv1LiquidationPeriodChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryConfigChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryConfigChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConfigChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ConfigChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRewardAddressChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRewardAddressChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RewardAddressChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RewardAddressChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPppconfigurationChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPppconfigurationChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PppConfigurationChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PppConfigurationChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenMinimumDepositChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenMinimumDepositChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenMinimumDepositChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenMinimumDepositChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTrustedForwarderChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTrustedForwarderChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrustedForwarderChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TrustedForwarderChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementLiquidatedByEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementLiquidatedByEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementLiquidatedByEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementLiquidatedByEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementLiquidatedV2EventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAgreementLiquidatedV2EventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementLiquidatedV2Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementLiquidatedV2Event_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBurnedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBurnedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BurnedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BurnedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMintedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMintedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MintedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MintedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySentEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySentEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SentEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SentEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransferEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransferEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDowngradedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDowngradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDowngradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenDowngradedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenUpgradedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenUpgradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenUpgradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenUpgradedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryApprovalEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryApprovalEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApprovalEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ApprovalEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCustomSuperTokenCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCustomSuperTokenCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomSuperTokenCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CustomSuperTokenCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenLogicCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySuperTokenLogicCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenLogicCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenLogicCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryNewPicEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryNewPicEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NewPicEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NewPicEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryExitRateChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryExitRateChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExitRateChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ExitRateChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBondIncreasedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryBondIncreasedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BondIncreasedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BondIncreasedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Account_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Pool_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolMemberArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolMembersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolMember_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolMember_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolDistributorArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolDistributorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolDistributor_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolDistributor_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Index_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Index_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexSubscriptionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIndexSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscription_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Stream_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowOperatorArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryFlowOperatorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperator_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamPeriodArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamPeriodsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StreamPeriod_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StreamPeriod_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenGovernanceConfigArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenGovernanceConfigsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenGovernanceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenGovernanceConfig_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamRevisionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStreamRevisionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StreamRevision_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StreamRevision_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryResolverEntryArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryResolverEntriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResolverEntry_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ResolverEntry_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountTokenSnapshotArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountTokenSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshot_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshot_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountTokenSnapshotLogArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountTokenSnapshotLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshotLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshotLog_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenStatisticArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenStatisticsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenStatistic_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenStatisticLogArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenStatisticLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenStatisticLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenStatisticLog_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySfmetaArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerySfmetasArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SfMeta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SfMeta_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Event_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type ResolverEntry = {
  /**
   * ID: the keccak256 hash of the set name
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  targetAddress: Scalars['Bytes']['output'];
  isToken: Scalars['Boolean']['output'];
  isListed: Scalars['Boolean']['output'];
  setEvents: Array<SetEvent>;
};


export type ResolverEntrySetEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SetEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SetEvent_Filter>;
};

export type ResolverEntry_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  targetAddress?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  targetAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  targetAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  targetAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isToken?: InputMaybe<Scalars['Boolean']['input']>;
  isToken_not?: InputMaybe<Scalars['Boolean']['input']>;
  isToken_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isToken_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isListed?: InputMaybe<Scalars['Boolean']['input']>;
  isListed_not?: InputMaybe<Scalars['Boolean']['input']>;
  isListed_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isListed_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  setEvents_?: InputMaybe<SetEvent_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ResolverEntry_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<ResolverEntry_Filter>>>;
};

export type ResolverEntry_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'targetAddress'
  | 'isToken'
  | 'isListed'
  | 'setEvents';

export type RewardAddressChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   * addresses[3] = `rewardAddress`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  rewardAddress: Scalars['Bytes']['output'];
};

export type RewardAddressChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  rewardAddress?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RewardAddressChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<RewardAddressChangedEvent_Filter>>>;
};

export type RewardAddressChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'host'
  | 'superToken'
  | 'isKeySet'
  | 'rewardAddress';

export type RoleAdminChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `previousAdminRole`
   * addresses[1] = `newAdminRole`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  role: Scalars['Bytes']['output'];
  previousAdminRole: Scalars['Bytes']['output'];
  newAdminRole: Scalars['Bytes']['output'];
};

export type RoleAdminChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  role?: InputMaybe<Scalars['Bytes']['input']>;
  role_not?: InputMaybe<Scalars['Bytes']['input']>;
  role_gt?: InputMaybe<Scalars['Bytes']['input']>;
  role_lt?: InputMaybe<Scalars['Bytes']['input']>;
  role_gte?: InputMaybe<Scalars['Bytes']['input']>;
  role_lte?: InputMaybe<Scalars['Bytes']['input']>;
  role_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_contains?: InputMaybe<Scalars['Bytes']['input']>;
  role_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_not?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_gt?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_lt?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_gte?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_lte?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  previousAdminRole_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  previousAdminRole_contains?: InputMaybe<Scalars['Bytes']['input']>;
  previousAdminRole_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_not?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newAdminRole_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newAdminRole_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newAdminRole_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RoleAdminChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<RoleAdminChangedEvent_Filter>>>;
};

export type RoleAdminChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'role'
  | 'previousAdminRole'
  | 'newAdminRole';

export type RoleGrantedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `account`
   * addresses[1] = `sender`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  role: Scalars['Bytes']['output'];
  account: Scalars['Bytes']['output'];
  sender: Scalars['Bytes']['output'];
};

export type RoleGrantedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  role?: InputMaybe<Scalars['Bytes']['input']>;
  role_not?: InputMaybe<Scalars['Bytes']['input']>;
  role_gt?: InputMaybe<Scalars['Bytes']['input']>;
  role_lt?: InputMaybe<Scalars['Bytes']['input']>;
  role_gte?: InputMaybe<Scalars['Bytes']['input']>;
  role_lte?: InputMaybe<Scalars['Bytes']['input']>;
  role_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_contains?: InputMaybe<Scalars['Bytes']['input']>;
  role_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account?: InputMaybe<Scalars['Bytes']['input']>;
  account_not?: InputMaybe<Scalars['Bytes']['input']>;
  account_gt?: InputMaybe<Scalars['Bytes']['input']>;
  account_lt?: InputMaybe<Scalars['Bytes']['input']>;
  account_gte?: InputMaybe<Scalars['Bytes']['input']>;
  account_lte?: InputMaybe<Scalars['Bytes']['input']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  account_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RoleGrantedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<RoleGrantedEvent_Filter>>>;
};

export type RoleGrantedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'role'
  | 'account'
  | 'sender';

export type RoleRevokedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `account`
   * addresses[1] = `sender`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  role: Scalars['Bytes']['output'];
  account: Scalars['Bytes']['output'];
  sender: Scalars['Bytes']['output'];
};

export type RoleRevokedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  role?: InputMaybe<Scalars['Bytes']['input']>;
  role_not?: InputMaybe<Scalars['Bytes']['input']>;
  role_gt?: InputMaybe<Scalars['Bytes']['input']>;
  role_lt?: InputMaybe<Scalars['Bytes']['input']>;
  role_gte?: InputMaybe<Scalars['Bytes']['input']>;
  role_lte?: InputMaybe<Scalars['Bytes']['input']>;
  role_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  role_contains?: InputMaybe<Scalars['Bytes']['input']>;
  role_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account?: InputMaybe<Scalars['Bytes']['input']>;
  account_not?: InputMaybe<Scalars['Bytes']['input']>;
  account_gt?: InputMaybe<Scalars['Bytes']['input']>;
  account_lt?: InputMaybe<Scalars['Bytes']['input']>;
  account_gte?: InputMaybe<Scalars['Bytes']['input']>;
  account_lte?: InputMaybe<Scalars['Bytes']['input']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  account_contains?: InputMaybe<Scalars['Bytes']['input']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lt?: InputMaybe<Scalars['Bytes']['input']>;
  sender_gte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_lte?: InputMaybe<Scalars['Bytes']['input']>;
  sender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  sender_contains?: InputMaybe<Scalars['Bytes']['input']>;
  sender_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RoleRevokedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<RoleRevokedEvent_Filter>>>;
};

export type RoleRevokedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'role'
  | 'account'
  | 'sender';

export type SfMeta = {
  /**
   * The id is the commit hash.
   *
   */
  id: Scalars['ID']['output'];
  timestamp: Scalars['BigInt']['output'];
  blockNumber: Scalars['BigInt']['output'];
  /**
   * Whether the branch is feature/dev/v1.
   *
   */
  configuration: Scalars['String']['output'];
  /**
   * The branch the current deployment is coming from.
   *
   */
  branch: Scalars['String']['output'];
  /**
   * The subgraph package.json semver version of the current deployment.
   *
   */
  packageVersion: Scalars['String']['output'];
};

export type SfMeta_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  configuration?: InputMaybe<Scalars['String']['input']>;
  configuration_not?: InputMaybe<Scalars['String']['input']>;
  configuration_gt?: InputMaybe<Scalars['String']['input']>;
  configuration_lt?: InputMaybe<Scalars['String']['input']>;
  configuration_gte?: InputMaybe<Scalars['String']['input']>;
  configuration_lte?: InputMaybe<Scalars['String']['input']>;
  configuration_in?: InputMaybe<Array<Scalars['String']['input']>>;
  configuration_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  configuration_contains?: InputMaybe<Scalars['String']['input']>;
  configuration_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  configuration_not_contains?: InputMaybe<Scalars['String']['input']>;
  configuration_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  configuration_starts_with?: InputMaybe<Scalars['String']['input']>;
  configuration_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  configuration_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  configuration_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  configuration_ends_with?: InputMaybe<Scalars['String']['input']>;
  configuration_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  configuration_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  configuration_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  branch?: InputMaybe<Scalars['String']['input']>;
  branch_not?: InputMaybe<Scalars['String']['input']>;
  branch_gt?: InputMaybe<Scalars['String']['input']>;
  branch_lt?: InputMaybe<Scalars['String']['input']>;
  branch_gte?: InputMaybe<Scalars['String']['input']>;
  branch_lte?: InputMaybe<Scalars['String']['input']>;
  branch_in?: InputMaybe<Array<Scalars['String']['input']>>;
  branch_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  branch_contains?: InputMaybe<Scalars['String']['input']>;
  branch_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  branch_not_contains?: InputMaybe<Scalars['String']['input']>;
  branch_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  branch_starts_with?: InputMaybe<Scalars['String']['input']>;
  branch_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  branch_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  branch_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  branch_ends_with?: InputMaybe<Scalars['String']['input']>;
  branch_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  branch_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  branch_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not?: InputMaybe<Scalars['String']['input']>;
  packageVersion_gt?: InputMaybe<Scalars['String']['input']>;
  packageVersion_lt?: InputMaybe<Scalars['String']['input']>;
  packageVersion_gte?: InputMaybe<Scalars['String']['input']>;
  packageVersion_lte?: InputMaybe<Scalars['String']['input']>;
  packageVersion_in?: InputMaybe<Array<Scalars['String']['input']>>;
  packageVersion_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  packageVersion_contains?: InputMaybe<Scalars['String']['input']>;
  packageVersion_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion_starts_with?: InputMaybe<Scalars['String']['input']>;
  packageVersion_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion_ends_with?: InputMaybe<Scalars['String']['input']>;
  packageVersion_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  packageVersion_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SfMeta_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SfMeta_Filter>>>;
};

export type SfMeta_OrderBy =
  | 'id'
  | 'timestamp'
  | 'blockNumber'
  | 'configuration'
  | 'branch'
  | 'packageVersion';

export type SentEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `operator`
   * addresses[2] = `from`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  operator: Scalars['Bytes']['output'];
  from: Scalars['Bytes']['output'];
  to: Scalars['Bytes']['output'];
  token: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  data: Scalars['Bytes']['output'];
  operatorData: Scalars['Bytes']['output'];
};

export type SentEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from?: InputMaybe<Scalars['Bytes']['input']>;
  from_not?: InputMaybe<Scalars['Bytes']['input']>;
  from_gt?: InputMaybe<Scalars['Bytes']['input']>;
  from_lt?: InputMaybe<Scalars['Bytes']['input']>;
  from_gte?: InputMaybe<Scalars['Bytes']['input']>;
  from_lte?: InputMaybe<Scalars['Bytes']['input']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  from_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to?: InputMaybe<Scalars['Bytes']['input']>;
  to_not?: InputMaybe<Scalars['Bytes']['input']>;
  to_gt?: InputMaybe<Scalars['Bytes']['input']>;
  to_lt?: InputMaybe<Scalars['Bytes']['input']>;
  to_gte?: InputMaybe<Scalars['Bytes']['input']>;
  to_lte?: InputMaybe<Scalars['Bytes']['input']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  to_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  data?: InputMaybe<Scalars['Bytes']['input']>;
  data_not?: InputMaybe<Scalars['Bytes']['input']>;
  data_gt?: InputMaybe<Scalars['Bytes']['input']>;
  data_lt?: InputMaybe<Scalars['Bytes']['input']>;
  data_gte?: InputMaybe<Scalars['Bytes']['input']>;
  data_lte?: InputMaybe<Scalars['Bytes']['input']>;
  data_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  data_contains?: InputMaybe<Scalars['Bytes']['input']>;
  data_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operatorData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operatorData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SentEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SentEvent_Filter>>>;
};

export type SentEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'operator'
  | 'from'
  | 'to'
  | 'token'
  | 'amount'
  | 'data'
  | 'operatorData';

export type SetEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Empty addresses array.
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  /**
   * Because the name property is indexed, the
   * returned value will be a keccak256 hash
   * of the string.
   *
   */
  hashedName: Scalars['Bytes']['output'];
  target: Scalars['Bytes']['output'];
  resolverEntry: ResolverEntry;
};

export type SetEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  hashedName?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_not?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_gt?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_lt?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_gte?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_lte?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  hashedName_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  hashedName_contains?: InputMaybe<Scalars['Bytes']['input']>;
  hashedName_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  target?: InputMaybe<Scalars['Bytes']['input']>;
  target_not?: InputMaybe<Scalars['Bytes']['input']>;
  target_gt?: InputMaybe<Scalars['Bytes']['input']>;
  target_lt?: InputMaybe<Scalars['Bytes']['input']>;
  target_gte?: InputMaybe<Scalars['Bytes']['input']>;
  target_lte?: InputMaybe<Scalars['Bytes']['input']>;
  target_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  target_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  target_contains?: InputMaybe<Scalars['Bytes']['input']>;
  target_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  resolverEntry?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_gt?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_lt?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_gte?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_lte?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resolverEntry_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  resolverEntry_contains?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_contains?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_starts_with?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_ends_with?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  resolverEntry_?: InputMaybe<ResolverEntry_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SetEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SetEvent_Filter>>>;
};

export type SetEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'hashedName'
  | 'target'
  | 'resolverEntry'
  | 'resolverEntry__id'
  | 'resolverEntry__createdAtTimestamp'
  | 'resolverEntry__createdAtBlockNumber'
  | 'resolverEntry__updatedAtTimestamp'
  | 'resolverEntry__updatedAtBlockNumber'
  | 'resolverEntry__targetAddress'
  | 'resolverEntry__isToken'
  | 'resolverEntry__isListed';

/**
 * Stream: A higher order entity that represents the lifetime of a stream between a `sender` and a `receiver`.
 * A account can start a stream, update the flow rate, but when they close it, it is considered "dead".
 * The next stream you create with the same `sender` and `receiver` will create a new stream entity.
 * Therefore, multiple stream entities can be created between the same `sender` and `receiver`.
 *
 */
export type Stream = {
  /**
   * ID composed of: senderAddress-receiverAddress-tokenAddress-revisionIndex
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  currentFlowRate: Scalars['BigInt']['output'];
  deposit: Scalars['BigInt']['output'];
  /**
   * The amount streamed until `updatedAtTimestamp`/`updatedAtBlock`.
   * The formula to get the current streamed amount is:
   * `streamedUntilUpdatedAt + ((currentTime in seconds) - updatedAtTimestamp) * currentFlowRate`.
   *
   */
  streamedUntilUpdatedAt: Scalars['BigInt']['output'];
  token: Token;
  sender: Account;
  receiver: Account;
  /**
   * The `userData` stored on the Stream is the last `userData` that was set in a `FlowUpdatedEvent`,
   * for this particular stream. To see the historical `userData` for this stream, you can query the `flowUpdatedEvents` field.
   *
   */
  userData: Scalars['Bytes']['output'];
  flowUpdatedEvents: Array<FlowUpdatedEvent>;
  streamPeriods: Array<StreamPeriod>;
};


/**
 * Stream: A higher order entity that represents the lifetime of a stream between a `sender` and a `receiver`.
 * A account can start a stream, update the flow rate, but when they close it, it is considered "dead".
 * The next stream you create with the same `sender` and `receiver` will create a new stream entity.
 * Therefore, multiple stream entities can be created between the same `sender` and `receiver`.
 *
 */
export type StreamFlowUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowUpdatedEvent_Filter>;
};


/**
 * Stream: A higher order entity that represents the lifetime of a stream between a `sender` and a `receiver`.
 * A account can start a stream, update the flow rate, but when they close it, it is considered "dead".
 * The next stream you create with the same `sender` and `receiver` will create a new stream entity.
 * Therefore, multiple stream entities can be created between the same `sender` and `receiver`.
 *
 */
export type StreamStreamPeriodsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StreamPeriod_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StreamPeriod_Filter>;
};

/**
 * StreamPeriod: A higher order entity that represents a period of time in a Stream with a constant flowRate.
 *
 */
export type StreamPeriod = {
  /**
   * ID composed of: streamId - periodRevisionIndex
   *
   */
  id: Scalars['ID']['output'];
  stream: Stream;
  sender: Account;
  receiver: Account;
  token: Token;
  flowRate: Scalars['BigInt']['output'];
  deposit: Scalars['BigInt']['output'];
  startedAtTimestamp: Scalars['BigInt']['output'];
  startedAtBlockNumber: Scalars['BigInt']['output'];
  startedAtEvent: FlowUpdatedEvent;
  /**
   * Following values are null until the StreamPeriod is terminated
   *
   */
  stoppedAtTimestamp?: Maybe<Scalars['BigInt']['output']>;
  stoppedAtBlockNumber?: Maybe<Scalars['BigInt']['output']>;
  stoppedAtEvent?: Maybe<FlowUpdatedEvent>;
  totalAmountStreamed?: Maybe<Scalars['BigInt']['output']>;
};

export type StreamPeriod_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  stream?: InputMaybe<Scalars['String']['input']>;
  stream_not?: InputMaybe<Scalars['String']['input']>;
  stream_gt?: InputMaybe<Scalars['String']['input']>;
  stream_lt?: InputMaybe<Scalars['String']['input']>;
  stream_gte?: InputMaybe<Scalars['String']['input']>;
  stream_lte?: InputMaybe<Scalars['String']['input']>;
  stream_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stream_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stream_contains?: InputMaybe<Scalars['String']['input']>;
  stream_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_contains?: InputMaybe<Scalars['String']['input']>;
  stream_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_starts_with?: InputMaybe<Scalars['String']['input']>;
  stream_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stream_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_ends_with?: InputMaybe<Scalars['String']['input']>;
  stream_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stream_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stream_?: InputMaybe<Stream_Filter>;
  sender?: InputMaybe<Scalars['String']['input']>;
  sender_not?: InputMaybe<Scalars['String']['input']>;
  sender_gt?: InputMaybe<Scalars['String']['input']>;
  sender_lt?: InputMaybe<Scalars['String']['input']>;
  sender_gte?: InputMaybe<Scalars['String']['input']>;
  sender_lte?: InputMaybe<Scalars['String']['input']>;
  sender_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_contains?: InputMaybe<Scalars['String']['input']>;
  sender_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_?: InputMaybe<Account_Filter>;
  receiver?: InputMaybe<Scalars['String']['input']>;
  receiver_not?: InputMaybe<Scalars['String']['input']>;
  receiver_gt?: InputMaybe<Scalars['String']['input']>;
  receiver_lt?: InputMaybe<Scalars['String']['input']>;
  receiver_gte?: InputMaybe<Scalars['String']['input']>;
  receiver_lte?: InputMaybe<Scalars['String']['input']>;
  receiver_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiver_contains?: InputMaybe<Scalars['String']['input']>;
  receiver_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiver_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_starts_with?: InputMaybe<Scalars['String']['input']>;
  receiver_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  receiver_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiver_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiver_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_?: InputMaybe<Account_Filter>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  flowRate?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  flowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  flowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startedAtEvent?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_gt?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_lt?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_gte?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_lte?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_in?: InputMaybe<Array<Scalars['String']['input']>>;
  startedAtEvent_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  startedAtEvent_contains?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_contains?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_starts_with?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_ends_with?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  startedAtEvent_?: InputMaybe<FlowUpdatedEvent_Filter>;
  stoppedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stoppedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stoppedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stoppedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stoppedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stoppedAtEvent?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_gt?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_lt?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_gte?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_lte?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stoppedAtEvent_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  stoppedAtEvent_contains?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_contains?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_starts_with?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_ends_with?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  stoppedAtEvent_?: InputMaybe<FlowUpdatedEvent_Filter>;
  totalAmountStreamed?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<StreamPeriod_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<StreamPeriod_Filter>>>;
};

export type StreamPeriod_OrderBy =
  | 'id'
  | 'stream'
  | 'stream__id'
  | 'stream__createdAtTimestamp'
  | 'stream__createdAtBlockNumber'
  | 'stream__updatedAtTimestamp'
  | 'stream__updatedAtBlockNumber'
  | 'stream__currentFlowRate'
  | 'stream__deposit'
  | 'stream__streamedUntilUpdatedAt'
  | 'stream__userData'
  | 'sender'
  | 'sender__id'
  | 'sender__createdAtTimestamp'
  | 'sender__createdAtBlockNumber'
  | 'sender__updatedAtTimestamp'
  | 'sender__updatedAtBlockNumber'
  | 'sender__isSuperApp'
  | 'receiver'
  | 'receiver__id'
  | 'receiver__createdAtTimestamp'
  | 'receiver__createdAtBlockNumber'
  | 'receiver__updatedAtTimestamp'
  | 'receiver__updatedAtBlockNumber'
  | 'receiver__isSuperApp'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'flowRate'
  | 'deposit'
  | 'startedAtTimestamp'
  | 'startedAtBlockNumber'
  | 'startedAtEvent'
  | 'startedAtEvent__id'
  | 'startedAtEvent__transactionHash'
  | 'startedAtEvent__gasPrice'
  | 'startedAtEvent__gasUsed'
  | 'startedAtEvent__timestamp'
  | 'startedAtEvent__name'
  | 'startedAtEvent__blockNumber'
  | 'startedAtEvent__logIndex'
  | 'startedAtEvent__order'
  | 'startedAtEvent__token'
  | 'startedAtEvent__sender'
  | 'startedAtEvent__receiver'
  | 'startedAtEvent__flowOperator'
  | 'startedAtEvent__flowRate'
  | 'startedAtEvent__totalSenderFlowRate'
  | 'startedAtEvent__totalReceiverFlowRate'
  | 'startedAtEvent__deposit'
  | 'startedAtEvent__userData'
  | 'startedAtEvent__oldFlowRate'
  | 'startedAtEvent__type'
  | 'startedAtEvent__totalAmountStreamedUntilTimestamp'
  | 'stoppedAtTimestamp'
  | 'stoppedAtBlockNumber'
  | 'stoppedAtEvent'
  | 'stoppedAtEvent__id'
  | 'stoppedAtEvent__transactionHash'
  | 'stoppedAtEvent__gasPrice'
  | 'stoppedAtEvent__gasUsed'
  | 'stoppedAtEvent__timestamp'
  | 'stoppedAtEvent__name'
  | 'stoppedAtEvent__blockNumber'
  | 'stoppedAtEvent__logIndex'
  | 'stoppedAtEvent__order'
  | 'stoppedAtEvent__token'
  | 'stoppedAtEvent__sender'
  | 'stoppedAtEvent__receiver'
  | 'stoppedAtEvent__flowOperator'
  | 'stoppedAtEvent__flowRate'
  | 'stoppedAtEvent__totalSenderFlowRate'
  | 'stoppedAtEvent__totalReceiverFlowRate'
  | 'stoppedAtEvent__deposit'
  | 'stoppedAtEvent__userData'
  | 'stoppedAtEvent__oldFlowRate'
  | 'stoppedAtEvent__type'
  | 'stoppedAtEvent__totalAmountStreamedUntilTimestamp'
  | 'totalAmountStreamed';

export type StreamRevision = {
  /**
   * ID composed of: keccak256(abi.encode(sender,receiver))-tokenAddress
   *
   */
  id: Scalars['ID']['output'];
  revisionIndex: Scalars['Int']['output'];
  periodRevisionIndex: Scalars['Int']['output'];
  /**
   * The "most recently alive" stream between a sender and receiver.
   * Note: The `revisionIndex` property may not be the same as the `revisionIndex` of `mostRecentStream`. Which means `mostRecentStream` has been closed and no new stream has been opened.
   *
   */
  mostRecentStream: Stream;
};

export type StreamRevision_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  revisionIndex?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_not?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_gt?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_lt?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_gte?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_lte?: InputMaybe<Scalars['Int']['input']>;
  revisionIndex_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  revisionIndex_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  periodRevisionIndex?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_not?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_gt?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_lt?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_gte?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_lte?: InputMaybe<Scalars['Int']['input']>;
  periodRevisionIndex_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  periodRevisionIndex_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  mostRecentStream?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_gt?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_lt?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_gte?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_lte?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mostRecentStream_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  mostRecentStream_contains?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_contains?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_starts_with?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_ends_with?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  mostRecentStream_?: InputMaybe<Stream_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<StreamRevision_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<StreamRevision_Filter>>>;
};

export type StreamRevision_OrderBy =
  | 'id'
  | 'revisionIndex'
  | 'periodRevisionIndex'
  | 'mostRecentStream'
  | 'mostRecentStream__id'
  | 'mostRecentStream__createdAtTimestamp'
  | 'mostRecentStream__createdAtBlockNumber'
  | 'mostRecentStream__updatedAtTimestamp'
  | 'mostRecentStream__updatedAtBlockNumber'
  | 'mostRecentStream__currentFlowRate'
  | 'mostRecentStream__deposit'
  | 'mostRecentStream__streamedUntilUpdatedAt'
  | 'mostRecentStream__userData';

export type Stream_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentFlowRate?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  currentFlowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  currentFlowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  streamedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  streamedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  streamedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  sender?: InputMaybe<Scalars['String']['input']>;
  sender_not?: InputMaybe<Scalars['String']['input']>;
  sender_gt?: InputMaybe<Scalars['String']['input']>;
  sender_lt?: InputMaybe<Scalars['String']['input']>;
  sender_gte?: InputMaybe<Scalars['String']['input']>;
  sender_lte?: InputMaybe<Scalars['String']['input']>;
  sender_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  sender_contains?: InputMaybe<Scalars['String']['input']>;
  sender_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains?: InputMaybe<Scalars['String']['input']>;
  sender_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  sender_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  sender_?: InputMaybe<Account_Filter>;
  receiver?: InputMaybe<Scalars['String']['input']>;
  receiver_not?: InputMaybe<Scalars['String']['input']>;
  receiver_gt?: InputMaybe<Scalars['String']['input']>;
  receiver_lt?: InputMaybe<Scalars['String']['input']>;
  receiver_gte?: InputMaybe<Scalars['String']['input']>;
  receiver_lte?: InputMaybe<Scalars['String']['input']>;
  receiver_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  receiver_contains?: InputMaybe<Scalars['String']['input']>;
  receiver_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_contains?: InputMaybe<Scalars['String']['input']>;
  receiver_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_starts_with?: InputMaybe<Scalars['String']['input']>;
  receiver_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  receiver_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiver_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  receiver_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  receiver_?: InputMaybe<Account_Filter>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  flowUpdatedEvents_?: InputMaybe<FlowUpdatedEvent_Filter>;
  streamPeriods_?: InputMaybe<StreamPeriod_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Stream_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Stream_Filter>>>;
};

export type Stream_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'currentFlowRate'
  | 'deposit'
  | 'streamedUntilUpdatedAt'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'sender'
  | 'sender__id'
  | 'sender__createdAtTimestamp'
  | 'sender__createdAtBlockNumber'
  | 'sender__updatedAtTimestamp'
  | 'sender__updatedAtBlockNumber'
  | 'sender__isSuperApp'
  | 'receiver'
  | 'receiver__id'
  | 'receiver__createdAtTimestamp'
  | 'receiver__createdAtBlockNumber'
  | 'receiver__updatedAtTimestamp'
  | 'receiver__updatedAtBlockNumber'
  | 'receiver__isSuperApp'
  | 'userData'
  | 'flowUpdatedEvents'
  | 'streamPeriods';

export type Subscription = {
  flowUpdatedEvent?: Maybe<FlowUpdatedEvent>;
  flowUpdatedEvents: Array<FlowUpdatedEvent>;
  flowOperatorUpdatedEvent?: Maybe<FlowOperatorUpdatedEvent>;
  flowOperatorUpdatedEvents: Array<FlowOperatorUpdatedEvent>;
  indexCreatedEvent?: Maybe<IndexCreatedEvent>;
  indexCreatedEvents: Array<IndexCreatedEvent>;
  indexDistributionClaimedEvent?: Maybe<IndexDistributionClaimedEvent>;
  indexDistributionClaimedEvents: Array<IndexDistributionClaimedEvent>;
  indexUpdatedEvent?: Maybe<IndexUpdatedEvent>;
  indexUpdatedEvents: Array<IndexUpdatedEvent>;
  indexSubscribedEvent?: Maybe<IndexSubscribedEvent>;
  indexSubscribedEvents: Array<IndexSubscribedEvent>;
  indexUnitsUpdatedEvent?: Maybe<IndexUnitsUpdatedEvent>;
  indexUnitsUpdatedEvents: Array<IndexUnitsUpdatedEvent>;
  indexUnsubscribedEvent?: Maybe<IndexUnsubscribedEvent>;
  indexUnsubscribedEvents: Array<IndexUnsubscribedEvent>;
  subscriptionApprovedEvent?: Maybe<SubscriptionApprovedEvent>;
  subscriptionApprovedEvents: Array<SubscriptionApprovedEvent>;
  subscriptionDistributionClaimedEvent?: Maybe<SubscriptionDistributionClaimedEvent>;
  subscriptionDistributionClaimedEvents: Array<SubscriptionDistributionClaimedEvent>;
  subscriptionRevokedEvent?: Maybe<SubscriptionRevokedEvent>;
  subscriptionRevokedEvents: Array<SubscriptionRevokedEvent>;
  subscriptionUnitsUpdatedEvent?: Maybe<SubscriptionUnitsUpdatedEvent>;
  subscriptionUnitsUpdatedEvents: Array<SubscriptionUnitsUpdatedEvent>;
  poolCreatedEvent?: Maybe<PoolCreatedEvent>;
  poolCreatedEvents: Array<PoolCreatedEvent>;
  poolConnectionUpdatedEvent?: Maybe<PoolConnectionUpdatedEvent>;
  poolConnectionUpdatedEvents: Array<PoolConnectionUpdatedEvent>;
  bufferAdjustedEvent?: Maybe<BufferAdjustedEvent>;
  bufferAdjustedEvents: Array<BufferAdjustedEvent>;
  instantDistributionUpdatedEvent?: Maybe<InstantDistributionUpdatedEvent>;
  instantDistributionUpdatedEvents: Array<InstantDistributionUpdatedEvent>;
  flowDistributionUpdatedEvent?: Maybe<FlowDistributionUpdatedEvent>;
  flowDistributionUpdatedEvents: Array<FlowDistributionUpdatedEvent>;
  distributionClaimedEvent?: Maybe<DistributionClaimedEvent>;
  distributionClaimedEvents: Array<DistributionClaimedEvent>;
  memberUnitsUpdatedEvent?: Maybe<MemberUnitsUpdatedEvent>;
  memberUnitsUpdatedEvents: Array<MemberUnitsUpdatedEvent>;
  agreementClassRegisteredEvent?: Maybe<AgreementClassRegisteredEvent>;
  agreementClassRegisteredEvents: Array<AgreementClassRegisteredEvent>;
  agreementClassUpdatedEvent?: Maybe<AgreementClassUpdatedEvent>;
  agreementClassUpdatedEvents: Array<AgreementClassUpdatedEvent>;
  appRegisteredEvent?: Maybe<AppRegisteredEvent>;
  appRegisteredEvents: Array<AppRegisteredEvent>;
  governanceReplacedEvent?: Maybe<GovernanceReplacedEvent>;
  governanceReplacedEvents: Array<GovernanceReplacedEvent>;
  jailEvent?: Maybe<JailEvent>;
  jailEvents: Array<JailEvent>;
  superTokenFactoryUpdatedEvent?: Maybe<SuperTokenFactoryUpdatedEvent>;
  superTokenFactoryUpdatedEvents: Array<SuperTokenFactoryUpdatedEvent>;
  superTokenLogicUpdatedEvent?: Maybe<SuperTokenLogicUpdatedEvent>;
  superTokenLogicUpdatedEvents: Array<SuperTokenLogicUpdatedEvent>;
  roleAdminChangedEvent?: Maybe<RoleAdminChangedEvent>;
  roleAdminChangedEvents: Array<RoleAdminChangedEvent>;
  roleGrantedEvent?: Maybe<RoleGrantedEvent>;
  roleGrantedEvents: Array<RoleGrantedEvent>;
  roleRevokedEvent?: Maybe<RoleRevokedEvent>;
  roleRevokedEvents: Array<RoleRevokedEvent>;
  setEvent?: Maybe<SetEvent>;
  setEvents: Array<SetEvent>;
  cfav1LiquidationPeriodChangedEvent?: Maybe<CfAv1LiquidationPeriodChangedEvent>;
  cfav1LiquidationPeriodChangedEvents: Array<CfAv1LiquidationPeriodChangedEvent>;
  configChangedEvent?: Maybe<ConfigChangedEvent>;
  configChangedEvents: Array<ConfigChangedEvent>;
  rewardAddressChangedEvent?: Maybe<RewardAddressChangedEvent>;
  rewardAddressChangedEvents: Array<RewardAddressChangedEvent>;
  pppconfigurationChangedEvent?: Maybe<PppConfigurationChangedEvent>;
  pppconfigurationChangedEvents: Array<PppConfigurationChangedEvent>;
  superTokenMinimumDepositChangedEvent?: Maybe<SuperTokenMinimumDepositChangedEvent>;
  superTokenMinimumDepositChangedEvents: Array<SuperTokenMinimumDepositChangedEvent>;
  trustedForwarderChangedEvent?: Maybe<TrustedForwarderChangedEvent>;
  trustedForwarderChangedEvents: Array<TrustedForwarderChangedEvent>;
  agreementLiquidatedByEvent?: Maybe<AgreementLiquidatedByEvent>;
  agreementLiquidatedByEvents: Array<AgreementLiquidatedByEvent>;
  agreementLiquidatedV2Event?: Maybe<AgreementLiquidatedV2Event>;
  agreementLiquidatedV2Events: Array<AgreementLiquidatedV2Event>;
  burnedEvent?: Maybe<BurnedEvent>;
  burnedEvents: Array<BurnedEvent>;
  mintedEvent?: Maybe<MintedEvent>;
  mintedEvents: Array<MintedEvent>;
  sentEvent?: Maybe<SentEvent>;
  sentEvents: Array<SentEvent>;
  transferEvent?: Maybe<TransferEvent>;
  transferEvents: Array<TransferEvent>;
  tokenDowngradedEvent?: Maybe<TokenDowngradedEvent>;
  tokenDowngradedEvents: Array<TokenDowngradedEvent>;
  tokenUpgradedEvent?: Maybe<TokenUpgradedEvent>;
  tokenUpgradedEvents: Array<TokenUpgradedEvent>;
  approvalEvent?: Maybe<ApprovalEvent>;
  approvalEvents: Array<ApprovalEvent>;
  customSuperTokenCreatedEvent?: Maybe<CustomSuperTokenCreatedEvent>;
  customSuperTokenCreatedEvents: Array<CustomSuperTokenCreatedEvent>;
  superTokenCreatedEvent?: Maybe<SuperTokenCreatedEvent>;
  superTokenCreatedEvents: Array<SuperTokenCreatedEvent>;
  superTokenLogicCreatedEvent?: Maybe<SuperTokenLogicCreatedEvent>;
  superTokenLogicCreatedEvents: Array<SuperTokenLogicCreatedEvent>;
  newPICEvent?: Maybe<NewPicEvent>;
  newPICEvents: Array<NewPicEvent>;
  exitRateChangedEvent?: Maybe<ExitRateChangedEvent>;
  exitRateChangedEvents: Array<ExitRateChangedEvent>;
  bondIncreasedEvent?: Maybe<BondIncreasedEvent>;
  bondIncreasedEvents: Array<BondIncreasedEvent>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  poolMember?: Maybe<PoolMember>;
  poolMembers: Array<PoolMember>;
  poolDistributor?: Maybe<PoolDistributor>;
  poolDistributors: Array<PoolDistributor>;
  index?: Maybe<Index>;
  indexes: Array<Index>;
  indexSubscription?: Maybe<IndexSubscription>;
  indexSubscriptions: Array<IndexSubscription>;
  stream?: Maybe<Stream>;
  streams: Array<Stream>;
  flowOperator?: Maybe<FlowOperator>;
  flowOperators: Array<FlowOperator>;
  streamPeriod?: Maybe<StreamPeriod>;
  streamPeriods: Array<StreamPeriod>;
  tokenGovernanceConfig?: Maybe<TokenGovernanceConfig>;
  tokenGovernanceConfigs: Array<TokenGovernanceConfig>;
  streamRevision?: Maybe<StreamRevision>;
  streamRevisions: Array<StreamRevision>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  resolverEntry?: Maybe<ResolverEntry>;
  resolverEntries: Array<ResolverEntry>;
  accountTokenSnapshot?: Maybe<AccountTokenSnapshot>;
  accountTokenSnapshots: Array<AccountTokenSnapshot>;
  accountTokenSnapshotLog?: Maybe<AccountTokenSnapshotLog>;
  accountTokenSnapshotLogs: Array<AccountTokenSnapshotLog>;
  tokenStatistic?: Maybe<TokenStatistic>;
  tokenStatistics: Array<TokenStatistic>;
  tokenStatisticLog?: Maybe<TokenStatisticLog>;
  tokenStatisticLogs: Array<TokenStatisticLog>;
  sfmeta?: Maybe<SfMeta>;
  sfmetas: Array<SfMeta>;
  event?: Maybe<Event>;
  events: Array<Event>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionFlowUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowOperatorUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowOperatorUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperatorUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperatorUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexDistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexSubscribedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexSubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscribedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUnsubscribedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexUnsubscribedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexUnsubscribedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexUnsubscribedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionApprovedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionApprovedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionApprovedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionApprovedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionDistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionDistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionRevokedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionRevokedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionRevokedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionRevokedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSubscriptionUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolConnectionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolConnectionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolConnectionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolConnectionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBufferAdjustedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBufferAdjustedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BufferAdjustedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BufferAdjustedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInstantDistributionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionInstantDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InstantDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InstantDistributionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowDistributionUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowDistributionUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowDistributionUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowDistributionUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDistributionClaimedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDistributionClaimedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DistributionClaimedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DistributionClaimedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMemberUnitsUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMemberUnitsUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MemberUnitsUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MemberUnitsUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementClassRegisteredEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementClassRegisteredEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementClassRegisteredEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementClassRegisteredEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementClassUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementClassUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementClassUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementClassUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAppRegisteredEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAppRegisteredEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AppRegisteredEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AppRegisteredEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGovernanceReplacedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGovernanceReplacedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<GovernanceReplacedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<GovernanceReplacedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionJailEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionJailEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<JailEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<JailEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenFactoryUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenFactoryUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenFactoryUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenFactoryUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenLogicUpdatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenLogicUpdatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenLogicUpdatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenLogicUpdatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleAdminChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleAdminChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleAdminChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleAdminChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleGrantedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleGrantedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleGrantedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleGrantedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleRevokedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRoleRevokedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RoleRevokedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RoleRevokedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSetEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSetEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SetEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SetEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCfav1LiquidationPeriodChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCfav1LiquidationPeriodChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CfAv1LiquidationPeriodChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CfAv1LiquidationPeriodChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionConfigChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionConfigChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConfigChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ConfigChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRewardAddressChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRewardAddressChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RewardAddressChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RewardAddressChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPppconfigurationChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPppconfigurationChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PppConfigurationChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PppConfigurationChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenMinimumDepositChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenMinimumDepositChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenMinimumDepositChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenMinimumDepositChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTrustedForwarderChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTrustedForwarderChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrustedForwarderChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TrustedForwarderChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementLiquidatedByEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementLiquidatedByEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementLiquidatedByEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementLiquidatedByEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementLiquidatedV2EventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAgreementLiquidatedV2EventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AgreementLiquidatedV2Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AgreementLiquidatedV2Event_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBurnedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBurnedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BurnedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BurnedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMintedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMintedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MintedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MintedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSentEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSentEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SentEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SentEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransferEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransferEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransferEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransferEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDowngradedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDowngradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDowngradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenDowngradedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenUpgradedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenUpgradedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenUpgradedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenUpgradedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionApprovalEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionApprovalEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApprovalEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ApprovalEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCustomSuperTokenCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCustomSuperTokenCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomSuperTokenCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CustomSuperTokenCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenLogicCreatedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSuperTokenLogicCreatedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SuperTokenLogicCreatedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SuperTokenLogicCreatedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionNewPicEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionNewPicEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NewPicEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NewPicEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionExitRateChangedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionExitRateChangedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExitRateChangedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ExitRateChangedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBondIncreasedEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionBondIncreasedEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BondIncreasedEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BondIncreasedEvent_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Account_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Pool_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolMemberArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolMembersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolMember_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolMember_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolDistributorArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolDistributorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolDistributor_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PoolDistributor_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Index_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Index_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexSubscriptionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIndexSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IndexSubscription_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<IndexSubscription_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Stream_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Stream_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowOperatorArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionFlowOperatorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FlowOperator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FlowOperator_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamPeriodArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamPeriodsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StreamPeriod_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StreamPeriod_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenGovernanceConfigArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenGovernanceConfigsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenGovernanceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenGovernanceConfig_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamRevisionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStreamRevisionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StreamRevision_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StreamRevision_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionResolverEntryArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionResolverEntriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ResolverEntry_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ResolverEntry_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountTokenSnapshotArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountTokenSnapshotsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshot_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshot_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountTokenSnapshotLogArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountTokenSnapshotLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AccountTokenSnapshotLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AccountTokenSnapshotLog_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenStatisticArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenStatisticsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenStatistic_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenStatisticLogArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenStatisticLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenStatisticLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenStatisticLog_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSfmetaArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionSfmetasArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SfMeta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SfMeta_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Event_Filter>;
  block?: InputMaybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionApprovedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  subscription: IndexSubscription;
};

export type SubscriptionApprovedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscription?: InputMaybe<Scalars['String']['input']>;
  subscription_not?: InputMaybe<Scalars['String']['input']>;
  subscription_gt?: InputMaybe<Scalars['String']['input']>;
  subscription_lt?: InputMaybe<Scalars['String']['input']>;
  subscription_gte?: InputMaybe<Scalars['String']['input']>;
  subscription_lte?: InputMaybe<Scalars['String']['input']>;
  subscription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_?: InputMaybe<IndexSubscription_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SubscriptionApprovedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionApprovedEvent_Filter>>>;
};

export type SubscriptionApprovedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'subscriber'
  | 'publisher'
  | 'indexId'
  | 'userData'
  | 'subscription'
  | 'subscription__id'
  | 'subscription__createdAtTimestamp'
  | 'subscription__createdAtBlockNumber'
  | 'subscription__updatedAtTimestamp'
  | 'subscription__updatedAtBlockNumber'
  | 'subscription__approved'
  | 'subscription__units'
  | 'subscription__totalAmountReceivedUntilUpdatedAt'
  | 'subscription__indexValueUntilUpdatedAt';

export type SubscriptionDistributionClaimedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  amount: Scalars['BigInt']['output'];
  subscription: IndexSubscription;
};

export type SubscriptionDistributionClaimedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscription?: InputMaybe<Scalars['String']['input']>;
  subscription_not?: InputMaybe<Scalars['String']['input']>;
  subscription_gt?: InputMaybe<Scalars['String']['input']>;
  subscription_lt?: InputMaybe<Scalars['String']['input']>;
  subscription_gte?: InputMaybe<Scalars['String']['input']>;
  subscription_lte?: InputMaybe<Scalars['String']['input']>;
  subscription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_?: InputMaybe<IndexSubscription_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SubscriptionDistributionClaimedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionDistributionClaimedEvent_Filter>>>;
};

export type SubscriptionDistributionClaimedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'subscriber'
  | 'publisher'
  | 'indexId'
  | 'amount'
  | 'subscription'
  | 'subscription__id'
  | 'subscription__createdAtTimestamp'
  | 'subscription__createdAtBlockNumber'
  | 'subscription__updatedAtTimestamp'
  | 'subscription__updatedAtBlockNumber'
  | 'subscription__approved'
  | 'subscription__units'
  | 'subscription__totalAmountReceivedUntilUpdatedAt'
  | 'subscription__indexValueUntilUpdatedAt';

export type SubscriptionRevokedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  subscription: IndexSubscription;
};

export type SubscriptionRevokedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscription?: InputMaybe<Scalars['String']['input']>;
  subscription_not?: InputMaybe<Scalars['String']['input']>;
  subscription_gt?: InputMaybe<Scalars['String']['input']>;
  subscription_lt?: InputMaybe<Scalars['String']['input']>;
  subscription_gte?: InputMaybe<Scalars['String']['input']>;
  subscription_lte?: InputMaybe<Scalars['String']['input']>;
  subscription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_?: InputMaybe<IndexSubscription_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SubscriptionRevokedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionRevokedEvent_Filter>>>;
};

export type SubscriptionRevokedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'subscriber'
  | 'publisher'
  | 'indexId'
  | 'userData'
  | 'subscription'
  | 'subscription__id'
  | 'subscription__createdAtTimestamp'
  | 'subscription__createdAtBlockNumber'
  | 'subscription__updatedAtTimestamp'
  | 'subscription__updatedAtBlockNumber'
  | 'subscription__approved'
  | 'subscription__units'
  | 'subscription__totalAmountReceivedUntilUpdatedAt'
  | 'subscription__indexValueUntilUpdatedAt';

export type SubscriptionUnitsUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `publisher`
   * addresses[2] = `subscriber`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  /**
   * The account that is subscribed to `index`. A possible recipient of distributions from the `publisher`.
   * `subscriber` only receives tokens if they have been allocated units (can be thought of as shares).
   *
   */
  subscriber: Scalars['Bytes']['output'];
  /**
   * The creator of the `index`.
   *
   */
  publisher: Scalars['Bytes']['output'];
  /**
   * An arbitrary uint32 value used to allow a publisher to create multiple indexes for a specific `token`.
   *
   */
  indexId: Scalars['BigInt']['output'];
  units: Scalars['BigInt']['output'];
  userData: Scalars['Bytes']['output'];
  oldUnits: Scalars['BigInt']['output'];
  subscription: IndexSubscription;
};

export type SubscriptionUnitsUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lt?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_gte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_lte?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  subscriber_contains?: InputMaybe<Scalars['Bytes']['input']>;
  subscriber_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lt?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_gte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_lte?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  publisher_contains?: InputMaybe<Scalars['Bytes']['input']>;
  publisher_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  indexId?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_not?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  indexId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  indexId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units?: InputMaybe<Scalars['BigInt']['input']>;
  units_not?: InputMaybe<Scalars['BigInt']['input']>;
  units_gt?: InputMaybe<Scalars['BigInt']['input']>;
  units_lt?: InputMaybe<Scalars['BigInt']['input']>;
  units_gte?: InputMaybe<Scalars['BigInt']['input']>;
  units_lte?: InputMaybe<Scalars['BigInt']['input']>;
  units_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  units_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  userData?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  userData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  userData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  userData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  userData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oldUnits?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_not?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lt?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_gte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_lte?: InputMaybe<Scalars['BigInt']['input']>;
  oldUnits_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  oldUnits_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  subscription?: InputMaybe<Scalars['String']['input']>;
  subscription_not?: InputMaybe<Scalars['String']['input']>;
  subscription_gt?: InputMaybe<Scalars['String']['input']>;
  subscription_lt?: InputMaybe<Scalars['String']['input']>;
  subscription_gte?: InputMaybe<Scalars['String']['input']>;
  subscription_lte?: InputMaybe<Scalars['String']['input']>;
  subscription_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains?: InputMaybe<Scalars['String']['input']>;
  subscription_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subscription_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  subscription_?: InputMaybe<IndexSubscription_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionUnitsUpdatedEvent_Filter>>>;
};

export type SubscriptionUnitsUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'subscriber'
  | 'publisher'
  | 'indexId'
  | 'units'
  | 'userData'
  | 'oldUnits'
  | 'subscription'
  | 'subscription__id'
  | 'subscription__createdAtTimestamp'
  | 'subscription__createdAtBlockNumber'
  | 'subscription__updatedAtTimestamp'
  | 'subscription__updatedAtBlockNumber'
  | 'subscription__approved'
  | 'subscription__units'
  | 'subscription__totalAmountReceivedUntilUpdatedAt'
  | 'subscription__indexValueUntilUpdatedAt';

export type SuperTokenCreatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
};

export type SuperTokenCreatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SuperTokenCreatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SuperTokenCreatedEvent_Filter>>>;
};

export type SuperTokenCreatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token';

export type SuperTokenFactoryUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `newFactory`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  newFactory: Scalars['Bytes']['output'];
};

export type SuperTokenFactoryUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  newFactory?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_not?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_gt?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_lt?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_gte?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_lte?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newFactory_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  newFactory_contains?: InputMaybe<Scalars['Bytes']['input']>;
  newFactory_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SuperTokenFactoryUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SuperTokenFactoryUpdatedEvent_Filter>>>;
};

export type SuperTokenFactoryUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'newFactory';

export type SuperTokenLogicCreatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `tokenLogic`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  tokenLogic: Scalars['Bytes']['output'];
};

export type SuperTokenLogicCreatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokenLogic?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_not?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_gt?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_lt?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_gte?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_lte?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokenLogic_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokenLogic_contains?: InputMaybe<Scalars['Bytes']['input']>;
  tokenLogic_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SuperTokenLogicCreatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SuperTokenLogicCreatedEvent_Filter>>>;
};

export type SuperTokenLogicCreatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'tokenLogic';

export type SuperTokenLogicUpdatedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token`
   * addresses[1] = `code`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  code: Scalars['Bytes']['output'];
};

export type SuperTokenLogicUpdatedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code?: InputMaybe<Scalars['Bytes']['input']>;
  code_not?: InputMaybe<Scalars['Bytes']['input']>;
  code_gt?: InputMaybe<Scalars['Bytes']['input']>;
  code_lt?: InputMaybe<Scalars['Bytes']['input']>;
  code_gte?: InputMaybe<Scalars['Bytes']['input']>;
  code_lte?: InputMaybe<Scalars['Bytes']['input']>;
  code_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  code_contains?: InputMaybe<Scalars['Bytes']['input']>;
  code_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SuperTokenLogicUpdatedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SuperTokenLogicUpdatedEvent_Filter>>>;
};

export type SuperTokenLogicUpdatedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'code';

export type SuperTokenMinimumDepositChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  minimumDeposit: Scalars['BigInt']['output'];
};

export type SuperTokenMinimumDepositChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  minimumDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minimumDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SuperTokenMinimumDepositChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<SuperTokenMinimumDepositChangedEvent_Filter>>>;
};

export type SuperTokenMinimumDepositChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'host'
  | 'superToken'
  | 'isKeySet'
  | 'minimumDeposit';

/**
 * Token: A higher order entity created for super tokens (and underlying tokens) that are "valid" (tokens that have Superfluid's host contract address set as the host).
 *
 */
export type Token = {
  /**
   * ID: the token address
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  decimals: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  isSuperToken: Scalars['Boolean']['output'];
  /**
   * A boolean indicating whether the token is a NativeAssetSuperToken.
   *
   */
  isNativeAssetSuperToken: Scalars['Boolean']['output'];
  /**
   * A boolean indicating whether the token is a part of our resolver list.
   *
   */
  isListed: Scalars['Boolean']['output'];
  /**
   * The address of the underlying ERC20 token (zero address for non-ERC20WrapperSuperToken's)
   *
   */
  underlyingAddress: Scalars['Bytes']['output'];
  /**
   * The underlying ERC20 token for a ERC20WrapperSuperToken otherwise null.
   *
   */
  underlyingToken?: Maybe<Token>;
  /**
   * If `governanceConfig.id` is the zero address, the token uses the default governance config.
   *
   */
  governanceConfig?: Maybe<TokenGovernanceConfig>;
};

export type TokenDowngradedEvent = Event & {
  id: Scalars['ID']['output'];
  account: Account;
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `account`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
};

export type TokenDowngradedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenDowngradedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenDowngradedEvent_Filter>>>;
};

export type TokenDowngradedEvent_OrderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'amount';

/**
 * TokenGovernanceConfig: A higher order entity that represents the governance configs for a token.
 * If `id` is `address(0)`, it will be used as the default config.
 *
 */
export type TokenGovernanceConfig = {
  /**
   * id is the address of the SuperToken
   * NOTE: the zero address is reserved for the default config for all tokens with unset configs.
   *
   */
  id: Scalars['ID']['output'];
  createdAtTimestamp: Scalars['BigInt']['output'];
  createdAtBlockNumber: Scalars['BigInt']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * If true, `id` is `address(0)` and this is the default config for all tokens with unset configs.
   *
   */
  isDefault: Scalars['Boolean']['output'];
  /**
   * The (default or token-specific) address that receives liquidation rewards for a token prior to 3Ps and the TOGA address after 3Ps.
   *
   */
  rewardAddress?: Maybe<Scalars['Bytes']['output']>;
  /**
   * The (default or token-specific) liquidation period (buffer amount required for a token).
   * This field can be used to calculate the liquidation buffer (or deposit) amount for a token: `liquidationBufferAmount = liquidationPeriod * flowRate`.
   * Note that if `minimumDeposit` is set, the liquidation buffer amount will be the greater of the two values.
   *
   */
  liquidationPeriod?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The (default or token-specific) patrician period, the patrician period is the window in which a patrician receives all rewards for a liquidation, no matter the liquidating account.
   *
   */
  patricianPeriod?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The (default or token-specific) minimum deposit amount.
   *
   */
  minimumDeposit?: Maybe<Scalars['BigInt']['output']>;
  /**
   * A reverse lookup to the token it is associated with and null if it is the default config.
   *
   */
  token?: Maybe<Token>;
};

export type TokenGovernanceConfig_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault_not?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isDefault_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  rewardAddress?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  rewardAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  rewardAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  patricianPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  patricianPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  patricianPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minimumDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minimumDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minimumDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenGovernanceConfig_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenGovernanceConfig_Filter>>>;
};

export type TokenGovernanceConfig_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'isDefault'
  | 'rewardAddress'
  | 'liquidationPeriod'
  | 'patricianPeriod'
  | 'minimumDeposit'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress';

/**
 * TokenStatistic: An aggregate entity which contains aggregate data for `token`.
 *
 */
export type TokenStatistic = {
  /**
   * id: `token` (superToken) address
   *
   */
  id: Scalars['ID']['output'];
  updatedAtTimestamp: Scalars['BigInt']['output'];
  updatedAtBlockNumber: Scalars['BigInt']['output'];
  /**
   * The total number of currently active `token` streams.
   *
   */
  totalNumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The total number of currently active `token` streams for the CFA.
   *
   */
  totalCFANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The total number of currently active `token` streams for the GDA.
   *
   */
  totalGDANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token`.
   *
   */
  totalNumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token` for the CFA.
   *
   */
  totalCFANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token` for the GDA.
   *
   */
  totalGDANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The total number of Indexes created with `token`.
   *
   */
  totalNumberOfIndexes: Scalars['Int']['output'];
  /**
   * The total number of "active" (has greater than 0 units and has distributed it at least once) Indexes created with `token`.
   *
   */
  totalNumberOfActiveIndexes: Scalars['Int']['output'];
  /**
   * The number of subscriptions which have units allocated to them created with Indexes that distribute `token`.
   *
   */
  totalSubscriptionsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all approved subscriptions whether or not they have units.
   *
   */
  totalApprovedSubscriptions: Scalars['Int']['output'];
  /**
   * The total number of Pools created with `token`.
   *
   */
  totalNumberOfPools: Scalars['Int']['output'];
  /**
   * The total number of "active" (has greater than 0 units and has distributed it at least once) Pools created with `token`.
   *
   */
  totalNumberOfActivePools: Scalars['Int']['output'];
  /**
   * The number of memberships which have units allocated to them created with Pools that distribute `token`.
   *
   */
  totalMembershipsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all approved memberships whether or not they have units.
   *
   */
  totalConnectedMemberships: Scalars['Int']['output'];
  /**
   * The total deposit held by all flow agreements for this particular `token`.
   *
   */
  totalDeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit held by the CFA for this particular `token`.
   *
   */
  totalCFADeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit held by the GDA agreement for this particular `token`.
   *
   */
  totalGDADeposit: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for all flow agreements.
   *
   */
  totalOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for the CFA.
   *
   */
  totalCFAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for the GDA.
   *
   */
  totalGDAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The all-time total amount streamed (outflows) until the `updatedAtTimestamp`/`updatedAtBlock` for all flow agreements.
   *
   */
  totalAmountStreamedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The all-time total amount streamed (outflows) until the `updatedAtTimestamp`/`updatedAtBlock` for the CFA.
   *
   */
  totalCFAAmountStreamedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The all-time total amount transferred until the `updatedAtTimestamp`/`updatedAtBlock`.
   *
   */
  totalAmountTransferredUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The all-time total amount distributed until the `updatedAtTimestamp`/`updatedAtBlock`.
   *
   */
  totalAmountDistributedUntilUpdatedAt: Scalars['BigInt']['output'];
  /**
   * The total supply of the token - this is impacted by users upgrading/downgrading their tokens.
   *
   */
  totalSupply: Scalars['BigInt']['output'];
  /**
   * The total number of accounts that have interacted with the token (but might not hold a balance anymore).
   *
   */
  totalNumberOfAccounts: Scalars['Int']['output'];
  /**
   * The total number of accounts holding a non-zero balance of the token.
   *
   */
  totalNumberOfHolders: Scalars['Int']['output'];
  token: Token;
  tokenStatisticLogs: Array<TokenStatisticLog>;
};


/**
 * TokenStatistic: An aggregate entity which contains aggregate data for `token`.
 *
 */
export type TokenStatisticTokenStatisticLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenStatisticLog_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenStatisticLog_Filter>;
};

/**
 * TokenStatisticLog: Historical entries of `TokenStatistic` updates.
 *
 */
export type TokenStatisticLog = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['BigInt']['output'];
  blockNumber: Scalars['BigInt']['output'];
  transactionHash: Scalars['Bytes']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  triggeredByEventName: Scalars['String']['output'];
  /**
   * The total number of currently active `token` streams for all flow agreements.
   *
   */
  totalNumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The total number of currently active `token` streams for the CFA.
   *
   */
  totalCFANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The total number of currently active `token` streams for the GDA.
   *
   */
  totalGDANumberOfActiveStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token` for all flow agreements.
   *
   */
  totalNumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token` for the CFA.
   *
   */
  totalCFANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The count of closed streams for `token` for the GDA.
   *
   */
  totalGDANumberOfClosedStreams: Scalars['Int']['output'];
  /**
   * The total number of Indexes created with `token`.
   *
   */
  totalNumberOfIndexes: Scalars['Int']['output'];
  /**
   * The total number of "active" (has greater than 0 units and has distributed it at least once) Indexes created with `token`.
   *
   */
  totalNumberOfActiveIndexes: Scalars['Int']['output'];
  /**
   * The number of subscriptions which have units allocated to them created with Indexes that distribute `token`.
   *
   */
  totalSubscriptionsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all approved subscriptions whether or not they have units.
   *
   */
  totalApprovedSubscriptions: Scalars['Int']['output'];
  /**
   * The total number of Pools created with `token`.
   *
   */
  totalNumberOfPools: Scalars['Int']['output'];
  /**
   * The total number of "active" (has greater than 0 units and has distributed it at least once) Pools created with `token`.
   *
   */
  totalNumberOfActivePools: Scalars['Int']['output'];
  /**
   * The number of memberships which have units allocated to them created with Pools that distribute `token`.
   *
   */
  totalMembershipsWithUnits: Scalars['Int']['output'];
  /**
   * Counts all connected memberships whether or not they have units.
   *
   */
  totalConnectedMemberships: Scalars['Int']['output'];
  /**
   * The total deposit held by the CFA agreement for this particular `token` for all flow agreements.
   *
   */
  totalDeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit held by the CFA agreement for this particular `token` for the CFA.
   *
   */
  totalCFADeposit: Scalars['BigInt']['output'];
  /**
   * The total deposit held by the CFA agreement for this particular `token` for the GDA.
   *
   */
  totalGDADeposit: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for all flow agreements.
   *
   */
  totalOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for the CFA.
   *
   */
  totalCFAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The total outflow rate of the `token` (how much value is being moved) for the GDA.
   *
   */
  totalGDAOutflowRate: Scalars['BigInt']['output'];
  /**
   * The all-time total amount of `token` streamed (outflows) until the `timestamp`/`block` for all flow agreements.
   *
   */
  totalAmountStreamed: Scalars['BigInt']['output'];
  /**
   * The all-time total amount of `token` streamed (outflows) until the `timestamp`/`block` for the CFA.
   *
   */
  totalCFAAmountStreamed: Scalars['BigInt']['output'];
  /**
   * The all-time total amount of `token` transferred until the `timestamp`/`block`.
   *
   */
  totalAmountTransferred: Scalars['BigInt']['output'];
  /**
   * The all-time total amount of `token` distributed until the `timestamp`/`block`.
   *
   */
  totalAmountDistributed: Scalars['BigInt']['output'];
  /**
   * The total supply of the token - this is impacted by users upgrading/downgrading their tokens.
   *
   */
  totalSupply: Scalars['BigInt']['output'];
  /**
   * The total number of accounts that have interacted with the token (but might not hold a balance anymore).
   *
   */
  totalNumberOfAccounts: Scalars['Int']['output'];
  /**
   * The total number of accounts holding a non-zero balance of the token.
   *
   */
  totalNumberOfHolders: Scalars['Int']['output'];
  token: Token;
  tokenStatistic: TokenStatistic;
};

export type TokenStatisticLog_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  triggeredByEventName?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_gt?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_lt?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_gte?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_lte?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  triggeredByEventName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  triggeredByEventName_contains?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_contains?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_starts_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_ends_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  triggeredByEventName_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  totalNumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfIndexes?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfIndexes_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveIndexes?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveIndexes_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_not?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfPools?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfPools_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActivePools?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActivePools_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_not?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamed?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamed?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferred?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferred_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferred_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributed?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSupply?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNumberOfAccounts?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfAccounts_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfHolders?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfHolders_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  tokenStatistic?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_gt?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_lt?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_gte?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_lte?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tokenStatistic_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  tokenStatistic_contains?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_contains?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_starts_with?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_ends_with?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenStatistic_?: InputMaybe<TokenStatistic_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenStatisticLog_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenStatisticLog_Filter>>>;
};

export type TokenStatisticLog_OrderBy =
  | 'id'
  | 'timestamp'
  | 'blockNumber'
  | 'transactionHash'
  | 'logIndex'
  | 'order'
  | 'triggeredByEventName'
  | 'totalNumberOfActiveStreams'
  | 'totalCFANumberOfActiveStreams'
  | 'totalGDANumberOfActiveStreams'
  | 'totalNumberOfClosedStreams'
  | 'totalCFANumberOfClosedStreams'
  | 'totalGDANumberOfClosedStreams'
  | 'totalNumberOfIndexes'
  | 'totalNumberOfActiveIndexes'
  | 'totalSubscriptionsWithUnits'
  | 'totalApprovedSubscriptions'
  | 'totalNumberOfPools'
  | 'totalNumberOfActivePools'
  | 'totalMembershipsWithUnits'
  | 'totalConnectedMemberships'
  | 'totalDeposit'
  | 'totalCFADeposit'
  | 'totalGDADeposit'
  | 'totalOutflowRate'
  | 'totalCFAOutflowRate'
  | 'totalGDAOutflowRate'
  | 'totalAmountStreamed'
  | 'totalCFAAmountStreamed'
  | 'totalAmountTransferred'
  | 'totalAmountDistributed'
  | 'totalSupply'
  | 'totalNumberOfAccounts'
  | 'totalNumberOfHolders'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'tokenStatistic'
  | 'tokenStatistic__id'
  | 'tokenStatistic__updatedAtTimestamp'
  | 'tokenStatistic__updatedAtBlockNumber'
  | 'tokenStatistic__totalNumberOfActiveStreams'
  | 'tokenStatistic__totalCFANumberOfActiveStreams'
  | 'tokenStatistic__totalGDANumberOfActiveStreams'
  | 'tokenStatistic__totalNumberOfClosedStreams'
  | 'tokenStatistic__totalCFANumberOfClosedStreams'
  | 'tokenStatistic__totalGDANumberOfClosedStreams'
  | 'tokenStatistic__totalNumberOfIndexes'
  | 'tokenStatistic__totalNumberOfActiveIndexes'
  | 'tokenStatistic__totalSubscriptionsWithUnits'
  | 'tokenStatistic__totalApprovedSubscriptions'
  | 'tokenStatistic__totalNumberOfPools'
  | 'tokenStatistic__totalNumberOfActivePools'
  | 'tokenStatistic__totalMembershipsWithUnits'
  | 'tokenStatistic__totalConnectedMemberships'
  | 'tokenStatistic__totalDeposit'
  | 'tokenStatistic__totalCFADeposit'
  | 'tokenStatistic__totalGDADeposit'
  | 'tokenStatistic__totalOutflowRate'
  | 'tokenStatistic__totalCFAOutflowRate'
  | 'tokenStatistic__totalGDAOutflowRate'
  | 'tokenStatistic__totalAmountStreamedUntilUpdatedAt'
  | 'tokenStatistic__totalCFAAmountStreamedUntilUpdatedAt'
  | 'tokenStatistic__totalAmountTransferredUntilUpdatedAt'
  | 'tokenStatistic__totalAmountDistributedUntilUpdatedAt'
  | 'tokenStatistic__totalSupply'
  | 'tokenStatistic__totalNumberOfAccounts'
  | 'tokenStatistic__totalNumberOfHolders';

export type TokenStatistic_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  updatedAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfActiveStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfActiveStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalCFANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalCFANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_not?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lt?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_gte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_lte?: InputMaybe<Scalars['Int']['input']>;
  totalGDANumberOfClosedStreams_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalGDANumberOfClosedStreams_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfIndexes?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfIndexes_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfIndexes_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveIndexes?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActiveIndexes_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActiveIndexes_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalSubscriptionsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalSubscriptionsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_not?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lt?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_gte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_lte?: InputMaybe<Scalars['Int']['input']>;
  totalApprovedSubscriptions_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalApprovedSubscriptions_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfPools?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfPools_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfPools_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActivePools?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfActivePools_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfActivePools_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_not?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lt?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_gte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_lte?: InputMaybe<Scalars['Int']['input']>;
  totalMembershipsWithUnits_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalMembershipsWithUnits_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_not?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lt?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_gte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_lte?: InputMaybe<Scalars['Int']['input']>;
  totalConnectedMemberships_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalConnectedMemberships_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalDeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalDeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalDeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDADeposit_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDADeposit_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalGDAOutflowRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalGDAOutflowRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountStreamedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountStreamedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalCFAAmountStreamedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalCFAAmountStreamedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferredUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountTransferredUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountTransferredUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalAmountDistributedUntilUpdatedAt_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalAmountDistributedUntilUpdatedAt_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSupply?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalNumberOfAccounts?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfAccounts_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfAccounts_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfHolders?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_not?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_gt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_lt?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_gte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_lte?: InputMaybe<Scalars['Int']['input']>;
  totalNumberOfHolders_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalNumberOfHolders_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  tokenStatisticLogs_?: InputMaybe<TokenStatisticLog_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenStatistic_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenStatistic_Filter>>>;
};

export type TokenStatistic_OrderBy =
  | 'id'
  | 'updatedAtTimestamp'
  | 'updatedAtBlockNumber'
  | 'totalNumberOfActiveStreams'
  | 'totalCFANumberOfActiveStreams'
  | 'totalGDANumberOfActiveStreams'
  | 'totalNumberOfClosedStreams'
  | 'totalCFANumberOfClosedStreams'
  | 'totalGDANumberOfClosedStreams'
  | 'totalNumberOfIndexes'
  | 'totalNumberOfActiveIndexes'
  | 'totalSubscriptionsWithUnits'
  | 'totalApprovedSubscriptions'
  | 'totalNumberOfPools'
  | 'totalNumberOfActivePools'
  | 'totalMembershipsWithUnits'
  | 'totalConnectedMemberships'
  | 'totalDeposit'
  | 'totalCFADeposit'
  | 'totalGDADeposit'
  | 'totalOutflowRate'
  | 'totalCFAOutflowRate'
  | 'totalGDAOutflowRate'
  | 'totalAmountStreamedUntilUpdatedAt'
  | 'totalCFAAmountStreamedUntilUpdatedAt'
  | 'totalAmountTransferredUntilUpdatedAt'
  | 'totalAmountDistributedUntilUpdatedAt'
  | 'totalSupply'
  | 'totalNumberOfAccounts'
  | 'totalNumberOfHolders'
  | 'token'
  | 'token__id'
  | 'token__createdAtTimestamp'
  | 'token__createdAtBlockNumber'
  | 'token__decimals'
  | 'token__name'
  | 'token__symbol'
  | 'token__isSuperToken'
  | 'token__isNativeAssetSuperToken'
  | 'token__isListed'
  | 'token__underlyingAddress'
  | 'tokenStatisticLogs';

export type TokenUpgradedEvent = Event & {
  id: Scalars['ID']['output'];
  account: Account;
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token` (superToken)
   * addresses[1] = `account`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
};

export type TokenUpgradedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  account?: InputMaybe<Scalars['String']['input']>;
  account_not?: InputMaybe<Scalars['String']['input']>;
  account_gt?: InputMaybe<Scalars['String']['input']>;
  account_lt?: InputMaybe<Scalars['String']['input']>;
  account_gte?: InputMaybe<Scalars['String']['input']>;
  account_lte?: InputMaybe<Scalars['String']['input']>;
  account_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  account_contains?: InputMaybe<Scalars['String']['input']>;
  account_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_contains?: InputMaybe<Scalars['String']['input']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  account_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  account_?: InputMaybe<Account_Filter>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenUpgradedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenUpgradedEvent_Filter>>>;
};

export type TokenUpgradedEvent_OrderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__createdAtTimestamp'
  | 'account__createdAtBlockNumber'
  | 'account__updatedAtTimestamp'
  | 'account__updatedAtBlockNumber'
  | 'account__isSuperApp'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'token'
  | 'amount';

export type Token_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAtTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  createdAtBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAtBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  decimals_not?: InputMaybe<Scalars['Int']['input']>;
  decimals_gt?: InputMaybe<Scalars['Int']['input']>;
  decimals_lt?: InputMaybe<Scalars['Int']['input']>;
  decimals_gte?: InputMaybe<Scalars['Int']['input']>;
  decimals_lte?: InputMaybe<Scalars['Int']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  isSuperToken?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperToken_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuperToken_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isSuperToken_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isNativeAssetSuperToken?: InputMaybe<Scalars['Boolean']['input']>;
  isNativeAssetSuperToken_not?: InputMaybe<Scalars['Boolean']['input']>;
  isNativeAssetSuperToken_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isNativeAssetSuperToken_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isListed?: InputMaybe<Scalars['Boolean']['input']>;
  isListed_not?: InputMaybe<Scalars['Boolean']['input']>;
  isListed_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isListed_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  underlyingAddress?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  underlyingAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  underlyingAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  underlyingToken?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_gt?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_lt?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_gte?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_lte?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_in?: InputMaybe<Array<Scalars['String']['input']>>;
  underlyingToken_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  underlyingToken_contains?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_contains?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_starts_with?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_ends_with?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  underlyingToken_?: InputMaybe<Token_Filter>;
  governanceConfig?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_gt?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_lt?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_gte?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_lte?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_in?: InputMaybe<Array<Scalars['String']['input']>>;
  governanceConfig_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  governanceConfig_contains?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_contains?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_starts_with?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_ends_with?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceConfig_?: InputMaybe<TokenGovernanceConfig_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
};

export type Token_OrderBy =
  | 'id'
  | 'createdAtTimestamp'
  | 'createdAtBlockNumber'
  | 'decimals'
  | 'name'
  | 'symbol'
  | 'isSuperToken'
  | 'isNativeAssetSuperToken'
  | 'isListed'
  | 'underlyingAddress'
  | 'underlyingToken'
  | 'underlyingToken__id'
  | 'underlyingToken__createdAtTimestamp'
  | 'underlyingToken__createdAtBlockNumber'
  | 'underlyingToken__decimals'
  | 'underlyingToken__name'
  | 'underlyingToken__symbol'
  | 'underlyingToken__isSuperToken'
  | 'underlyingToken__isNativeAssetSuperToken'
  | 'underlyingToken__isListed'
  | 'underlyingToken__underlyingAddress'
  | 'governanceConfig'
  | 'governanceConfig__id'
  | 'governanceConfig__createdAtTimestamp'
  | 'governanceConfig__createdAtBlockNumber'
  | 'governanceConfig__updatedAtTimestamp'
  | 'governanceConfig__updatedAtBlockNumber'
  | 'governanceConfig__isDefault'
  | 'governanceConfig__rewardAddress'
  | 'governanceConfig__liquidationPeriod'
  | 'governanceConfig__patricianPeriod'
  | 'governanceConfig__minimumDeposit';

export type TransferEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `token`
   * addresses[1] = `from`
   * addresses[2] = `to`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  from: Account;
  to: Account;
  value: Scalars['BigInt']['output'];
  token: Scalars['Bytes']['output'];
};

export type TransferEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  from_not?: InputMaybe<Scalars['String']['input']>;
  from_gt?: InputMaybe<Scalars['String']['input']>;
  from_lt?: InputMaybe<Scalars['String']['input']>;
  from_gte?: InputMaybe<Scalars['String']['input']>;
  from_lte?: InputMaybe<Scalars['String']['input']>;
  from_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_contains?: InputMaybe<Scalars['String']['input']>;
  from_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_contains?: InputMaybe<Scalars['String']['input']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_?: InputMaybe<Account_Filter>;
  to?: InputMaybe<Scalars['String']['input']>;
  to_not?: InputMaybe<Scalars['String']['input']>;
  to_gt?: InputMaybe<Scalars['String']['input']>;
  to_lt?: InputMaybe<Scalars['String']['input']>;
  to_gte?: InputMaybe<Scalars['String']['input']>;
  to_lte?: InputMaybe<Scalars['String']['input']>;
  to_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_contains?: InputMaybe<Scalars['String']['input']>;
  to_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_contains?: InputMaybe<Scalars['String']['input']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_?: InputMaybe<Account_Filter>;
  value?: InputMaybe<Scalars['BigInt']['input']>;
  value_not?: InputMaybe<Scalars['BigInt']['input']>;
  value_gt?: InputMaybe<Scalars['BigInt']['input']>;
  value_lt?: InputMaybe<Scalars['BigInt']['input']>;
  value_gte?: InputMaybe<Scalars['BigInt']['input']>;
  value_lte?: InputMaybe<Scalars['BigInt']['input']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TransferEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TransferEvent_Filter>>>;
};

export type TransferEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'from'
  | 'from__id'
  | 'from__createdAtTimestamp'
  | 'from__createdAtBlockNumber'
  | 'from__updatedAtTimestamp'
  | 'from__updatedAtBlockNumber'
  | 'from__isSuperApp'
  | 'to'
  | 'to__id'
  | 'to__createdAtTimestamp'
  | 'to__createdAtBlockNumber'
  | 'to__updatedAtTimestamp'
  | 'to__updatedAtBlockNumber'
  | 'to__isSuperApp'
  | 'value'
  | 'token';

export type TrustedForwarderChangedEvent = Event & {
  id: Scalars['ID']['output'];
  transactionHash: Scalars['Bytes']['output'];
  gasPrice: Scalars['BigInt']['output'];
  gasUsed: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  /**
   * The address of the governance contract the event was emitted from.
   *
   */
  governanceAddress: Scalars['Bytes']['output'];
  /**
   * Contains the addresses that were impacted by this event:
   * addresses[0] = `governanceAddress`
   * addresses[1] = `host`
   * addresses[2] = `superToken`
   * addresses[3] = `forwarder`
   *
   */
  addresses: Array<Scalars['Bytes']['output']>;
  blockNumber: Scalars['BigInt']['output'];
  logIndex: Scalars['BigInt']['output'];
  order: Scalars['BigInt']['output'];
  host: Scalars['Bytes']['output'];
  superToken: Scalars['Bytes']['output'];
  isKeySet: Scalars['Boolean']['output'];
  forwarder: Scalars['Bytes']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type TrustedForwarderChangedEvent_Filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  gasPrice?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_not?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lt?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_gte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_lte?: InputMaybe<Scalars['BigInt']['input']>;
  gasUsed_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  gasUsed_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  governanceAddress?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  addresses?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  addresses_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']['input']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_not?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lt?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_gte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_lte?: InputMaybe<Scalars['BigInt']['input']>;
  logIndex_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  logIndex_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order?: InputMaybe<Scalars['BigInt']['input']>;
  order_not?: InputMaybe<Scalars['BigInt']['input']>;
  order_gt?: InputMaybe<Scalars['BigInt']['input']>;
  order_lt?: InputMaybe<Scalars['BigInt']['input']>;
  order_gte?: InputMaybe<Scalars['BigInt']['input']>;
  order_lte?: InputMaybe<Scalars['BigInt']['input']>;
  order_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  host?: InputMaybe<Scalars['Bytes']['input']>;
  host_not?: InputMaybe<Scalars['Bytes']['input']>;
  host_gt?: InputMaybe<Scalars['Bytes']['input']>;
  host_lt?: InputMaybe<Scalars['Bytes']['input']>;
  host_gte?: InputMaybe<Scalars['Bytes']['input']>;
  host_lte?: InputMaybe<Scalars['Bytes']['input']>;
  host_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  host_contains?: InputMaybe<Scalars['Bytes']['input']>;
  host_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  superToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  superToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  isKeySet?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_not?: InputMaybe<Scalars['Boolean']['input']>;
  isKeySet_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isKeySet_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  forwarder?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_not?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_gt?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_lt?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_gte?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_lte?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  forwarder_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  forwarder_contains?: InputMaybe<Scalars['Bytes']['input']>;
  forwarder_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  enabled_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TrustedForwarderChangedEvent_Filter>>>;
  or?: InputMaybe<Array<InputMaybe<TrustedForwarderChangedEvent_Filter>>>;
};

export type TrustedForwarderChangedEvent_OrderBy =
  | 'id'
  | 'transactionHash'
  | 'gasPrice'
  | 'gasUsed'
  | 'timestamp'
  | 'name'
  | 'governanceAddress'
  | 'addresses'
  | 'blockNumber'
  | 'logIndex'
  | 'order'
  | 'host'
  | 'superToken'
  | 'isKeySet'
  | 'forwarder'
  | 'enabled';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  Event: ( AgreementClassRegisteredEvent ) | ( AgreementClassUpdatedEvent ) | ( AgreementLiquidatedByEvent ) | ( AgreementLiquidatedV2Event ) | ( AppRegisteredEvent ) | ( ApprovalEvent ) | ( BondIncreasedEvent ) | ( BufferAdjustedEvent ) | ( BurnedEvent ) | ( CfAv1LiquidationPeriodChangedEvent ) | ( ConfigChangedEvent ) | ( CustomSuperTokenCreatedEvent ) | ( DistributionClaimedEvent ) | ( ExitRateChangedEvent ) | ( FlowDistributionUpdatedEvent ) | ( FlowOperatorUpdatedEvent ) | ( FlowUpdatedEvent ) | ( GovernanceReplacedEvent ) | ( IndexCreatedEvent ) | ( IndexDistributionClaimedEvent ) | ( IndexSubscribedEvent ) | ( IndexUnitsUpdatedEvent ) | ( IndexUnsubscribedEvent ) | ( IndexUpdatedEvent ) | ( InstantDistributionUpdatedEvent ) | ( JailEvent ) | ( MemberUnitsUpdatedEvent ) | ( MintedEvent ) | ( NewPicEvent ) | ( PppConfigurationChangedEvent ) | ( PoolConnectionUpdatedEvent ) | ( PoolCreatedEvent ) | ( RewardAddressChangedEvent ) | ( RoleAdminChangedEvent ) | ( RoleGrantedEvent ) | ( RoleRevokedEvent ) | ( SentEvent ) | ( SetEvent ) | ( SubscriptionApprovedEvent ) | ( SubscriptionDistributionClaimedEvent ) | ( SubscriptionRevokedEvent ) | ( SubscriptionUnitsUpdatedEvent ) | ( SuperTokenCreatedEvent ) | ( SuperTokenFactoryUpdatedEvent ) | ( SuperTokenLogicCreatedEvent ) | ( SuperTokenLogicUpdatedEvent ) | ( SuperTokenMinimumDepositChangedEvent ) | ( TokenDowngradedEvent ) | ( TokenUpgradedEvent ) | ( TransferEvent ) | ( TrustedForwarderChangedEvent );
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Account: ResolverTypeWrapper<Account>;
  AccountTokenSnapshot: ResolverTypeWrapper<AccountTokenSnapshot>;
  AccountTokenSnapshotLog: ResolverTypeWrapper<AccountTokenSnapshotLog>;
  AccountTokenSnapshotLog_filter: AccountTokenSnapshotLog_Filter;
  AccountTokenSnapshotLog_orderBy: AccountTokenSnapshotLog_OrderBy;
  AccountTokenSnapshot_filter: AccountTokenSnapshot_Filter;
  AccountTokenSnapshot_orderBy: AccountTokenSnapshot_OrderBy;
  Account_filter: Account_Filter;
  Account_orderBy: Account_OrderBy;
  Aggregation_interval: Aggregation_Interval;
  AgreementClassRegisteredEvent: ResolverTypeWrapper<AgreementClassRegisteredEvent>;
  AgreementClassRegisteredEvent_filter: AgreementClassRegisteredEvent_Filter;
  AgreementClassRegisteredEvent_orderBy: AgreementClassRegisteredEvent_OrderBy;
  AgreementClassUpdatedEvent: ResolverTypeWrapper<AgreementClassUpdatedEvent>;
  AgreementClassUpdatedEvent_filter: AgreementClassUpdatedEvent_Filter;
  AgreementClassUpdatedEvent_orderBy: AgreementClassUpdatedEvent_OrderBy;
  AgreementLiquidatedByEvent: ResolverTypeWrapper<AgreementLiquidatedByEvent>;
  AgreementLiquidatedByEvent_filter: AgreementLiquidatedByEvent_Filter;
  AgreementLiquidatedByEvent_orderBy: AgreementLiquidatedByEvent_OrderBy;
  AgreementLiquidatedV2Event: ResolverTypeWrapper<AgreementLiquidatedV2Event>;
  AgreementLiquidatedV2Event_filter: AgreementLiquidatedV2Event_Filter;
  AgreementLiquidatedV2Event_orderBy: AgreementLiquidatedV2Event_OrderBy;
  AppRegisteredEvent: ResolverTypeWrapper<AppRegisteredEvent>;
  AppRegisteredEvent_filter: AppRegisteredEvent_Filter;
  AppRegisteredEvent_orderBy: AppRegisteredEvent_OrderBy;
  ApprovalEvent: ResolverTypeWrapper<ApprovalEvent>;
  ApprovalEvent_filter: ApprovalEvent_Filter;
  ApprovalEvent_orderBy: ApprovalEvent_OrderBy;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']['output']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_Height;
  BondIncreasedEvent: ResolverTypeWrapper<BondIncreasedEvent>;
  BondIncreasedEvent_filter: BondIncreasedEvent_Filter;
  BondIncreasedEvent_orderBy: BondIncreasedEvent_OrderBy;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BufferAdjustedEvent: ResolverTypeWrapper<BufferAdjustedEvent>;
  BufferAdjustedEvent_filter: BufferAdjustedEvent_Filter;
  BufferAdjustedEvent_orderBy: BufferAdjustedEvent_OrderBy;
  BurnedEvent: ResolverTypeWrapper<BurnedEvent>;
  BurnedEvent_filter: BurnedEvent_Filter;
  BurnedEvent_orderBy: BurnedEvent_OrderBy;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']['output']>;
  CFAv1LiquidationPeriodChangedEvent: ResolverTypeWrapper<CfAv1LiquidationPeriodChangedEvent>;
  CFAv1LiquidationPeriodChangedEvent_filter: CfAv1LiquidationPeriodChangedEvent_Filter;
  CFAv1LiquidationPeriodChangedEvent_orderBy: CfAv1LiquidationPeriodChangedEvent_OrderBy;
  ConfigChangedEvent: ResolverTypeWrapper<ConfigChangedEvent>;
  ConfigChangedEvent_filter: ConfigChangedEvent_Filter;
  ConfigChangedEvent_orderBy: ConfigChangedEvent_OrderBy;
  CustomSuperTokenCreatedEvent: ResolverTypeWrapper<CustomSuperTokenCreatedEvent>;
  CustomSuperTokenCreatedEvent_filter: CustomSuperTokenCreatedEvent_Filter;
  CustomSuperTokenCreatedEvent_orderBy: CustomSuperTokenCreatedEvent_OrderBy;
  DistributionClaimedEvent: ResolverTypeWrapper<DistributionClaimedEvent>;
  DistributionClaimedEvent_filter: DistributionClaimedEvent_Filter;
  DistributionClaimedEvent_orderBy: DistributionClaimedEvent_OrderBy;
  Event: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Event']>;
  Event_filter: Event_Filter;
  Event_orderBy: Event_OrderBy;
  ExitRateChangedEvent: ResolverTypeWrapper<ExitRateChangedEvent>;
  ExitRateChangedEvent_filter: ExitRateChangedEvent_Filter;
  ExitRateChangedEvent_orderBy: ExitRateChangedEvent_OrderBy;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FlowDistributionUpdatedEvent: ResolverTypeWrapper<FlowDistributionUpdatedEvent>;
  FlowDistributionUpdatedEvent_filter: FlowDistributionUpdatedEvent_Filter;
  FlowDistributionUpdatedEvent_orderBy: FlowDistributionUpdatedEvent_OrderBy;
  FlowOperator: ResolverTypeWrapper<FlowOperator>;
  FlowOperatorUpdatedEvent: ResolverTypeWrapper<FlowOperatorUpdatedEvent>;
  FlowOperatorUpdatedEvent_filter: FlowOperatorUpdatedEvent_Filter;
  FlowOperatorUpdatedEvent_orderBy: FlowOperatorUpdatedEvent_OrderBy;
  FlowOperator_filter: FlowOperator_Filter;
  FlowOperator_orderBy: FlowOperator_OrderBy;
  FlowUpdatedEvent: ResolverTypeWrapper<FlowUpdatedEvent>;
  FlowUpdatedEvent_filter: FlowUpdatedEvent_Filter;
  FlowUpdatedEvent_orderBy: FlowUpdatedEvent_OrderBy;
  GovernanceReplacedEvent: ResolverTypeWrapper<GovernanceReplacedEvent>;
  GovernanceReplacedEvent_filter: GovernanceReplacedEvent_Filter;
  GovernanceReplacedEvent_orderBy: GovernanceReplacedEvent_OrderBy;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Index: ResolverTypeWrapper<Index>;
  IndexCreatedEvent: ResolverTypeWrapper<IndexCreatedEvent>;
  IndexCreatedEvent_filter: IndexCreatedEvent_Filter;
  IndexCreatedEvent_orderBy: IndexCreatedEvent_OrderBy;
  IndexDistributionClaimedEvent: ResolverTypeWrapper<IndexDistributionClaimedEvent>;
  IndexDistributionClaimedEvent_filter: IndexDistributionClaimedEvent_Filter;
  IndexDistributionClaimedEvent_orderBy: IndexDistributionClaimedEvent_OrderBy;
  IndexSubscribedEvent: ResolverTypeWrapper<IndexSubscribedEvent>;
  IndexSubscribedEvent_filter: IndexSubscribedEvent_Filter;
  IndexSubscribedEvent_orderBy: IndexSubscribedEvent_OrderBy;
  IndexSubscription: ResolverTypeWrapper<IndexSubscription>;
  IndexSubscription_filter: IndexSubscription_Filter;
  IndexSubscription_orderBy: IndexSubscription_OrderBy;
  IndexUnitsUpdatedEvent: ResolverTypeWrapper<IndexUnitsUpdatedEvent>;
  IndexUnitsUpdatedEvent_filter: IndexUnitsUpdatedEvent_Filter;
  IndexUnitsUpdatedEvent_orderBy: IndexUnitsUpdatedEvent_OrderBy;
  IndexUnsubscribedEvent: ResolverTypeWrapper<IndexUnsubscribedEvent>;
  IndexUnsubscribedEvent_filter: IndexUnsubscribedEvent_Filter;
  IndexUnsubscribedEvent_orderBy: IndexUnsubscribedEvent_OrderBy;
  IndexUpdatedEvent: ResolverTypeWrapper<IndexUpdatedEvent>;
  IndexUpdatedEvent_filter: IndexUpdatedEvent_Filter;
  IndexUpdatedEvent_orderBy: IndexUpdatedEvent_OrderBy;
  Index_filter: Index_Filter;
  Index_orderBy: Index_OrderBy;
  InstantDistributionUpdatedEvent: ResolverTypeWrapper<InstantDistributionUpdatedEvent>;
  InstantDistributionUpdatedEvent_filter: InstantDistributionUpdatedEvent_Filter;
  InstantDistributionUpdatedEvent_orderBy: InstantDistributionUpdatedEvent_OrderBy;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']['output']>;
  JailEvent: ResolverTypeWrapper<JailEvent>;
  JailEvent_filter: JailEvent_Filter;
  JailEvent_orderBy: JailEvent_OrderBy;
  MemberUnitsUpdatedEvent: ResolverTypeWrapper<MemberUnitsUpdatedEvent>;
  MemberUnitsUpdatedEvent_filter: MemberUnitsUpdatedEvent_Filter;
  MemberUnitsUpdatedEvent_orderBy: MemberUnitsUpdatedEvent_OrderBy;
  MintedEvent: ResolverTypeWrapper<MintedEvent>;
  MintedEvent_filter: MintedEvent_Filter;
  MintedEvent_orderBy: MintedEvent_OrderBy;
  NewPICEvent: ResolverTypeWrapper<NewPicEvent>;
  NewPICEvent_filter: NewPicEvent_Filter;
  NewPICEvent_orderBy: NewPicEvent_OrderBy;
  OrderDirection: OrderDirection;
  PPPConfigurationChangedEvent: ResolverTypeWrapper<PppConfigurationChangedEvent>;
  PPPConfigurationChangedEvent_filter: PppConfigurationChangedEvent_Filter;
  PPPConfigurationChangedEvent_orderBy: PppConfigurationChangedEvent_OrderBy;
  Pool: ResolverTypeWrapper<Pool>;
  PoolConnectionUpdatedEvent: ResolverTypeWrapper<PoolConnectionUpdatedEvent>;
  PoolConnectionUpdatedEvent_filter: PoolConnectionUpdatedEvent_Filter;
  PoolConnectionUpdatedEvent_orderBy: PoolConnectionUpdatedEvent_OrderBy;
  PoolCreatedEvent: ResolverTypeWrapper<PoolCreatedEvent>;
  PoolCreatedEvent_filter: PoolCreatedEvent_Filter;
  PoolCreatedEvent_orderBy: PoolCreatedEvent_OrderBy;
  PoolDistributor: ResolverTypeWrapper<PoolDistributor>;
  PoolDistributor_filter: PoolDistributor_Filter;
  PoolDistributor_orderBy: PoolDistributor_OrderBy;
  PoolMember: ResolverTypeWrapper<PoolMember>;
  PoolMember_filter: PoolMember_Filter;
  PoolMember_orderBy: PoolMember_OrderBy;
  Pool_filter: Pool_Filter;
  Pool_orderBy: Pool_OrderBy;
  Query: ResolverTypeWrapper<{}>;
  ResolverEntry: ResolverTypeWrapper<ResolverEntry>;
  ResolverEntry_filter: ResolverEntry_Filter;
  ResolverEntry_orderBy: ResolverEntry_OrderBy;
  RewardAddressChangedEvent: ResolverTypeWrapper<RewardAddressChangedEvent>;
  RewardAddressChangedEvent_filter: RewardAddressChangedEvent_Filter;
  RewardAddressChangedEvent_orderBy: RewardAddressChangedEvent_OrderBy;
  RoleAdminChangedEvent: ResolverTypeWrapper<RoleAdminChangedEvent>;
  RoleAdminChangedEvent_filter: RoleAdminChangedEvent_Filter;
  RoleAdminChangedEvent_orderBy: RoleAdminChangedEvent_OrderBy;
  RoleGrantedEvent: ResolverTypeWrapper<RoleGrantedEvent>;
  RoleGrantedEvent_filter: RoleGrantedEvent_Filter;
  RoleGrantedEvent_orderBy: RoleGrantedEvent_OrderBy;
  RoleRevokedEvent: ResolverTypeWrapper<RoleRevokedEvent>;
  RoleRevokedEvent_filter: RoleRevokedEvent_Filter;
  RoleRevokedEvent_orderBy: RoleRevokedEvent_OrderBy;
  SFMeta: ResolverTypeWrapper<SfMeta>;
  SFMeta_filter: SfMeta_Filter;
  SFMeta_orderBy: SfMeta_OrderBy;
  SentEvent: ResolverTypeWrapper<SentEvent>;
  SentEvent_filter: SentEvent_Filter;
  SentEvent_orderBy: SentEvent_OrderBy;
  SetEvent: ResolverTypeWrapper<SetEvent>;
  SetEvent_filter: SetEvent_Filter;
  SetEvent_orderBy: SetEvent_OrderBy;
  Stream: ResolverTypeWrapper<Stream>;
  StreamPeriod: ResolverTypeWrapper<StreamPeriod>;
  StreamPeriod_filter: StreamPeriod_Filter;
  StreamPeriod_orderBy: StreamPeriod_OrderBy;
  StreamRevision: ResolverTypeWrapper<StreamRevision>;
  StreamRevision_filter: StreamRevision_Filter;
  StreamRevision_orderBy: StreamRevision_OrderBy;
  Stream_filter: Stream_Filter;
  Stream_orderBy: Stream_OrderBy;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionApprovedEvent: ResolverTypeWrapper<SubscriptionApprovedEvent>;
  SubscriptionApprovedEvent_filter: SubscriptionApprovedEvent_Filter;
  SubscriptionApprovedEvent_orderBy: SubscriptionApprovedEvent_OrderBy;
  SubscriptionDistributionClaimedEvent: ResolverTypeWrapper<SubscriptionDistributionClaimedEvent>;
  SubscriptionDistributionClaimedEvent_filter: SubscriptionDistributionClaimedEvent_Filter;
  SubscriptionDistributionClaimedEvent_orderBy: SubscriptionDistributionClaimedEvent_OrderBy;
  SubscriptionRevokedEvent: ResolverTypeWrapper<SubscriptionRevokedEvent>;
  SubscriptionRevokedEvent_filter: SubscriptionRevokedEvent_Filter;
  SubscriptionRevokedEvent_orderBy: SubscriptionRevokedEvent_OrderBy;
  SubscriptionUnitsUpdatedEvent: ResolverTypeWrapper<SubscriptionUnitsUpdatedEvent>;
  SubscriptionUnitsUpdatedEvent_filter: SubscriptionUnitsUpdatedEvent_Filter;
  SubscriptionUnitsUpdatedEvent_orderBy: SubscriptionUnitsUpdatedEvent_OrderBy;
  SuperTokenCreatedEvent: ResolverTypeWrapper<SuperTokenCreatedEvent>;
  SuperTokenCreatedEvent_filter: SuperTokenCreatedEvent_Filter;
  SuperTokenCreatedEvent_orderBy: SuperTokenCreatedEvent_OrderBy;
  SuperTokenFactoryUpdatedEvent: ResolverTypeWrapper<SuperTokenFactoryUpdatedEvent>;
  SuperTokenFactoryUpdatedEvent_filter: SuperTokenFactoryUpdatedEvent_Filter;
  SuperTokenFactoryUpdatedEvent_orderBy: SuperTokenFactoryUpdatedEvent_OrderBy;
  SuperTokenLogicCreatedEvent: ResolverTypeWrapper<SuperTokenLogicCreatedEvent>;
  SuperTokenLogicCreatedEvent_filter: SuperTokenLogicCreatedEvent_Filter;
  SuperTokenLogicCreatedEvent_orderBy: SuperTokenLogicCreatedEvent_OrderBy;
  SuperTokenLogicUpdatedEvent: ResolverTypeWrapper<SuperTokenLogicUpdatedEvent>;
  SuperTokenLogicUpdatedEvent_filter: SuperTokenLogicUpdatedEvent_Filter;
  SuperTokenLogicUpdatedEvent_orderBy: SuperTokenLogicUpdatedEvent_OrderBy;
  SuperTokenMinimumDepositChangedEvent: ResolverTypeWrapper<SuperTokenMinimumDepositChangedEvent>;
  SuperTokenMinimumDepositChangedEvent_filter: SuperTokenMinimumDepositChangedEvent_Filter;
  SuperTokenMinimumDepositChangedEvent_orderBy: SuperTokenMinimumDepositChangedEvent_OrderBy;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']['output']>;
  Token: ResolverTypeWrapper<Token>;
  TokenDowngradedEvent: ResolverTypeWrapper<TokenDowngradedEvent>;
  TokenDowngradedEvent_filter: TokenDowngradedEvent_Filter;
  TokenDowngradedEvent_orderBy: TokenDowngradedEvent_OrderBy;
  TokenGovernanceConfig: ResolverTypeWrapper<TokenGovernanceConfig>;
  TokenGovernanceConfig_filter: TokenGovernanceConfig_Filter;
  TokenGovernanceConfig_orderBy: TokenGovernanceConfig_OrderBy;
  TokenStatistic: ResolverTypeWrapper<TokenStatistic>;
  TokenStatisticLog: ResolverTypeWrapper<TokenStatisticLog>;
  TokenStatisticLog_filter: TokenStatisticLog_Filter;
  TokenStatisticLog_orderBy: TokenStatisticLog_OrderBy;
  TokenStatistic_filter: TokenStatistic_Filter;
  TokenStatistic_orderBy: TokenStatistic_OrderBy;
  TokenUpgradedEvent: ResolverTypeWrapper<TokenUpgradedEvent>;
  TokenUpgradedEvent_filter: TokenUpgradedEvent_Filter;
  TokenUpgradedEvent_orderBy: TokenUpgradedEvent_OrderBy;
  Token_filter: Token_Filter;
  Token_orderBy: Token_OrderBy;
  TransferEvent: ResolverTypeWrapper<TransferEvent>;
  TransferEvent_filter: TransferEvent_Filter;
  TransferEvent_orderBy: TransferEvent_OrderBy;
  TrustedForwarderChangedEvent: ResolverTypeWrapper<TrustedForwarderChangedEvent>;
  TrustedForwarderChangedEvent_filter: TrustedForwarderChangedEvent_Filter;
  TrustedForwarderChangedEvent_orderBy: TrustedForwarderChangedEvent_OrderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Account: Account;
  AccountTokenSnapshot: AccountTokenSnapshot;
  AccountTokenSnapshotLog: AccountTokenSnapshotLog;
  AccountTokenSnapshotLog_filter: AccountTokenSnapshotLog_Filter;
  AccountTokenSnapshot_filter: AccountTokenSnapshot_Filter;
  Account_filter: Account_Filter;
  AgreementClassRegisteredEvent: AgreementClassRegisteredEvent;
  AgreementClassRegisteredEvent_filter: AgreementClassRegisteredEvent_Filter;
  AgreementClassUpdatedEvent: AgreementClassUpdatedEvent;
  AgreementClassUpdatedEvent_filter: AgreementClassUpdatedEvent_Filter;
  AgreementLiquidatedByEvent: AgreementLiquidatedByEvent;
  AgreementLiquidatedByEvent_filter: AgreementLiquidatedByEvent_Filter;
  AgreementLiquidatedV2Event: AgreementLiquidatedV2Event;
  AgreementLiquidatedV2Event_filter: AgreementLiquidatedV2Event_Filter;
  AppRegisteredEvent: AppRegisteredEvent;
  AppRegisteredEvent_filter: AppRegisteredEvent_Filter;
  ApprovalEvent: ApprovalEvent;
  ApprovalEvent_filter: ApprovalEvent_Filter;
  BigDecimal: Scalars['BigDecimal']['output'];
  BigInt: Scalars['BigInt']['output'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_Height;
  BondIncreasedEvent: BondIncreasedEvent;
  BondIncreasedEvent_filter: BondIncreasedEvent_Filter;
  Boolean: Scalars['Boolean']['output'];
  BufferAdjustedEvent: BufferAdjustedEvent;
  BufferAdjustedEvent_filter: BufferAdjustedEvent_Filter;
  BurnedEvent: BurnedEvent;
  BurnedEvent_filter: BurnedEvent_Filter;
  Bytes: Scalars['Bytes']['output'];
  CFAv1LiquidationPeriodChangedEvent: CfAv1LiquidationPeriodChangedEvent;
  CFAv1LiquidationPeriodChangedEvent_filter: CfAv1LiquidationPeriodChangedEvent_Filter;
  ConfigChangedEvent: ConfigChangedEvent;
  ConfigChangedEvent_filter: ConfigChangedEvent_Filter;
  CustomSuperTokenCreatedEvent: CustomSuperTokenCreatedEvent;
  CustomSuperTokenCreatedEvent_filter: CustomSuperTokenCreatedEvent_Filter;
  DistributionClaimedEvent: DistributionClaimedEvent;
  DistributionClaimedEvent_filter: DistributionClaimedEvent_Filter;
  Event: ResolversInterfaceTypes<ResolversParentTypes>['Event'];
  Event_filter: Event_Filter;
  ExitRateChangedEvent: ExitRateChangedEvent;
  ExitRateChangedEvent_filter: ExitRateChangedEvent_Filter;
  Float: Scalars['Float']['output'];
  FlowDistributionUpdatedEvent: FlowDistributionUpdatedEvent;
  FlowDistributionUpdatedEvent_filter: FlowDistributionUpdatedEvent_Filter;
  FlowOperator: FlowOperator;
  FlowOperatorUpdatedEvent: FlowOperatorUpdatedEvent;
  FlowOperatorUpdatedEvent_filter: FlowOperatorUpdatedEvent_Filter;
  FlowOperator_filter: FlowOperator_Filter;
  FlowUpdatedEvent: FlowUpdatedEvent;
  FlowUpdatedEvent_filter: FlowUpdatedEvent_Filter;
  GovernanceReplacedEvent: GovernanceReplacedEvent;
  GovernanceReplacedEvent_filter: GovernanceReplacedEvent_Filter;
  ID: Scalars['ID']['output'];
  Index: Index;
  IndexCreatedEvent: IndexCreatedEvent;
  IndexCreatedEvent_filter: IndexCreatedEvent_Filter;
  IndexDistributionClaimedEvent: IndexDistributionClaimedEvent;
  IndexDistributionClaimedEvent_filter: IndexDistributionClaimedEvent_Filter;
  IndexSubscribedEvent: IndexSubscribedEvent;
  IndexSubscribedEvent_filter: IndexSubscribedEvent_Filter;
  IndexSubscription: IndexSubscription;
  IndexSubscription_filter: IndexSubscription_Filter;
  IndexUnitsUpdatedEvent: IndexUnitsUpdatedEvent;
  IndexUnitsUpdatedEvent_filter: IndexUnitsUpdatedEvent_Filter;
  IndexUnsubscribedEvent: IndexUnsubscribedEvent;
  IndexUnsubscribedEvent_filter: IndexUnsubscribedEvent_Filter;
  IndexUpdatedEvent: IndexUpdatedEvent;
  IndexUpdatedEvent_filter: IndexUpdatedEvent_Filter;
  Index_filter: Index_Filter;
  InstantDistributionUpdatedEvent: InstantDistributionUpdatedEvent;
  InstantDistributionUpdatedEvent_filter: InstantDistributionUpdatedEvent_Filter;
  Int: Scalars['Int']['output'];
  Int8: Scalars['Int8']['output'];
  JailEvent: JailEvent;
  JailEvent_filter: JailEvent_Filter;
  MemberUnitsUpdatedEvent: MemberUnitsUpdatedEvent;
  MemberUnitsUpdatedEvent_filter: MemberUnitsUpdatedEvent_Filter;
  MintedEvent: MintedEvent;
  MintedEvent_filter: MintedEvent_Filter;
  NewPICEvent: NewPicEvent;
  NewPICEvent_filter: NewPicEvent_Filter;
  PPPConfigurationChangedEvent: PppConfigurationChangedEvent;
  PPPConfigurationChangedEvent_filter: PppConfigurationChangedEvent_Filter;
  Pool: Pool;
  PoolConnectionUpdatedEvent: PoolConnectionUpdatedEvent;
  PoolConnectionUpdatedEvent_filter: PoolConnectionUpdatedEvent_Filter;
  PoolCreatedEvent: PoolCreatedEvent;
  PoolCreatedEvent_filter: PoolCreatedEvent_Filter;
  PoolDistributor: PoolDistributor;
  PoolDistributor_filter: PoolDistributor_Filter;
  PoolMember: PoolMember;
  PoolMember_filter: PoolMember_Filter;
  Pool_filter: Pool_Filter;
  Query: {};
  ResolverEntry: ResolverEntry;
  ResolverEntry_filter: ResolverEntry_Filter;
  RewardAddressChangedEvent: RewardAddressChangedEvent;
  RewardAddressChangedEvent_filter: RewardAddressChangedEvent_Filter;
  RoleAdminChangedEvent: RoleAdminChangedEvent;
  RoleAdminChangedEvent_filter: RoleAdminChangedEvent_Filter;
  RoleGrantedEvent: RoleGrantedEvent;
  RoleGrantedEvent_filter: RoleGrantedEvent_Filter;
  RoleRevokedEvent: RoleRevokedEvent;
  RoleRevokedEvent_filter: RoleRevokedEvent_Filter;
  SFMeta: SfMeta;
  SFMeta_filter: SfMeta_Filter;
  SentEvent: SentEvent;
  SentEvent_filter: SentEvent_Filter;
  SetEvent: SetEvent;
  SetEvent_filter: SetEvent_Filter;
  Stream: Stream;
  StreamPeriod: StreamPeriod;
  StreamPeriod_filter: StreamPeriod_Filter;
  StreamRevision: StreamRevision;
  StreamRevision_filter: StreamRevision_Filter;
  Stream_filter: Stream_Filter;
  String: Scalars['String']['output'];
  Subscription: {};
  SubscriptionApprovedEvent: SubscriptionApprovedEvent;
  SubscriptionApprovedEvent_filter: SubscriptionApprovedEvent_Filter;
  SubscriptionDistributionClaimedEvent: SubscriptionDistributionClaimedEvent;
  SubscriptionDistributionClaimedEvent_filter: SubscriptionDistributionClaimedEvent_Filter;
  SubscriptionRevokedEvent: SubscriptionRevokedEvent;
  SubscriptionRevokedEvent_filter: SubscriptionRevokedEvent_Filter;
  SubscriptionUnitsUpdatedEvent: SubscriptionUnitsUpdatedEvent;
  SubscriptionUnitsUpdatedEvent_filter: SubscriptionUnitsUpdatedEvent_Filter;
  SuperTokenCreatedEvent: SuperTokenCreatedEvent;
  SuperTokenCreatedEvent_filter: SuperTokenCreatedEvent_Filter;
  SuperTokenFactoryUpdatedEvent: SuperTokenFactoryUpdatedEvent;
  SuperTokenFactoryUpdatedEvent_filter: SuperTokenFactoryUpdatedEvent_Filter;
  SuperTokenLogicCreatedEvent: SuperTokenLogicCreatedEvent;
  SuperTokenLogicCreatedEvent_filter: SuperTokenLogicCreatedEvent_Filter;
  SuperTokenLogicUpdatedEvent: SuperTokenLogicUpdatedEvent;
  SuperTokenLogicUpdatedEvent_filter: SuperTokenLogicUpdatedEvent_Filter;
  SuperTokenMinimumDepositChangedEvent: SuperTokenMinimumDepositChangedEvent;
  SuperTokenMinimumDepositChangedEvent_filter: SuperTokenMinimumDepositChangedEvent_Filter;
  Timestamp: Scalars['Timestamp']['output'];
  Token: Token;
  TokenDowngradedEvent: TokenDowngradedEvent;
  TokenDowngradedEvent_filter: TokenDowngradedEvent_Filter;
  TokenGovernanceConfig: TokenGovernanceConfig;
  TokenGovernanceConfig_filter: TokenGovernanceConfig_Filter;
  TokenStatistic: TokenStatistic;
  TokenStatisticLog: TokenStatisticLog;
  TokenStatisticLog_filter: TokenStatisticLog_Filter;
  TokenStatistic_filter: TokenStatistic_Filter;
  TokenUpgradedEvent: TokenUpgradedEvent;
  TokenUpgradedEvent_filter: TokenUpgradedEvent_Filter;
  Token_filter: Token_Filter;
  TransferEvent: TransferEvent;
  TransferEvent_filter: TransferEvent_Filter;
  TrustedForwarderChangedEvent: TrustedForwarderChangedEvent;
  TrustedForwarderChangedEvent_filter: TrustedForwarderChangedEvent_Filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type EntityDirectiveArgs = { };

export type EntityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = EntityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type SubgraphIdDirectiveArgs = {
  id: Scalars['String']['input'];
};

export type SubgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = SubgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DerivedFromDirectiveArgs = {
  field: Scalars['String']['input'];
};

export type DerivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = DerivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isSuperApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inflows?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<AccountInflowsArgs, 'skip' | 'first'>>;
  outflows?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<AccountOutflowsArgs, 'skip' | 'first'>>;
  subscriptions?: Resolver<Array<ResolversTypes['IndexSubscription']>, ParentType, ContextType, RequireFields<AccountSubscriptionsArgs, 'skip' | 'first'>>;
  publishedIndexes?: Resolver<Array<ResolversTypes['Index']>, ParentType, ContextType, RequireFields<AccountPublishedIndexesArgs, 'skip' | 'first'>>;
  pools?: Resolver<Array<ResolversTypes['Pool']>, ParentType, ContextType, RequireFields<AccountPoolsArgs, 'skip' | 'first'>>;
  poolMemberships?: Resolver<Array<ResolversTypes['PoolMember']>, ParentType, ContextType, RequireFields<AccountPoolMembershipsArgs, 'skip' | 'first'>>;
  sentTransferEvents?: Resolver<Array<ResolversTypes['TransferEvent']>, ParentType, ContextType, RequireFields<AccountSentTransferEventsArgs, 'skip' | 'first'>>;
  receivedTransferEvents?: Resolver<Array<ResolversTypes['TransferEvent']>, ParentType, ContextType, RequireFields<AccountReceivedTransferEventsArgs, 'skip' | 'first'>>;
  tokenUpgradedEvents?: Resolver<Array<ResolversTypes['TokenUpgradedEvent']>, ParentType, ContextType, RequireFields<AccountTokenUpgradedEventsArgs, 'skip' | 'first'>>;
  tokenDowngradedEvents?: Resolver<Array<ResolversTypes['TokenDowngradedEvent']>, ParentType, ContextType, RequireFields<AccountTokenDowngradedEventsArgs, 'skip' | 'first'>>;
  accountTokenSnapshots?: Resolver<Array<ResolversTypes['AccountTokenSnapshot']>, ParentType, ContextType, RequireFields<AccountAccountTokenSnapshotsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountTokenSnapshotResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AccountTokenSnapshot'] = ResolversParentTypes['AccountTokenSnapshot']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isLiquidationEstimateOptimistic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maybeCriticalAtTimestamp?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  totalNumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeCFAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeGDAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeIncomingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveCFAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveGDAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveIncomingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSubscriptionsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalApprovedSubscriptions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMembershipsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnectedMemberships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adminOfPoolCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  balanceUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  balanceLastUpdatedFromRpcBlocknumber?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  totalDeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalNetFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFANetFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalInflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedInUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedOutUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamedOutUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountTransferredUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  flowOperators?: Resolver<Array<ResolversTypes['FlowOperator']>, ParentType, ContextType, RequireFields<AccountTokenSnapshotFlowOperatorsArgs, 'skip' | 'first'>>;
  accountTokenSnapshotLogs?: Resolver<Array<ResolversTypes['AccountTokenSnapshotLog']>, ParentType, ContextType, RequireFields<AccountTokenSnapshotAccountTokenSnapshotLogsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccountTokenSnapshotLogResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AccountTokenSnapshotLog'] = ResolversParentTypes['AccountTokenSnapshotLog']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  triggeredByEventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maybeCriticalAtTimestamp?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  totalNumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeCFAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeGDAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeIncomingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveCFAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveGDAOutgoingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inactiveIncomingStreamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSubscriptionsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalApprovedSubscriptions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMembershipsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnectedMemberships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalDeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalNetFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFANetFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalInflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedIn?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamedOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountTransferred?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  accountTokenSnapshot?: Resolver<ResolversTypes['AccountTokenSnapshot'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgreementClassRegisteredEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AgreementClassRegisteredEvent'] = ResolversParentTypes['AgreementClassRegisteredEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  agreementType?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgreementClassUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AgreementClassUpdatedEvent'] = ResolversParentTypes['AgreementClassUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  agreementType?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgreementLiquidatedByEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AgreementLiquidatedByEvent'] = ResolversParentTypes['AgreementLiquidatedByEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  liquidatorAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  agreementClass?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  agreementId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  penaltyAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  bondAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  rewardAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  bailoutAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowRateAtLiquidation?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgreementLiquidatedV2EventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AgreementLiquidatedV2Event'] = ResolversParentTypes['AgreementLiquidatedV2Event']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  agreementClass?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  agreementId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  liquidatorAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  targetAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  rewardAmountReceiver?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  rewardAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  targetAccountBalanceDelta?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  liquidationType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowRateAtLiquidation?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  rewardAccount?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AppRegisteredEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AppRegisteredEvent'] = ResolversParentTypes['AppRegisteredEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  app?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApprovalEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ApprovalEvent'] = ResolversParentTypes['ApprovalEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BondIncreasedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['BondIncreasedEvent'] = ResolversParentTypes['BondIncreasedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  additionalBond?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BufferAdjustedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['BufferAdjustedEvent'] = ResolversParentTypes['BufferAdjustedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  bufferDelta?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  newBufferAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalBufferAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolDistributor?: Resolver<ResolversTypes['PoolDistributor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BurnedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['BurnedEvent'] = ResolversParentTypes['BurnedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  operatorData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type CfAv1LiquidationPeriodChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['CFAv1LiquidationPeriodChangedEvent'] = ResolversParentTypes['CFAv1LiquidationPeriodChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  liquidationPeriod?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConfigChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ConfigChangedEvent'] = ResolversParentTypes['ConfigChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomSuperTokenCreatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['CustomSuperTokenCreatedEvent'] = ResolversParentTypes['CustomSuperTokenCreatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DistributionClaimedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['DistributionClaimedEvent'] = ResolversParentTypes['DistributionClaimedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  claimedAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalClaimed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolMember?: Resolver<ResolversTypes['PoolMember'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = ResolversObject<{
  __resolveType: TypeResolveFn<'AgreementClassRegisteredEvent' | 'AgreementClassUpdatedEvent' | 'AgreementLiquidatedByEvent' | 'AgreementLiquidatedV2Event' | 'AppRegisteredEvent' | 'ApprovalEvent' | 'BondIncreasedEvent' | 'BufferAdjustedEvent' | 'BurnedEvent' | 'CFAv1LiquidationPeriodChangedEvent' | 'ConfigChangedEvent' | 'CustomSuperTokenCreatedEvent' | 'DistributionClaimedEvent' | 'ExitRateChangedEvent' | 'FlowDistributionUpdatedEvent' | 'FlowOperatorUpdatedEvent' | 'FlowUpdatedEvent' | 'GovernanceReplacedEvent' | 'IndexCreatedEvent' | 'IndexDistributionClaimedEvent' | 'IndexSubscribedEvent' | 'IndexUnitsUpdatedEvent' | 'IndexUnsubscribedEvent' | 'IndexUpdatedEvent' | 'InstantDistributionUpdatedEvent' | 'JailEvent' | 'MemberUnitsUpdatedEvent' | 'MintedEvent' | 'NewPICEvent' | 'PPPConfigurationChangedEvent' | 'PoolConnectionUpdatedEvent' | 'PoolCreatedEvent' | 'RewardAddressChangedEvent' | 'RoleAdminChangedEvent' | 'RoleGrantedEvent' | 'RoleRevokedEvent' | 'SentEvent' | 'SetEvent' | 'SubscriptionApprovedEvent' | 'SubscriptionDistributionClaimedEvent' | 'SubscriptionRevokedEvent' | 'SubscriptionUnitsUpdatedEvent' | 'SuperTokenCreatedEvent' | 'SuperTokenFactoryUpdatedEvent' | 'SuperTokenLogicCreatedEvent' | 'SuperTokenLogicUpdatedEvent' | 'SuperTokenMinimumDepositChangedEvent' | 'TokenDowngradedEvent' | 'TokenUpgradedEvent' | 'TransferEvent' | 'TrustedForwarderChangedEvent', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
}>;

export type ExitRateChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ExitRateChangedEvent'] = ResolversParentTypes['ExitRateChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  exitRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FlowDistributionUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['FlowDistributionUpdatedEvent'] = ResolversParentTypes['FlowDistributionUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oldFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  newDistributorToPoolFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  newTotalDistributionFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  adjustmentFlowRecipient?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  adjustmentFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolDistributor?: Resolver<ResolversTypes['PoolDistributor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FlowOperatorResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['FlowOperator'] = ResolversParentTypes['FlowOperator']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flowRateAllowanceGranted?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowRateAllowanceRemaining?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  allowance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowOperator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  accountTokenSnapshot?: Resolver<ResolversTypes['AccountTokenSnapshot'], ParentType, ContextType>;
  flowOperatorUpdatedEvents?: Resolver<Array<ResolversTypes['FlowOperatorUpdatedEvent']>, ParentType, ContextType, RequireFields<FlowOperatorFlowOperatorUpdatedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FlowOperatorUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['FlowOperatorUpdatedEvent'] = ResolversParentTypes['FlowOperatorUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  flowRateAllowance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowOperator?: Resolver<ResolversTypes['FlowOperator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FlowUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['FlowUpdatedEvent'] = ResolversParentTypes['FlowUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  flowOperator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  flowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSenderFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalReceiverFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oldFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmountStreamedUntilTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GovernanceReplacedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['GovernanceReplacedEvent'] = ResolversParentTypes['GovernanceReplacedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  oldGovernance?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  newGovernance?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Index'] = ResolversParentTypes['Index']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  indexValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSubscriptionsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnitsPending?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnitsApproved?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  subscriptions?: Resolver<Array<ResolversTypes['IndexSubscription']>, ParentType, ContextType, RequireFields<IndexSubscriptionsArgs, 'skip' | 'first'>>;
  indexCreatedEvent?: Resolver<ResolversTypes['IndexCreatedEvent'], ParentType, ContextType>;
  indexDistributionClaimedEvents?: Resolver<Array<ResolversTypes['IndexDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<IndexIndexDistributionClaimedEventsArgs, 'skip' | 'first'>>;
  indexUpdatedEvents?: Resolver<Array<ResolversTypes['IndexUpdatedEvent']>, ParentType, ContextType, RequireFields<IndexIndexUpdatedEventsArgs, 'skip' | 'first'>>;
  indexSubscribedEvents?: Resolver<Array<ResolversTypes['IndexSubscribedEvent']>, ParentType, ContextType, RequireFields<IndexIndexSubscribedEventsArgs, 'skip' | 'first'>>;
  indexUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['IndexUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<IndexIndexUnitsUpdatedEventsArgs, 'skip' | 'first'>>;
  indexUnsubscribedEvents?: Resolver<Array<ResolversTypes['IndexUnsubscribedEvent']>, ParentType, ContextType, RequireFields<IndexIndexUnsubscribedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexCreatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexCreatedEvent'] = ResolversParentTypes['IndexCreatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexDistributionClaimedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexDistributionClaimedEvent'] = ResolversParentTypes['IndexDistributionClaimedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexSubscribedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexSubscribedEvent'] = ResolversParentTypes['IndexSubscribedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexSubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexSubscription'] = ResolversParentTypes['IndexSubscription']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountReceivedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  indexValueUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  subscriptionApprovedEvents?: Resolver<Array<ResolversTypes['SubscriptionApprovedEvent']>, ParentType, ContextType, RequireFields<IndexSubscriptionSubscriptionApprovedEventsArgs, 'skip' | 'first'>>;
  subscriptionDistributionClaimedEvents?: Resolver<Array<ResolversTypes['SubscriptionDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<IndexSubscriptionSubscriptionDistributionClaimedEventsArgs, 'skip' | 'first'>>;
  subscriptionRevokedEvents?: Resolver<Array<ResolversTypes['SubscriptionRevokedEvent']>, ParentType, ContextType, RequireFields<IndexSubscriptionSubscriptionRevokedEventsArgs, 'skip' | 'first'>>;
  subscriptionUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['SubscriptionUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<IndexSubscriptionSubscriptionUnitsUpdatedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexUnitsUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexUnitsUpdatedEvent'] = ResolversParentTypes['IndexUnitsUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oldUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexUnsubscribedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexUnsubscribedEvent'] = ResolversParentTypes['IndexUnsubscribedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndexUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['IndexUpdatedEvent'] = ResolversParentTypes['IndexUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  oldIndexValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  newIndexValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnitsPending?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnitsApproved?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Index'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstantDistributionUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['InstantDistributionUpdatedEvent'] = ResolversParentTypes['InstantDistributionUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  requestedAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  actualAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolDistributor?: Resolver<ResolversTypes['PoolDistributor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type JailEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['JailEvent'] = ResolversParentTypes['JailEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  app?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MemberUnitsUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MemberUnitsUpdatedEvent'] = ResolversParentTypes['MemberUnitsUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oldUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolMember?: Resolver<ResolversTypes['PoolMember'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MintedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MintedEvent'] = ResolversParentTypes['MintedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  operatorData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewPicEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NewPICEvent'] = ResolversParentTypes['NewPICEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  pic?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  bond?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  exitRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PppConfigurationChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PPPConfigurationChangedEvent'] = ResolversParentTypes['PPPConfigurationChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  liquidationPeriod?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  patricianPeriod?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PoolResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Pool'] = ResolversParentTypes['Pool']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalConnectedUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalDisconnectedUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountInstantlyDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountFlowedDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalFlowAdjustmentAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  perUnitSettledValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  perUnitFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnectedMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDisconnectedMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adjustmentFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalBuffer?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  admin?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  poolDistributors?: Resolver<Array<ResolversTypes['PoolDistributor']>, ParentType, ContextType, RequireFields<PoolPoolDistributorsArgs, 'skip' | 'first'>>;
  poolMembers?: Resolver<Array<ResolversTypes['PoolMember']>, ParentType, ContextType, RequireFields<PoolPoolMembersArgs, 'skip' | 'first'>>;
  poolCreatedEvent?: Resolver<ResolversTypes['PoolCreatedEvent'], ParentType, ContextType>;
  poolConnectionUpdatedEvents?: Resolver<Array<ResolversTypes['PoolConnectionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolPoolConnectionUpdatedEventsArgs, 'skip' | 'first'>>;
  bufferAdjustedEvents?: Resolver<Array<ResolversTypes['BufferAdjustedEvent']>, ParentType, ContextType, RequireFields<PoolBufferAdjustedEventsArgs, 'skip' | 'first'>>;
  instantDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['InstantDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolInstantDistributionUpdatedEventsArgs, 'skip' | 'first'>>;
  flowDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['FlowDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolFlowDistributionUpdatedEventsArgs, 'skip' | 'first'>>;
  memberUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['MemberUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolMemberUnitsUpdatedEventsArgs, 'skip' | 'first'>>;
  distributionClaimedEvents?: Resolver<Array<ResolversTypes['DistributionClaimedEvent']>, ParentType, ContextType, RequireFields<PoolDistributionClaimedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PoolConnectionUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PoolConnectionUpdatedEvent'] = ResolversParentTypes['PoolConnectionUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolMember?: Resolver<ResolversTypes['PoolMember'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PoolCreatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PoolCreatedEvent'] = ResolversParentTypes['PoolCreatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  caller?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  admin?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PoolDistributorResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PoolDistributor'] = ResolversParentTypes['PoolDistributor']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountInstantlyDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountFlowedDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalBuffer?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  flowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  bufferAdjustedEvents?: Resolver<Array<ResolversTypes['BufferAdjustedEvent']>, ParentType, ContextType, RequireFields<PoolDistributorBufferAdjustedEventsArgs, 'skip' | 'first'>>;
  instantDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['InstantDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolDistributorInstantDistributionUpdatedEventsArgs, 'skip' | 'first'>>;
  flowDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['FlowDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolDistributorFlowDistributionUpdatedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PoolMemberResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PoolMember'] = ResolversParentTypes['PoolMember']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalAmountClaimed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  poolTotalAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountReceivedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  syncedPerUnitSettledValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  syncedPerUnitFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Pool'], ParentType, ContextType>;
  poolConnectionUpdatedEvents?: Resolver<Array<ResolversTypes['PoolConnectionUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolMemberPoolConnectionUpdatedEventsArgs, 'skip' | 'first'>>;
  memberUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['MemberUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<PoolMemberMemberUnitsUpdatedEventsArgs, 'skip' | 'first'>>;
  distributionClaimedEvents?: Resolver<Array<ResolversTypes['DistributionClaimedEvent']>, ParentType, ContextType, RequireFields<PoolMemberDistributionClaimedEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  flowUpdatedEvent?: Resolver<Maybe<ResolversTypes['FlowUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowUpdatedEvents?: Resolver<Array<ResolversTypes['FlowUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowOperatorUpdatedEvent?: Resolver<Maybe<ResolversTypes['FlowOperatorUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowOperatorUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowOperatorUpdatedEvents?: Resolver<Array<ResolversTypes['FlowOperatorUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowOperatorUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexCreatedEvent?: Resolver<Maybe<ResolversTypes['IndexCreatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexCreatedEventArgs, 'id' | 'subgraphError'>>;
  indexCreatedEvents?: Resolver<Array<ResolversTypes['IndexCreatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexDistributionClaimedEvent?: Resolver<Maybe<ResolversTypes['IndexDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QueryIndexDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  indexDistributionClaimedEvents?: Resolver<Array<ResolversTypes['IndexDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QueryIndexDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUpdatedEvent?: Resolver<Maybe<ResolversTypes['IndexUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUpdatedEventArgs, 'id' | 'subgraphError'>>;
  indexUpdatedEvents?: Resolver<Array<ResolversTypes['IndexUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexSubscribedEvent?: Resolver<Maybe<ResolversTypes['IndexSubscribedEvent']>, ParentType, ContextType, RequireFields<QueryIndexSubscribedEventArgs, 'id' | 'subgraphError'>>;
  indexSubscribedEvents?: Resolver<Array<ResolversTypes['IndexSubscribedEvent']>, ParentType, ContextType, RequireFields<QueryIndexSubscribedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUnitsUpdatedEvent?: Resolver<Maybe<ResolversTypes['IndexUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  indexUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['IndexUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUnsubscribedEvent?: Resolver<Maybe<ResolversTypes['IndexUnsubscribedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUnsubscribedEventArgs, 'id' | 'subgraphError'>>;
  indexUnsubscribedEvents?: Resolver<Array<ResolversTypes['IndexUnsubscribedEvent']>, ParentType, ContextType, RequireFields<QueryIndexUnsubscribedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionApprovedEvent?: Resolver<Maybe<ResolversTypes['SubscriptionApprovedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionApprovedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionApprovedEvents?: Resolver<Array<ResolversTypes['SubscriptionApprovedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionApprovedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionDistributionClaimedEvent?: Resolver<Maybe<ResolversTypes['SubscriptionDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionDistributionClaimedEvents?: Resolver<Array<ResolversTypes['SubscriptionDistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionRevokedEvent?: Resolver<Maybe<ResolversTypes['SubscriptionRevokedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionRevokedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionRevokedEvents?: Resolver<Array<ResolversTypes['SubscriptionRevokedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionRevokedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionUnitsUpdatedEvent?: Resolver<Maybe<ResolversTypes['SubscriptionUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['SubscriptionUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySubscriptionUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolCreatedEvent?: Resolver<Maybe<ResolversTypes['PoolCreatedEvent']>, ParentType, ContextType, RequireFields<QueryPoolCreatedEventArgs, 'id' | 'subgraphError'>>;
  poolCreatedEvents?: Resolver<Array<ResolversTypes['PoolCreatedEvent']>, ParentType, ContextType, RequireFields<QueryPoolCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolConnectionUpdatedEvent?: Resolver<Maybe<ResolversTypes['PoolConnectionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryPoolConnectionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  poolConnectionUpdatedEvents?: Resolver<Array<ResolversTypes['PoolConnectionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryPoolConnectionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  bufferAdjustedEvent?: Resolver<Maybe<ResolversTypes['BufferAdjustedEvent']>, ParentType, ContextType, RequireFields<QueryBufferAdjustedEventArgs, 'id' | 'subgraphError'>>;
  bufferAdjustedEvents?: Resolver<Array<ResolversTypes['BufferAdjustedEvent']>, ParentType, ContextType, RequireFields<QueryBufferAdjustedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  instantDistributionUpdatedEvent?: Resolver<Maybe<ResolversTypes['InstantDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryInstantDistributionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  instantDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['InstantDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryInstantDistributionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowDistributionUpdatedEvent?: Resolver<Maybe<ResolversTypes['FlowDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowDistributionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowDistributionUpdatedEvents?: Resolver<Array<ResolversTypes['FlowDistributionUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryFlowDistributionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  distributionClaimedEvent?: Resolver<Maybe<ResolversTypes['DistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QueryDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  distributionClaimedEvents?: Resolver<Array<ResolversTypes['DistributionClaimedEvent']>, ParentType, ContextType, RequireFields<QueryDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  memberUnitsUpdatedEvent?: Resolver<Maybe<ResolversTypes['MemberUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryMemberUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  memberUnitsUpdatedEvents?: Resolver<Array<ResolversTypes['MemberUnitsUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryMemberUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementClassRegisteredEvent?: Resolver<Maybe<ResolversTypes['AgreementClassRegisteredEvent']>, ParentType, ContextType, RequireFields<QueryAgreementClassRegisteredEventArgs, 'id' | 'subgraphError'>>;
  agreementClassRegisteredEvents?: Resolver<Array<ResolversTypes['AgreementClassRegisteredEvent']>, ParentType, ContextType, RequireFields<QueryAgreementClassRegisteredEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementClassUpdatedEvent?: Resolver<Maybe<ResolversTypes['AgreementClassUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryAgreementClassUpdatedEventArgs, 'id' | 'subgraphError'>>;
  agreementClassUpdatedEvents?: Resolver<Array<ResolversTypes['AgreementClassUpdatedEvent']>, ParentType, ContextType, RequireFields<QueryAgreementClassUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  appRegisteredEvent?: Resolver<Maybe<ResolversTypes['AppRegisteredEvent']>, ParentType, ContextType, RequireFields<QueryAppRegisteredEventArgs, 'id' | 'subgraphError'>>;
  appRegisteredEvents?: Resolver<Array<ResolversTypes['AppRegisteredEvent']>, ParentType, ContextType, RequireFields<QueryAppRegisteredEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  governanceReplacedEvent?: Resolver<Maybe<ResolversTypes['GovernanceReplacedEvent']>, ParentType, ContextType, RequireFields<QueryGovernanceReplacedEventArgs, 'id' | 'subgraphError'>>;
  governanceReplacedEvents?: Resolver<Array<ResolversTypes['GovernanceReplacedEvent']>, ParentType, ContextType, RequireFields<QueryGovernanceReplacedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  jailEvent?: Resolver<Maybe<ResolversTypes['JailEvent']>, ParentType, ContextType, RequireFields<QueryJailEventArgs, 'id' | 'subgraphError'>>;
  jailEvents?: Resolver<Array<ResolversTypes['JailEvent']>, ParentType, ContextType, RequireFields<QueryJailEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenFactoryUpdatedEvent?: Resolver<Maybe<ResolversTypes['SuperTokenFactoryUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenFactoryUpdatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenFactoryUpdatedEvents?: Resolver<Array<ResolversTypes['SuperTokenFactoryUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenFactoryUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenLogicUpdatedEvent?: Resolver<Maybe<ResolversTypes['SuperTokenLogicUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenLogicUpdatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenLogicUpdatedEvents?: Resolver<Array<ResolversTypes['SuperTokenLogicUpdatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenLogicUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleAdminChangedEvent?: Resolver<Maybe<ResolversTypes['RoleAdminChangedEvent']>, ParentType, ContextType, RequireFields<QueryRoleAdminChangedEventArgs, 'id' | 'subgraphError'>>;
  roleAdminChangedEvents?: Resolver<Array<ResolversTypes['RoleAdminChangedEvent']>, ParentType, ContextType, RequireFields<QueryRoleAdminChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleGrantedEvent?: Resolver<Maybe<ResolversTypes['RoleGrantedEvent']>, ParentType, ContextType, RequireFields<QueryRoleGrantedEventArgs, 'id' | 'subgraphError'>>;
  roleGrantedEvents?: Resolver<Array<ResolversTypes['RoleGrantedEvent']>, ParentType, ContextType, RequireFields<QueryRoleGrantedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleRevokedEvent?: Resolver<Maybe<ResolversTypes['RoleRevokedEvent']>, ParentType, ContextType, RequireFields<QueryRoleRevokedEventArgs, 'id' | 'subgraphError'>>;
  roleRevokedEvents?: Resolver<Array<ResolversTypes['RoleRevokedEvent']>, ParentType, ContextType, RequireFields<QueryRoleRevokedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  setEvent?: Resolver<Maybe<ResolversTypes['SetEvent']>, ParentType, ContextType, RequireFields<QuerySetEventArgs, 'id' | 'subgraphError'>>;
  setEvents?: Resolver<Array<ResolversTypes['SetEvent']>, ParentType, ContextType, RequireFields<QuerySetEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  cfav1LiquidationPeriodChangedEvent?: Resolver<Maybe<ResolversTypes['CFAv1LiquidationPeriodChangedEvent']>, ParentType, ContextType, RequireFields<QueryCfav1LiquidationPeriodChangedEventArgs, 'id' | 'subgraphError'>>;
  cfav1LiquidationPeriodChangedEvents?: Resolver<Array<ResolversTypes['CFAv1LiquidationPeriodChangedEvent']>, ParentType, ContextType, RequireFields<QueryCfav1LiquidationPeriodChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  configChangedEvent?: Resolver<Maybe<ResolversTypes['ConfigChangedEvent']>, ParentType, ContextType, RequireFields<QueryConfigChangedEventArgs, 'id' | 'subgraphError'>>;
  configChangedEvents?: Resolver<Array<ResolversTypes['ConfigChangedEvent']>, ParentType, ContextType, RequireFields<QueryConfigChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  rewardAddressChangedEvent?: Resolver<Maybe<ResolversTypes['RewardAddressChangedEvent']>, ParentType, ContextType, RequireFields<QueryRewardAddressChangedEventArgs, 'id' | 'subgraphError'>>;
  rewardAddressChangedEvents?: Resolver<Array<ResolversTypes['RewardAddressChangedEvent']>, ParentType, ContextType, RequireFields<QueryRewardAddressChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pppconfigurationChangedEvent?: Resolver<Maybe<ResolversTypes['PPPConfigurationChangedEvent']>, ParentType, ContextType, RequireFields<QueryPppconfigurationChangedEventArgs, 'id' | 'subgraphError'>>;
  pppconfigurationChangedEvents?: Resolver<Array<ResolversTypes['PPPConfigurationChangedEvent']>, ParentType, ContextType, RequireFields<QueryPppconfigurationChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenMinimumDepositChangedEvent?: Resolver<Maybe<ResolversTypes['SuperTokenMinimumDepositChangedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenMinimumDepositChangedEventArgs, 'id' | 'subgraphError'>>;
  superTokenMinimumDepositChangedEvents?: Resolver<Array<ResolversTypes['SuperTokenMinimumDepositChangedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenMinimumDepositChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  trustedForwarderChangedEvent?: Resolver<Maybe<ResolversTypes['TrustedForwarderChangedEvent']>, ParentType, ContextType, RequireFields<QueryTrustedForwarderChangedEventArgs, 'id' | 'subgraphError'>>;
  trustedForwarderChangedEvents?: Resolver<Array<ResolversTypes['TrustedForwarderChangedEvent']>, ParentType, ContextType, RequireFields<QueryTrustedForwarderChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementLiquidatedByEvent?: Resolver<Maybe<ResolversTypes['AgreementLiquidatedByEvent']>, ParentType, ContextType, RequireFields<QueryAgreementLiquidatedByEventArgs, 'id' | 'subgraphError'>>;
  agreementLiquidatedByEvents?: Resolver<Array<ResolversTypes['AgreementLiquidatedByEvent']>, ParentType, ContextType, RequireFields<QueryAgreementLiquidatedByEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementLiquidatedV2Event?: Resolver<Maybe<ResolversTypes['AgreementLiquidatedV2Event']>, ParentType, ContextType, RequireFields<QueryAgreementLiquidatedV2EventArgs, 'id' | 'subgraphError'>>;
  agreementLiquidatedV2Events?: Resolver<Array<ResolversTypes['AgreementLiquidatedV2Event']>, ParentType, ContextType, RequireFields<QueryAgreementLiquidatedV2EventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  burnedEvent?: Resolver<Maybe<ResolversTypes['BurnedEvent']>, ParentType, ContextType, RequireFields<QueryBurnedEventArgs, 'id' | 'subgraphError'>>;
  burnedEvents?: Resolver<Array<ResolversTypes['BurnedEvent']>, ParentType, ContextType, RequireFields<QueryBurnedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  mintedEvent?: Resolver<Maybe<ResolversTypes['MintedEvent']>, ParentType, ContextType, RequireFields<QueryMintedEventArgs, 'id' | 'subgraphError'>>;
  mintedEvents?: Resolver<Array<ResolversTypes['MintedEvent']>, ParentType, ContextType, RequireFields<QueryMintedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sentEvent?: Resolver<Maybe<ResolversTypes['SentEvent']>, ParentType, ContextType, RequireFields<QuerySentEventArgs, 'id' | 'subgraphError'>>;
  sentEvents?: Resolver<Array<ResolversTypes['SentEvent']>, ParentType, ContextType, RequireFields<QuerySentEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transferEvent?: Resolver<Maybe<ResolversTypes['TransferEvent']>, ParentType, ContextType, RequireFields<QueryTransferEventArgs, 'id' | 'subgraphError'>>;
  transferEvents?: Resolver<Array<ResolversTypes['TransferEvent']>, ParentType, ContextType, RequireFields<QueryTransferEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenDowngradedEvent?: Resolver<Maybe<ResolversTypes['TokenDowngradedEvent']>, ParentType, ContextType, RequireFields<QueryTokenDowngradedEventArgs, 'id' | 'subgraphError'>>;
  tokenDowngradedEvents?: Resolver<Array<ResolversTypes['TokenDowngradedEvent']>, ParentType, ContextType, RequireFields<QueryTokenDowngradedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenUpgradedEvent?: Resolver<Maybe<ResolversTypes['TokenUpgradedEvent']>, ParentType, ContextType, RequireFields<QueryTokenUpgradedEventArgs, 'id' | 'subgraphError'>>;
  tokenUpgradedEvents?: Resolver<Array<ResolversTypes['TokenUpgradedEvent']>, ParentType, ContextType, RequireFields<QueryTokenUpgradedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  approvalEvent?: Resolver<Maybe<ResolversTypes['ApprovalEvent']>, ParentType, ContextType, RequireFields<QueryApprovalEventArgs, 'id' | 'subgraphError'>>;
  approvalEvents?: Resolver<Array<ResolversTypes['ApprovalEvent']>, ParentType, ContextType, RequireFields<QueryApprovalEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  customSuperTokenCreatedEvent?: Resolver<Maybe<ResolversTypes['CustomSuperTokenCreatedEvent']>, ParentType, ContextType, RequireFields<QueryCustomSuperTokenCreatedEventArgs, 'id' | 'subgraphError'>>;
  customSuperTokenCreatedEvents?: Resolver<Array<ResolversTypes['CustomSuperTokenCreatedEvent']>, ParentType, ContextType, RequireFields<QueryCustomSuperTokenCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenCreatedEvent?: Resolver<Maybe<ResolversTypes['SuperTokenCreatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenCreatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenCreatedEvents?: Resolver<Array<ResolversTypes['SuperTokenCreatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenLogicCreatedEvent?: Resolver<Maybe<ResolversTypes['SuperTokenLogicCreatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenLogicCreatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenLogicCreatedEvents?: Resolver<Array<ResolversTypes['SuperTokenLogicCreatedEvent']>, ParentType, ContextType, RequireFields<QuerySuperTokenLogicCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  newPICEvent?: Resolver<Maybe<ResolversTypes['NewPICEvent']>, ParentType, ContextType, RequireFields<QueryNewPicEventArgs, 'id' | 'subgraphError'>>;
  newPICEvents?: Resolver<Array<ResolversTypes['NewPICEvent']>, ParentType, ContextType, RequireFields<QueryNewPicEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  exitRateChangedEvent?: Resolver<Maybe<ResolversTypes['ExitRateChangedEvent']>, ParentType, ContextType, RequireFields<QueryExitRateChangedEventArgs, 'id' | 'subgraphError'>>;
  exitRateChangedEvents?: Resolver<Array<ResolversTypes['ExitRateChangedEvent']>, ParentType, ContextType, RequireFields<QueryExitRateChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  bondIncreasedEvent?: Resolver<Maybe<ResolversTypes['BondIncreasedEvent']>, ParentType, ContextType, RequireFields<QueryBondIncreasedEventArgs, 'id' | 'subgraphError'>>;
  bondIncreasedEvents?: Resolver<Array<ResolversTypes['BondIncreasedEvent']>, ParentType, ContextType, RequireFields<QueryBondIncreasedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryAccountArgs, 'id' | 'subgraphError'>>;
  accounts?: Resolver<Array<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pool?: Resolver<Maybe<ResolversTypes['Pool']>, ParentType, ContextType, RequireFields<QueryPoolArgs, 'id' | 'subgraphError'>>;
  pools?: Resolver<Array<ResolversTypes['Pool']>, ParentType, ContextType, RequireFields<QueryPoolsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolMember?: Resolver<Maybe<ResolversTypes['PoolMember']>, ParentType, ContextType, RequireFields<QueryPoolMemberArgs, 'id' | 'subgraphError'>>;
  poolMembers?: Resolver<Array<ResolversTypes['PoolMember']>, ParentType, ContextType, RequireFields<QueryPoolMembersArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolDistributor?: Resolver<Maybe<ResolversTypes['PoolDistributor']>, ParentType, ContextType, RequireFields<QueryPoolDistributorArgs, 'id' | 'subgraphError'>>;
  poolDistributors?: Resolver<Array<ResolversTypes['PoolDistributor']>, ParentType, ContextType, RequireFields<QueryPoolDistributorsArgs, 'skip' | 'first' | 'subgraphError'>>;
  index?: Resolver<Maybe<ResolversTypes['Index']>, ParentType, ContextType, RequireFields<QueryIndexArgs, 'id' | 'subgraphError'>>;
  indexes?: Resolver<Array<ResolversTypes['Index']>, ParentType, ContextType, RequireFields<QueryIndexesArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexSubscription?: Resolver<Maybe<ResolversTypes['IndexSubscription']>, ParentType, ContextType, RequireFields<QueryIndexSubscriptionArgs, 'id' | 'subgraphError'>>;
  indexSubscriptions?: Resolver<Array<ResolversTypes['IndexSubscription']>, ParentType, ContextType, RequireFields<QueryIndexSubscriptionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QueryStreamArgs, 'id' | 'subgraphError'>>;
  streams?: Resolver<Array<ResolversTypes['Stream']>, ParentType, ContextType, RequireFields<QueryStreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowOperator?: Resolver<Maybe<ResolversTypes['FlowOperator']>, ParentType, ContextType, RequireFields<QueryFlowOperatorArgs, 'id' | 'subgraphError'>>;
  flowOperators?: Resolver<Array<ResolversTypes['FlowOperator']>, ParentType, ContextType, RequireFields<QueryFlowOperatorsArgs, 'skip' | 'first' | 'subgraphError'>>;
  streamPeriod?: Resolver<Maybe<ResolversTypes['StreamPeriod']>, ParentType, ContextType, RequireFields<QueryStreamPeriodArgs, 'id' | 'subgraphError'>>;
  streamPeriods?: Resolver<Array<ResolversTypes['StreamPeriod']>, ParentType, ContextType, RequireFields<QueryStreamPeriodsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenGovernanceConfig?: Resolver<Maybe<ResolversTypes['TokenGovernanceConfig']>, ParentType, ContextType, RequireFields<QueryTokenGovernanceConfigArgs, 'id' | 'subgraphError'>>;
  tokenGovernanceConfigs?: Resolver<Array<ResolversTypes['TokenGovernanceConfig']>, ParentType, ContextType, RequireFields<QueryTokenGovernanceConfigsArgs, 'skip' | 'first' | 'subgraphError'>>;
  streamRevision?: Resolver<Maybe<ResolversTypes['StreamRevision']>, ParentType, ContextType, RequireFields<QueryStreamRevisionArgs, 'id' | 'subgraphError'>>;
  streamRevisions?: Resolver<Array<ResolversTypes['StreamRevision']>, ParentType, ContextType, RequireFields<QueryStreamRevisionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QueryTokenArgs, 'id' | 'subgraphError'>>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QueryTokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  resolverEntry?: Resolver<Maybe<ResolversTypes['ResolverEntry']>, ParentType, ContextType, RequireFields<QueryResolverEntryArgs, 'id' | 'subgraphError'>>;
  resolverEntries?: Resolver<Array<ResolversTypes['ResolverEntry']>, ParentType, ContextType, RequireFields<QueryResolverEntriesArgs, 'skip' | 'first' | 'subgraphError'>>;
  accountTokenSnapshot?: Resolver<Maybe<ResolversTypes['AccountTokenSnapshot']>, ParentType, ContextType, RequireFields<QueryAccountTokenSnapshotArgs, 'id' | 'subgraphError'>>;
  accountTokenSnapshots?: Resolver<Array<ResolversTypes['AccountTokenSnapshot']>, ParentType, ContextType, RequireFields<QueryAccountTokenSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  accountTokenSnapshotLog?: Resolver<Maybe<ResolversTypes['AccountTokenSnapshotLog']>, ParentType, ContextType, RequireFields<QueryAccountTokenSnapshotLogArgs, 'id' | 'subgraphError'>>;
  accountTokenSnapshotLogs?: Resolver<Array<ResolversTypes['AccountTokenSnapshotLog']>, ParentType, ContextType, RequireFields<QueryAccountTokenSnapshotLogsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenStatistic?: Resolver<Maybe<ResolversTypes['TokenStatistic']>, ParentType, ContextType, RequireFields<QueryTokenStatisticArgs, 'id' | 'subgraphError'>>;
  tokenStatistics?: Resolver<Array<ResolversTypes['TokenStatistic']>, ParentType, ContextType, RequireFields<QueryTokenStatisticsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenStatisticLog?: Resolver<Maybe<ResolversTypes['TokenStatisticLog']>, ParentType, ContextType, RequireFields<QueryTokenStatisticLogArgs, 'id' | 'subgraphError'>>;
  tokenStatisticLogs?: Resolver<Array<ResolversTypes['TokenStatisticLog']>, ParentType, ContextType, RequireFields<QueryTokenStatisticLogsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sfmeta?: Resolver<Maybe<ResolversTypes['SFMeta']>, ParentType, ContextType, RequireFields<QuerySfmetaArgs, 'id' | 'subgraphError'>>;
  sfmetas?: Resolver<Array<ResolversTypes['SFMeta']>, ParentType, ContextType, RequireFields<QuerySfmetasArgs, 'skip' | 'first' | 'subgraphError'>>;
  event?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryEventArgs, 'id' | 'subgraphError'>>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_MetaArgs>>;
}>;

export type ResolverEntryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ResolverEntry'] = ResolversParentTypes['ResolverEntry']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  targetAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isListed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  setEvents?: Resolver<Array<ResolversTypes['SetEvent']>, ParentType, ContextType, RequireFields<ResolverEntrySetEventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RewardAddressChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RewardAddressChangedEvent'] = ResolversParentTypes['RewardAddressChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rewardAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleAdminChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RoleAdminChangedEvent'] = ResolversParentTypes['RoleAdminChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  previousAdminRole?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  newAdminRole?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleGrantedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RoleGrantedEvent'] = ResolversParentTypes['RoleGrantedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleRevokedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RoleRevokedEvent'] = ResolversParentTypes['RoleRevokedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SfMetaResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SFMeta'] = ResolversParentTypes['SFMeta']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  configuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SentEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SentEvent'] = ResolversParentTypes['SentEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  operatorData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SetEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SetEvent'] = ResolversParentTypes['SetEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  hashedName?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  resolverEntry?: Resolver<ResolversTypes['ResolverEntry'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Stream'] = ResolversParentTypes['Stream']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  currentFlowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  streamedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  flowUpdatedEvents?: Resolver<Array<ResolversTypes['FlowUpdatedEvent']>, ParentType, ContextType, RequireFields<StreamFlowUpdatedEventsArgs, 'skip' | 'first'>>;
  streamPeriods?: Resolver<Array<ResolversTypes['StreamPeriod']>, ParentType, ContextType, RequireFields<StreamStreamPeriodsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamPeriodResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['StreamPeriod'] = ResolversParentTypes['StreamPeriod']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  flowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startedAtEvent?: Resolver<ResolversTypes['FlowUpdatedEvent'], ParentType, ContextType>;
  stoppedAtTimestamp?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  stoppedAtBlockNumber?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  stoppedAtEvent?: Resolver<Maybe<ResolversTypes['FlowUpdatedEvent']>, ParentType, ContextType>;
  totalAmountStreamed?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamRevisionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['StreamRevision'] = ResolversParentTypes['StreamRevision']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  revisionIndex?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  periodRevisionIndex?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mostRecentStream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  flowUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['FlowUpdatedEvent']>, "flowUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionFlowUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['FlowUpdatedEvent']>, "flowUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionFlowUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowOperatorUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['FlowOperatorUpdatedEvent']>, "flowOperatorUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionFlowOperatorUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowOperatorUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['FlowOperatorUpdatedEvent']>, "flowOperatorUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionFlowOperatorUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexCreatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexCreatedEvent']>, "indexCreatedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexCreatedEventArgs, 'id' | 'subgraphError'>>;
  indexCreatedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexCreatedEvent']>, "indexCreatedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexDistributionClaimedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexDistributionClaimedEvent']>, "indexDistributionClaimedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  indexDistributionClaimedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexDistributionClaimedEvent']>, "indexDistributionClaimedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexUpdatedEvent']>, "indexUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexUpdatedEventArgs, 'id' | 'subgraphError'>>;
  indexUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexUpdatedEvent']>, "indexUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexSubscribedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexSubscribedEvent']>, "indexSubscribedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexSubscribedEventArgs, 'id' | 'subgraphError'>>;
  indexSubscribedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexSubscribedEvent']>, "indexSubscribedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexSubscribedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUnitsUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexUnitsUpdatedEvent']>, "indexUnitsUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  indexUnitsUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexUnitsUpdatedEvent']>, "indexUnitsUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexUnsubscribedEvent?: SubscriptionResolver<Maybe<ResolversTypes['IndexUnsubscribedEvent']>, "indexUnsubscribedEvent", ParentType, ContextType, RequireFields<SubscriptionIndexUnsubscribedEventArgs, 'id' | 'subgraphError'>>;
  indexUnsubscribedEvents?: SubscriptionResolver<Array<ResolversTypes['IndexUnsubscribedEvent']>, "indexUnsubscribedEvents", ParentType, ContextType, RequireFields<SubscriptionIndexUnsubscribedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionApprovedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionApprovedEvent']>, "subscriptionApprovedEvent", ParentType, ContextType, RequireFields<SubscriptionSubscriptionApprovedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionApprovedEvents?: SubscriptionResolver<Array<ResolversTypes['SubscriptionApprovedEvent']>, "subscriptionApprovedEvents", ParentType, ContextType, RequireFields<SubscriptionSubscriptionApprovedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionDistributionClaimedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionDistributionClaimedEvent']>, "subscriptionDistributionClaimedEvent", ParentType, ContextType, RequireFields<SubscriptionSubscriptionDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionDistributionClaimedEvents?: SubscriptionResolver<Array<ResolversTypes['SubscriptionDistributionClaimedEvent']>, "subscriptionDistributionClaimedEvents", ParentType, ContextType, RequireFields<SubscriptionSubscriptionDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionRevokedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionRevokedEvent']>, "subscriptionRevokedEvent", ParentType, ContextType, RequireFields<SubscriptionSubscriptionRevokedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionRevokedEvents?: SubscriptionResolver<Array<ResolversTypes['SubscriptionRevokedEvent']>, "subscriptionRevokedEvents", ParentType, ContextType, RequireFields<SubscriptionSubscriptionRevokedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  subscriptionUnitsUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionUnitsUpdatedEvent']>, "subscriptionUnitsUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionSubscriptionUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  subscriptionUnitsUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['SubscriptionUnitsUpdatedEvent']>, "subscriptionUnitsUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionSubscriptionUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolCreatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['PoolCreatedEvent']>, "poolCreatedEvent", ParentType, ContextType, RequireFields<SubscriptionPoolCreatedEventArgs, 'id' | 'subgraphError'>>;
  poolCreatedEvents?: SubscriptionResolver<Array<ResolversTypes['PoolCreatedEvent']>, "poolCreatedEvents", ParentType, ContextType, RequireFields<SubscriptionPoolCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolConnectionUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['PoolConnectionUpdatedEvent']>, "poolConnectionUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionPoolConnectionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  poolConnectionUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['PoolConnectionUpdatedEvent']>, "poolConnectionUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionPoolConnectionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  bufferAdjustedEvent?: SubscriptionResolver<Maybe<ResolversTypes['BufferAdjustedEvent']>, "bufferAdjustedEvent", ParentType, ContextType, RequireFields<SubscriptionBufferAdjustedEventArgs, 'id' | 'subgraphError'>>;
  bufferAdjustedEvents?: SubscriptionResolver<Array<ResolversTypes['BufferAdjustedEvent']>, "bufferAdjustedEvents", ParentType, ContextType, RequireFields<SubscriptionBufferAdjustedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  instantDistributionUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['InstantDistributionUpdatedEvent']>, "instantDistributionUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionInstantDistributionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  instantDistributionUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['InstantDistributionUpdatedEvent']>, "instantDistributionUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionInstantDistributionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowDistributionUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['FlowDistributionUpdatedEvent']>, "flowDistributionUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionFlowDistributionUpdatedEventArgs, 'id' | 'subgraphError'>>;
  flowDistributionUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['FlowDistributionUpdatedEvent']>, "flowDistributionUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionFlowDistributionUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  distributionClaimedEvent?: SubscriptionResolver<Maybe<ResolversTypes['DistributionClaimedEvent']>, "distributionClaimedEvent", ParentType, ContextType, RequireFields<SubscriptionDistributionClaimedEventArgs, 'id' | 'subgraphError'>>;
  distributionClaimedEvents?: SubscriptionResolver<Array<ResolversTypes['DistributionClaimedEvent']>, "distributionClaimedEvents", ParentType, ContextType, RequireFields<SubscriptionDistributionClaimedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  memberUnitsUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['MemberUnitsUpdatedEvent']>, "memberUnitsUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionMemberUnitsUpdatedEventArgs, 'id' | 'subgraphError'>>;
  memberUnitsUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['MemberUnitsUpdatedEvent']>, "memberUnitsUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionMemberUnitsUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementClassRegisteredEvent?: SubscriptionResolver<Maybe<ResolversTypes['AgreementClassRegisteredEvent']>, "agreementClassRegisteredEvent", ParentType, ContextType, RequireFields<SubscriptionAgreementClassRegisteredEventArgs, 'id' | 'subgraphError'>>;
  agreementClassRegisteredEvents?: SubscriptionResolver<Array<ResolversTypes['AgreementClassRegisteredEvent']>, "agreementClassRegisteredEvents", ParentType, ContextType, RequireFields<SubscriptionAgreementClassRegisteredEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementClassUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['AgreementClassUpdatedEvent']>, "agreementClassUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionAgreementClassUpdatedEventArgs, 'id' | 'subgraphError'>>;
  agreementClassUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['AgreementClassUpdatedEvent']>, "agreementClassUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionAgreementClassUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  appRegisteredEvent?: SubscriptionResolver<Maybe<ResolversTypes['AppRegisteredEvent']>, "appRegisteredEvent", ParentType, ContextType, RequireFields<SubscriptionAppRegisteredEventArgs, 'id' | 'subgraphError'>>;
  appRegisteredEvents?: SubscriptionResolver<Array<ResolversTypes['AppRegisteredEvent']>, "appRegisteredEvents", ParentType, ContextType, RequireFields<SubscriptionAppRegisteredEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  governanceReplacedEvent?: SubscriptionResolver<Maybe<ResolversTypes['GovernanceReplacedEvent']>, "governanceReplacedEvent", ParentType, ContextType, RequireFields<SubscriptionGovernanceReplacedEventArgs, 'id' | 'subgraphError'>>;
  governanceReplacedEvents?: SubscriptionResolver<Array<ResolversTypes['GovernanceReplacedEvent']>, "governanceReplacedEvents", ParentType, ContextType, RequireFields<SubscriptionGovernanceReplacedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  jailEvent?: SubscriptionResolver<Maybe<ResolversTypes['JailEvent']>, "jailEvent", ParentType, ContextType, RequireFields<SubscriptionJailEventArgs, 'id' | 'subgraphError'>>;
  jailEvents?: SubscriptionResolver<Array<ResolversTypes['JailEvent']>, "jailEvents", ParentType, ContextType, RequireFields<SubscriptionJailEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenFactoryUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SuperTokenFactoryUpdatedEvent']>, "superTokenFactoryUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionSuperTokenFactoryUpdatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenFactoryUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['SuperTokenFactoryUpdatedEvent']>, "superTokenFactoryUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionSuperTokenFactoryUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenLogicUpdatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SuperTokenLogicUpdatedEvent']>, "superTokenLogicUpdatedEvent", ParentType, ContextType, RequireFields<SubscriptionSuperTokenLogicUpdatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenLogicUpdatedEvents?: SubscriptionResolver<Array<ResolversTypes['SuperTokenLogicUpdatedEvent']>, "superTokenLogicUpdatedEvents", ParentType, ContextType, RequireFields<SubscriptionSuperTokenLogicUpdatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleAdminChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['RoleAdminChangedEvent']>, "roleAdminChangedEvent", ParentType, ContextType, RequireFields<SubscriptionRoleAdminChangedEventArgs, 'id' | 'subgraphError'>>;
  roleAdminChangedEvents?: SubscriptionResolver<Array<ResolversTypes['RoleAdminChangedEvent']>, "roleAdminChangedEvents", ParentType, ContextType, RequireFields<SubscriptionRoleAdminChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleGrantedEvent?: SubscriptionResolver<Maybe<ResolversTypes['RoleGrantedEvent']>, "roleGrantedEvent", ParentType, ContextType, RequireFields<SubscriptionRoleGrantedEventArgs, 'id' | 'subgraphError'>>;
  roleGrantedEvents?: SubscriptionResolver<Array<ResolversTypes['RoleGrantedEvent']>, "roleGrantedEvents", ParentType, ContextType, RequireFields<SubscriptionRoleGrantedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  roleRevokedEvent?: SubscriptionResolver<Maybe<ResolversTypes['RoleRevokedEvent']>, "roleRevokedEvent", ParentType, ContextType, RequireFields<SubscriptionRoleRevokedEventArgs, 'id' | 'subgraphError'>>;
  roleRevokedEvents?: SubscriptionResolver<Array<ResolversTypes['RoleRevokedEvent']>, "roleRevokedEvents", ParentType, ContextType, RequireFields<SubscriptionRoleRevokedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  setEvent?: SubscriptionResolver<Maybe<ResolversTypes['SetEvent']>, "setEvent", ParentType, ContextType, RequireFields<SubscriptionSetEventArgs, 'id' | 'subgraphError'>>;
  setEvents?: SubscriptionResolver<Array<ResolversTypes['SetEvent']>, "setEvents", ParentType, ContextType, RequireFields<SubscriptionSetEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  cfav1LiquidationPeriodChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['CFAv1LiquidationPeriodChangedEvent']>, "cfav1LiquidationPeriodChangedEvent", ParentType, ContextType, RequireFields<SubscriptionCfav1LiquidationPeriodChangedEventArgs, 'id' | 'subgraphError'>>;
  cfav1LiquidationPeriodChangedEvents?: SubscriptionResolver<Array<ResolversTypes['CFAv1LiquidationPeriodChangedEvent']>, "cfav1LiquidationPeriodChangedEvents", ParentType, ContextType, RequireFields<SubscriptionCfav1LiquidationPeriodChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  configChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['ConfigChangedEvent']>, "configChangedEvent", ParentType, ContextType, RequireFields<SubscriptionConfigChangedEventArgs, 'id' | 'subgraphError'>>;
  configChangedEvents?: SubscriptionResolver<Array<ResolversTypes['ConfigChangedEvent']>, "configChangedEvents", ParentType, ContextType, RequireFields<SubscriptionConfigChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  rewardAddressChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['RewardAddressChangedEvent']>, "rewardAddressChangedEvent", ParentType, ContextType, RequireFields<SubscriptionRewardAddressChangedEventArgs, 'id' | 'subgraphError'>>;
  rewardAddressChangedEvents?: SubscriptionResolver<Array<ResolversTypes['RewardAddressChangedEvent']>, "rewardAddressChangedEvents", ParentType, ContextType, RequireFields<SubscriptionRewardAddressChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pppconfigurationChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['PPPConfigurationChangedEvent']>, "pppconfigurationChangedEvent", ParentType, ContextType, RequireFields<SubscriptionPppconfigurationChangedEventArgs, 'id' | 'subgraphError'>>;
  pppconfigurationChangedEvents?: SubscriptionResolver<Array<ResolversTypes['PPPConfigurationChangedEvent']>, "pppconfigurationChangedEvents", ParentType, ContextType, RequireFields<SubscriptionPppconfigurationChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenMinimumDepositChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SuperTokenMinimumDepositChangedEvent']>, "superTokenMinimumDepositChangedEvent", ParentType, ContextType, RequireFields<SubscriptionSuperTokenMinimumDepositChangedEventArgs, 'id' | 'subgraphError'>>;
  superTokenMinimumDepositChangedEvents?: SubscriptionResolver<Array<ResolversTypes['SuperTokenMinimumDepositChangedEvent']>, "superTokenMinimumDepositChangedEvents", ParentType, ContextType, RequireFields<SubscriptionSuperTokenMinimumDepositChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  trustedForwarderChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['TrustedForwarderChangedEvent']>, "trustedForwarderChangedEvent", ParentType, ContextType, RequireFields<SubscriptionTrustedForwarderChangedEventArgs, 'id' | 'subgraphError'>>;
  trustedForwarderChangedEvents?: SubscriptionResolver<Array<ResolversTypes['TrustedForwarderChangedEvent']>, "trustedForwarderChangedEvents", ParentType, ContextType, RequireFields<SubscriptionTrustedForwarderChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementLiquidatedByEvent?: SubscriptionResolver<Maybe<ResolversTypes['AgreementLiquidatedByEvent']>, "agreementLiquidatedByEvent", ParentType, ContextType, RequireFields<SubscriptionAgreementLiquidatedByEventArgs, 'id' | 'subgraphError'>>;
  agreementLiquidatedByEvents?: SubscriptionResolver<Array<ResolversTypes['AgreementLiquidatedByEvent']>, "agreementLiquidatedByEvents", ParentType, ContextType, RequireFields<SubscriptionAgreementLiquidatedByEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  agreementLiquidatedV2Event?: SubscriptionResolver<Maybe<ResolversTypes['AgreementLiquidatedV2Event']>, "agreementLiquidatedV2Event", ParentType, ContextType, RequireFields<SubscriptionAgreementLiquidatedV2EventArgs, 'id' | 'subgraphError'>>;
  agreementLiquidatedV2Events?: SubscriptionResolver<Array<ResolversTypes['AgreementLiquidatedV2Event']>, "agreementLiquidatedV2Events", ParentType, ContextType, RequireFields<SubscriptionAgreementLiquidatedV2EventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  burnedEvent?: SubscriptionResolver<Maybe<ResolversTypes['BurnedEvent']>, "burnedEvent", ParentType, ContextType, RequireFields<SubscriptionBurnedEventArgs, 'id' | 'subgraphError'>>;
  burnedEvents?: SubscriptionResolver<Array<ResolversTypes['BurnedEvent']>, "burnedEvents", ParentType, ContextType, RequireFields<SubscriptionBurnedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  mintedEvent?: SubscriptionResolver<Maybe<ResolversTypes['MintedEvent']>, "mintedEvent", ParentType, ContextType, RequireFields<SubscriptionMintedEventArgs, 'id' | 'subgraphError'>>;
  mintedEvents?: SubscriptionResolver<Array<ResolversTypes['MintedEvent']>, "mintedEvents", ParentType, ContextType, RequireFields<SubscriptionMintedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sentEvent?: SubscriptionResolver<Maybe<ResolversTypes['SentEvent']>, "sentEvent", ParentType, ContextType, RequireFields<SubscriptionSentEventArgs, 'id' | 'subgraphError'>>;
  sentEvents?: SubscriptionResolver<Array<ResolversTypes['SentEvent']>, "sentEvents", ParentType, ContextType, RequireFields<SubscriptionSentEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transferEvent?: SubscriptionResolver<Maybe<ResolversTypes['TransferEvent']>, "transferEvent", ParentType, ContextType, RequireFields<SubscriptionTransferEventArgs, 'id' | 'subgraphError'>>;
  transferEvents?: SubscriptionResolver<Array<ResolversTypes['TransferEvent']>, "transferEvents", ParentType, ContextType, RequireFields<SubscriptionTransferEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenDowngradedEvent?: SubscriptionResolver<Maybe<ResolversTypes['TokenDowngradedEvent']>, "tokenDowngradedEvent", ParentType, ContextType, RequireFields<SubscriptionTokenDowngradedEventArgs, 'id' | 'subgraphError'>>;
  tokenDowngradedEvents?: SubscriptionResolver<Array<ResolversTypes['TokenDowngradedEvent']>, "tokenDowngradedEvents", ParentType, ContextType, RequireFields<SubscriptionTokenDowngradedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenUpgradedEvent?: SubscriptionResolver<Maybe<ResolversTypes['TokenUpgradedEvent']>, "tokenUpgradedEvent", ParentType, ContextType, RequireFields<SubscriptionTokenUpgradedEventArgs, 'id' | 'subgraphError'>>;
  tokenUpgradedEvents?: SubscriptionResolver<Array<ResolversTypes['TokenUpgradedEvent']>, "tokenUpgradedEvents", ParentType, ContextType, RequireFields<SubscriptionTokenUpgradedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  approvalEvent?: SubscriptionResolver<Maybe<ResolversTypes['ApprovalEvent']>, "approvalEvent", ParentType, ContextType, RequireFields<SubscriptionApprovalEventArgs, 'id' | 'subgraphError'>>;
  approvalEvents?: SubscriptionResolver<Array<ResolversTypes['ApprovalEvent']>, "approvalEvents", ParentType, ContextType, RequireFields<SubscriptionApprovalEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  customSuperTokenCreatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['CustomSuperTokenCreatedEvent']>, "customSuperTokenCreatedEvent", ParentType, ContextType, RequireFields<SubscriptionCustomSuperTokenCreatedEventArgs, 'id' | 'subgraphError'>>;
  customSuperTokenCreatedEvents?: SubscriptionResolver<Array<ResolversTypes['CustomSuperTokenCreatedEvent']>, "customSuperTokenCreatedEvents", ParentType, ContextType, RequireFields<SubscriptionCustomSuperTokenCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenCreatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SuperTokenCreatedEvent']>, "superTokenCreatedEvent", ParentType, ContextType, RequireFields<SubscriptionSuperTokenCreatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenCreatedEvents?: SubscriptionResolver<Array<ResolversTypes['SuperTokenCreatedEvent']>, "superTokenCreatedEvents", ParentType, ContextType, RequireFields<SubscriptionSuperTokenCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  superTokenLogicCreatedEvent?: SubscriptionResolver<Maybe<ResolversTypes['SuperTokenLogicCreatedEvent']>, "superTokenLogicCreatedEvent", ParentType, ContextType, RequireFields<SubscriptionSuperTokenLogicCreatedEventArgs, 'id' | 'subgraphError'>>;
  superTokenLogicCreatedEvents?: SubscriptionResolver<Array<ResolversTypes['SuperTokenLogicCreatedEvent']>, "superTokenLogicCreatedEvents", ParentType, ContextType, RequireFields<SubscriptionSuperTokenLogicCreatedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  newPICEvent?: SubscriptionResolver<Maybe<ResolversTypes['NewPICEvent']>, "newPICEvent", ParentType, ContextType, RequireFields<SubscriptionNewPicEventArgs, 'id' | 'subgraphError'>>;
  newPICEvents?: SubscriptionResolver<Array<ResolversTypes['NewPICEvent']>, "newPICEvents", ParentType, ContextType, RequireFields<SubscriptionNewPicEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  exitRateChangedEvent?: SubscriptionResolver<Maybe<ResolversTypes['ExitRateChangedEvent']>, "exitRateChangedEvent", ParentType, ContextType, RequireFields<SubscriptionExitRateChangedEventArgs, 'id' | 'subgraphError'>>;
  exitRateChangedEvents?: SubscriptionResolver<Array<ResolversTypes['ExitRateChangedEvent']>, "exitRateChangedEvents", ParentType, ContextType, RequireFields<SubscriptionExitRateChangedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  bondIncreasedEvent?: SubscriptionResolver<Maybe<ResolversTypes['BondIncreasedEvent']>, "bondIncreasedEvent", ParentType, ContextType, RequireFields<SubscriptionBondIncreasedEventArgs, 'id' | 'subgraphError'>>;
  bondIncreasedEvents?: SubscriptionResolver<Array<ResolversTypes['BondIncreasedEvent']>, "bondIncreasedEvents", ParentType, ContextType, RequireFields<SubscriptionBondIncreasedEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  account?: SubscriptionResolver<Maybe<ResolversTypes['Account']>, "account", ParentType, ContextType, RequireFields<SubscriptionAccountArgs, 'id' | 'subgraphError'>>;
  accounts?: SubscriptionResolver<Array<ResolversTypes['Account']>, "accounts", ParentType, ContextType, RequireFields<SubscriptionAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pool?: SubscriptionResolver<Maybe<ResolversTypes['Pool']>, "pool", ParentType, ContextType, RequireFields<SubscriptionPoolArgs, 'id' | 'subgraphError'>>;
  pools?: SubscriptionResolver<Array<ResolversTypes['Pool']>, "pools", ParentType, ContextType, RequireFields<SubscriptionPoolsArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolMember?: SubscriptionResolver<Maybe<ResolversTypes['PoolMember']>, "poolMember", ParentType, ContextType, RequireFields<SubscriptionPoolMemberArgs, 'id' | 'subgraphError'>>;
  poolMembers?: SubscriptionResolver<Array<ResolversTypes['PoolMember']>, "poolMembers", ParentType, ContextType, RequireFields<SubscriptionPoolMembersArgs, 'skip' | 'first' | 'subgraphError'>>;
  poolDistributor?: SubscriptionResolver<Maybe<ResolversTypes['PoolDistributor']>, "poolDistributor", ParentType, ContextType, RequireFields<SubscriptionPoolDistributorArgs, 'id' | 'subgraphError'>>;
  poolDistributors?: SubscriptionResolver<Array<ResolversTypes['PoolDistributor']>, "poolDistributors", ParentType, ContextType, RequireFields<SubscriptionPoolDistributorsArgs, 'skip' | 'first' | 'subgraphError'>>;
  index?: SubscriptionResolver<Maybe<ResolversTypes['Index']>, "index", ParentType, ContextType, RequireFields<SubscriptionIndexArgs, 'id' | 'subgraphError'>>;
  indexes?: SubscriptionResolver<Array<ResolversTypes['Index']>, "indexes", ParentType, ContextType, RequireFields<SubscriptionIndexesArgs, 'skip' | 'first' | 'subgraphError'>>;
  indexSubscription?: SubscriptionResolver<Maybe<ResolversTypes['IndexSubscription']>, "indexSubscription", ParentType, ContextType, RequireFields<SubscriptionIndexSubscriptionArgs, 'id' | 'subgraphError'>>;
  indexSubscriptions?: SubscriptionResolver<Array<ResolversTypes['IndexSubscription']>, "indexSubscriptions", ParentType, ContextType, RequireFields<SubscriptionIndexSubscriptionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stream?: SubscriptionResolver<Maybe<ResolversTypes['Stream']>, "stream", ParentType, ContextType, RequireFields<SubscriptionStreamArgs, 'id' | 'subgraphError'>>;
  streams?: SubscriptionResolver<Array<ResolversTypes['Stream']>, "streams", ParentType, ContextType, RequireFields<SubscriptionStreamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  flowOperator?: SubscriptionResolver<Maybe<ResolversTypes['FlowOperator']>, "flowOperator", ParentType, ContextType, RequireFields<SubscriptionFlowOperatorArgs, 'id' | 'subgraphError'>>;
  flowOperators?: SubscriptionResolver<Array<ResolversTypes['FlowOperator']>, "flowOperators", ParentType, ContextType, RequireFields<SubscriptionFlowOperatorsArgs, 'skip' | 'first' | 'subgraphError'>>;
  streamPeriod?: SubscriptionResolver<Maybe<ResolversTypes['StreamPeriod']>, "streamPeriod", ParentType, ContextType, RequireFields<SubscriptionStreamPeriodArgs, 'id' | 'subgraphError'>>;
  streamPeriods?: SubscriptionResolver<Array<ResolversTypes['StreamPeriod']>, "streamPeriods", ParentType, ContextType, RequireFields<SubscriptionStreamPeriodsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenGovernanceConfig?: SubscriptionResolver<Maybe<ResolversTypes['TokenGovernanceConfig']>, "tokenGovernanceConfig", ParentType, ContextType, RequireFields<SubscriptionTokenGovernanceConfigArgs, 'id' | 'subgraphError'>>;
  tokenGovernanceConfigs?: SubscriptionResolver<Array<ResolversTypes['TokenGovernanceConfig']>, "tokenGovernanceConfigs", ParentType, ContextType, RequireFields<SubscriptionTokenGovernanceConfigsArgs, 'skip' | 'first' | 'subgraphError'>>;
  streamRevision?: SubscriptionResolver<Maybe<ResolversTypes['StreamRevision']>, "streamRevision", ParentType, ContextType, RequireFields<SubscriptionStreamRevisionArgs, 'id' | 'subgraphError'>>;
  streamRevisions?: SubscriptionResolver<Array<ResolversTypes['StreamRevision']>, "streamRevisions", ParentType, ContextType, RequireFields<SubscriptionStreamRevisionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: SubscriptionResolver<Maybe<ResolversTypes['Token']>, "token", ParentType, ContextType, RequireFields<SubscriptionTokenArgs, 'id' | 'subgraphError'>>;
  tokens?: SubscriptionResolver<Array<ResolversTypes['Token']>, "tokens", ParentType, ContextType, RequireFields<SubscriptionTokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  resolverEntry?: SubscriptionResolver<Maybe<ResolversTypes['ResolverEntry']>, "resolverEntry", ParentType, ContextType, RequireFields<SubscriptionResolverEntryArgs, 'id' | 'subgraphError'>>;
  resolverEntries?: SubscriptionResolver<Array<ResolversTypes['ResolverEntry']>, "resolverEntries", ParentType, ContextType, RequireFields<SubscriptionResolverEntriesArgs, 'skip' | 'first' | 'subgraphError'>>;
  accountTokenSnapshot?: SubscriptionResolver<Maybe<ResolversTypes['AccountTokenSnapshot']>, "accountTokenSnapshot", ParentType, ContextType, RequireFields<SubscriptionAccountTokenSnapshotArgs, 'id' | 'subgraphError'>>;
  accountTokenSnapshots?: SubscriptionResolver<Array<ResolversTypes['AccountTokenSnapshot']>, "accountTokenSnapshots", ParentType, ContextType, RequireFields<SubscriptionAccountTokenSnapshotsArgs, 'skip' | 'first' | 'subgraphError'>>;
  accountTokenSnapshotLog?: SubscriptionResolver<Maybe<ResolversTypes['AccountTokenSnapshotLog']>, "accountTokenSnapshotLog", ParentType, ContextType, RequireFields<SubscriptionAccountTokenSnapshotLogArgs, 'id' | 'subgraphError'>>;
  accountTokenSnapshotLogs?: SubscriptionResolver<Array<ResolversTypes['AccountTokenSnapshotLog']>, "accountTokenSnapshotLogs", ParentType, ContextType, RequireFields<SubscriptionAccountTokenSnapshotLogsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenStatistic?: SubscriptionResolver<Maybe<ResolversTypes['TokenStatistic']>, "tokenStatistic", ParentType, ContextType, RequireFields<SubscriptionTokenStatisticArgs, 'id' | 'subgraphError'>>;
  tokenStatistics?: SubscriptionResolver<Array<ResolversTypes['TokenStatistic']>, "tokenStatistics", ParentType, ContextType, RequireFields<SubscriptionTokenStatisticsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenStatisticLog?: SubscriptionResolver<Maybe<ResolversTypes['TokenStatisticLog']>, "tokenStatisticLog", ParentType, ContextType, RequireFields<SubscriptionTokenStatisticLogArgs, 'id' | 'subgraphError'>>;
  tokenStatisticLogs?: SubscriptionResolver<Array<ResolversTypes['TokenStatisticLog']>, "tokenStatisticLogs", ParentType, ContextType, RequireFields<SubscriptionTokenStatisticLogsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sfmeta?: SubscriptionResolver<Maybe<ResolversTypes['SFMeta']>, "sfmeta", ParentType, ContextType, RequireFields<SubscriptionSfmetaArgs, 'id' | 'subgraphError'>>;
  sfmetas?: SubscriptionResolver<Array<ResolversTypes['SFMeta']>, "sfmetas", ParentType, ContextType, RequireFields<SubscriptionSfmetasArgs, 'skip' | 'first' | 'subgraphError'>>;
  event?: SubscriptionResolver<Maybe<ResolversTypes['Event']>, "event", ParentType, ContextType, RequireFields<SubscriptionEventArgs, 'id' | 'subgraphError'>>;
  events?: SubscriptionResolver<Array<ResolversTypes['Event']>, "events", ParentType, ContextType, RequireFields<SubscriptionEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_MetaArgs>>;
}>;

export type SubscriptionApprovedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SubscriptionApprovedEvent'] = ResolversParentTypes['SubscriptionApprovedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['IndexSubscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionDistributionClaimedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SubscriptionDistributionClaimedEvent'] = ResolversParentTypes['SubscriptionDistributionClaimedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['IndexSubscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionRevokedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SubscriptionRevokedEvent'] = ResolversParentTypes['SubscriptionRevokedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['IndexSubscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionUnitsUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SubscriptionUnitsUpdatedEvent'] = ResolversParentTypes['SubscriptionUnitsUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  subscriber?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  publisher?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  indexId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  userData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oldUnits?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['IndexSubscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuperTokenCreatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SuperTokenCreatedEvent'] = ResolversParentTypes['SuperTokenCreatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuperTokenFactoryUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SuperTokenFactoryUpdatedEvent'] = ResolversParentTypes['SuperTokenFactoryUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  newFactory?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuperTokenLogicCreatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SuperTokenLogicCreatedEvent'] = ResolversParentTypes['SuperTokenLogicCreatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  tokenLogic?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuperTokenLogicUpdatedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SuperTokenLogicUpdatedEvent'] = ResolversParentTypes['SuperTokenLogicUpdatedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuperTokenMinimumDepositChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SuperTokenMinimumDepositChangedEvent'] = ResolversParentTypes['SuperTokenMinimumDepositChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minimumDeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TokenResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isSuperToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNativeAssetSuperToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isListed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  underlyingAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  underlyingToken?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType>;
  governanceConfig?: Resolver<Maybe<ResolversTypes['TokenGovernanceConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenDowngradedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenDowngradedEvent'] = ResolversParentTypes['TokenDowngradedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenGovernanceConfigResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenGovernanceConfig'] = ResolversParentTypes['TokenGovernanceConfig']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  createdAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rewardAddress?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  liquidationPeriod?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  patricianPeriod?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  minimumDeposit?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenStatisticResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenStatistic'] = ResolversParentTypes['TokenStatistic']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAtTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  updatedAtBlockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalNumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfIndexes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfActiveIndexes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSubscriptionsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalApprovedSubscriptions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfPools?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfActivePools?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMembershipsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnectedMemberships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountTransferredUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountDistributedUntilUpdatedAt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSupply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalNumberOfAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfHolders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  tokenStatisticLogs?: Resolver<Array<ResolversTypes['TokenStatisticLog']>, ParentType, ContextType, RequireFields<TokenStatisticTokenStatisticLogsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenStatisticLogResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenStatisticLog'] = ResolversParentTypes['TokenStatisticLog']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  triggeredByEventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalNumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfActiveStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCFANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGDANumberOfClosedStreams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfIndexes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfActiveIndexes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSubscriptionsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalApprovedSubscriptions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfPools?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfActivePools?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMembershipsWithUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnectedMemberships?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDADeposit?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalGDAOutflowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountStreamed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalCFAAmountStreamed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountTransferred?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAmountDistributed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSupply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalNumberOfAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumberOfHolders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  tokenStatistic?: Resolver<ResolversTypes['TokenStatistic'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenUpgradedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenUpgradedEvent'] = ResolversParentTypes['TokenUpgradedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TransferEvent'] = ResolversParentTypes['TransferEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TrustedForwarderChangedEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TrustedForwarderChangedEvent'] = ResolversParentTypes['TrustedForwarderChangedEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  gasPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasUsed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  governanceAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  addresses?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  logIndex?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  superToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  isKeySet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  forwarder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Account?: AccountResolvers<ContextType>;
  AccountTokenSnapshot?: AccountTokenSnapshotResolvers<ContextType>;
  AccountTokenSnapshotLog?: AccountTokenSnapshotLogResolvers<ContextType>;
  AgreementClassRegisteredEvent?: AgreementClassRegisteredEventResolvers<ContextType>;
  AgreementClassUpdatedEvent?: AgreementClassUpdatedEventResolvers<ContextType>;
  AgreementLiquidatedByEvent?: AgreementLiquidatedByEventResolvers<ContextType>;
  AgreementLiquidatedV2Event?: AgreementLiquidatedV2EventResolvers<ContextType>;
  AppRegisteredEvent?: AppRegisteredEventResolvers<ContextType>;
  ApprovalEvent?: ApprovalEventResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  BondIncreasedEvent?: BondIncreasedEventResolvers<ContextType>;
  BufferAdjustedEvent?: BufferAdjustedEventResolvers<ContextType>;
  BurnedEvent?: BurnedEventResolvers<ContextType>;
  Bytes?: GraphQLScalarType;
  CFAv1LiquidationPeriodChangedEvent?: CfAv1LiquidationPeriodChangedEventResolvers<ContextType>;
  ConfigChangedEvent?: ConfigChangedEventResolvers<ContextType>;
  CustomSuperTokenCreatedEvent?: CustomSuperTokenCreatedEventResolvers<ContextType>;
  DistributionClaimedEvent?: DistributionClaimedEventResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  ExitRateChangedEvent?: ExitRateChangedEventResolvers<ContextType>;
  FlowDistributionUpdatedEvent?: FlowDistributionUpdatedEventResolvers<ContextType>;
  FlowOperator?: FlowOperatorResolvers<ContextType>;
  FlowOperatorUpdatedEvent?: FlowOperatorUpdatedEventResolvers<ContextType>;
  FlowUpdatedEvent?: FlowUpdatedEventResolvers<ContextType>;
  GovernanceReplacedEvent?: GovernanceReplacedEventResolvers<ContextType>;
  Index?: IndexResolvers<ContextType>;
  IndexCreatedEvent?: IndexCreatedEventResolvers<ContextType>;
  IndexDistributionClaimedEvent?: IndexDistributionClaimedEventResolvers<ContextType>;
  IndexSubscribedEvent?: IndexSubscribedEventResolvers<ContextType>;
  IndexSubscription?: IndexSubscriptionResolvers<ContextType>;
  IndexUnitsUpdatedEvent?: IndexUnitsUpdatedEventResolvers<ContextType>;
  IndexUnsubscribedEvent?: IndexUnsubscribedEventResolvers<ContextType>;
  IndexUpdatedEvent?: IndexUpdatedEventResolvers<ContextType>;
  InstantDistributionUpdatedEvent?: InstantDistributionUpdatedEventResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  JailEvent?: JailEventResolvers<ContextType>;
  MemberUnitsUpdatedEvent?: MemberUnitsUpdatedEventResolvers<ContextType>;
  MintedEvent?: MintedEventResolvers<ContextType>;
  NewPICEvent?: NewPicEventResolvers<ContextType>;
  PPPConfigurationChangedEvent?: PppConfigurationChangedEventResolvers<ContextType>;
  Pool?: PoolResolvers<ContextType>;
  PoolConnectionUpdatedEvent?: PoolConnectionUpdatedEventResolvers<ContextType>;
  PoolCreatedEvent?: PoolCreatedEventResolvers<ContextType>;
  PoolDistributor?: PoolDistributorResolvers<ContextType>;
  PoolMember?: PoolMemberResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ResolverEntry?: ResolverEntryResolvers<ContextType>;
  RewardAddressChangedEvent?: RewardAddressChangedEventResolvers<ContextType>;
  RoleAdminChangedEvent?: RoleAdminChangedEventResolvers<ContextType>;
  RoleGrantedEvent?: RoleGrantedEventResolvers<ContextType>;
  RoleRevokedEvent?: RoleRevokedEventResolvers<ContextType>;
  SFMeta?: SfMetaResolvers<ContextType>;
  SentEvent?: SentEventResolvers<ContextType>;
  SetEvent?: SetEventResolvers<ContextType>;
  Stream?: StreamResolvers<ContextType>;
  StreamPeriod?: StreamPeriodResolvers<ContextType>;
  StreamRevision?: StreamRevisionResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionApprovedEvent?: SubscriptionApprovedEventResolvers<ContextType>;
  SubscriptionDistributionClaimedEvent?: SubscriptionDistributionClaimedEventResolvers<ContextType>;
  SubscriptionRevokedEvent?: SubscriptionRevokedEventResolvers<ContextType>;
  SubscriptionUnitsUpdatedEvent?: SubscriptionUnitsUpdatedEventResolvers<ContextType>;
  SuperTokenCreatedEvent?: SuperTokenCreatedEventResolvers<ContextType>;
  SuperTokenFactoryUpdatedEvent?: SuperTokenFactoryUpdatedEventResolvers<ContextType>;
  SuperTokenLogicCreatedEvent?: SuperTokenLogicCreatedEventResolvers<ContextType>;
  SuperTokenLogicUpdatedEvent?: SuperTokenLogicUpdatedEventResolvers<ContextType>;
  SuperTokenMinimumDepositChangedEvent?: SuperTokenMinimumDepositChangedEventResolvers<ContextType>;
  Timestamp?: GraphQLScalarType;
  Token?: TokenResolvers<ContextType>;
  TokenDowngradedEvent?: TokenDowngradedEventResolvers<ContextType>;
  TokenGovernanceConfig?: TokenGovernanceConfigResolvers<ContextType>;
  TokenStatistic?: TokenStatisticResolvers<ContextType>;
  TokenStatisticLog?: TokenStatisticLogResolvers<ContextType>;
  TokenUpgradedEvent?: TokenUpgradedEventResolvers<ContextType>;
  TransferEvent?: TransferEventResolvers<ContextType>;
  TrustedForwarderChangedEvent?: TrustedForwarderChangedEventResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: EntityDirectiveResolver<any, any, ContextType>;
  subgraphId?: SubgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: DerivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = ProtocolTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/protocol/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const protocolTransforms = [];
const additionalTypeDefs = [] as any[];
const protocolHandler = new GraphqlHandler({
              name: "protocol",
              config: {"endpoint":"{context.url:https://base-mainnet.subgraph.x.superfluid.dev}","retry":5},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("protocol"),
              logger: logger.child("protocol"),
              importFn,
            });
sources[0] = {
          name: 'protocol',
          handler: protocolHandler,
          transforms: protocolTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })
const documentHashMap = {
        "72a7acfe00b42e516ceca349c82872c9f084e89310238da06adfd46fb9f47135": ReferralRewardsDocument,
"153dfff0052b4f40b330eaca678aa8350180b651e758bc660727f7d15fd6b0a9": PortfolioDocument,
"f842a80613eb9c5b474fefa9e9cba93f5fbe2a8a3d5132103c54a0a481bf923e": TotalAmountFlowDistributedDocument,
"e4178e8fceeba2b44d23263f7f5e5d58704af2ff08dd27c520cec7e3b0f91a89": LiquidityMovementsDocument,
"b170d0acdbf18d3874de72b44c1e8fc870e5fdbff692b3bf651e47907aef5531": ReferralsDocument,
"92d7317028cea6721a6cbcc434d7614d15feeb32199e7c9586b4f763d753e077": StreamsDocument
      }
additionalEnvelopPlugins.push(usePersistedOperations({
        getPersistedOperation(key) {
          return documentHashMap[key];
        },
        ...{}
      }))

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: ReferralRewardsDocument,
        get rawSDL() {
          return printWithCache(ReferralRewardsDocument);
        },
        location: 'ReferralRewardsDocument.graphql',
        sha256Hash: '72a7acfe00b42e516ceca349c82872c9f084e89310238da06adfd46fb9f47135'
      },{
        document: PortfolioDocument,
        get rawSDL() {
          return printWithCache(PortfolioDocument);
        },
        location: 'PortfolioDocument.graphql',
        sha256Hash: '153dfff0052b4f40b330eaca678aa8350180b651e758bc660727f7d15fd6b0a9'
      },{
        document: TotalAmountFlowDistributedDocument,
        get rawSDL() {
          return printWithCache(TotalAmountFlowDistributedDocument);
        },
        location: 'TotalAmountFlowDistributedDocument.graphql',
        sha256Hash: 'f842a80613eb9c5b474fefa9e9cba93f5fbe2a8a3d5132103c54a0a481bf923e'
      },{
        document: LiquidityMovementsDocument,
        get rawSDL() {
          return printWithCache(LiquidityMovementsDocument);
        },
        location: 'LiquidityMovementsDocument.graphql',
        sha256Hash: 'e4178e8fceeba2b44d23263f7f5e5d58704af2ff08dd27c520cec7e3b0f91a89'
      },{
        document: ReferralsDocument,
        get rawSDL() {
          return printWithCache(ReferralsDocument);
        },
        location: 'ReferralsDocument.graphql',
        sha256Hash: 'b170d0acdbf18d3874de72b44c1e8fc870e5fdbff692b3bf651e47907aef5531'
      },{
        document: StreamsDocument,
        get rawSDL() {
          return printWithCache(StreamsDocument);
        },
        location: 'StreamsDocument.graphql',
        sha256Hash: '92d7317028cea6721a6cbcc434d7614d15feeb32199e7c9586b4f763d753e077'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export const pollingInterval = null;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    if (pollingInterval) {
      setInterval(() => {
        getMeshOptions()
        .then(meshOptions => getMesh(meshOptions))
        .then(newMesh =>
          meshInstance$.then(oldMesh => {
            oldMesh.destroy()
            meshInstance$ = Promise.resolve(newMesh)
          })
        ).catch(err => {
          console.error("Mesh polling failed so the existing version will be used:", err);
        });
      }, pollingInterval)
    }
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type LiquidityMovementsQueryVariables = Exact<{
  poolId: Scalars['String']['input'];
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type LiquidityMovementsQuery = { events: Array<(
    Pick<InstantDistributionUpdatedEvent, 'timestamp' | 'transactionHash' | 'operator'>
    & { pool: Pick<Pool, 'id'> }
  )> };

export type PortfolioQueryVariables = Exact<{
  sender: Scalars['String']['input'];
  poolIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  torexes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type PortfolioQuery = { streams: Array<(
    Pick<Stream, 'createdAtTimestamp' | 'updatedAtTimestamp' | 'currentFlowRate' | 'streamedUntilUpdatedAt'>
    & { sender: Pick<Account, 'id'>, receiver: Pick<Account, 'id'>, token: Pick<Token, 'decimals' | 'id' | 'isListed' | 'isNativeAssetSuperToken' | 'isSuperToken' | 'name' | 'symbol'> }
  )>, pools: Array<(
    Pick<Pool, 'id' | 'totalUnits' | 'flowRate' | 'updatedAtTimestamp' | 'totalAmountDistributedUntilUpdatedAt'>
    & { poolMembers: Array<(
      Pick<PoolMember, 'units' | 'poolTotalAmountDistributedUntilUpdatedAt' | 'totalAmountReceivedUntilUpdatedAt'>
      & { account: Pick<Account, 'id'> }
    )> }
  )>, transferEvents: Array<(
    Pick<TransferEvent, 'value' | 'token'>
    & { to: Pick<Account, 'id'>, from: Pick<Account, 'id'> }
  )> };

export type ReferralRewardsQueryVariables = Exact<{
  referees: Array<Scalars['String']['input']> | Scalars['String']['input'];
  rewardToken: Scalars['String']['input'];
}>;


export type ReferralRewardsQuery = { pools: Array<{ poolMembers: Array<(
      Pick<PoolMember, 'units' | 'syncedPerUnitFlowRate' | 'updatedAtTimestamp' | 'poolTotalAmountDistributedUntilUpdatedAt' | 'totalAmountReceivedUntilUpdatedAt'>
      & { account: Pick<Account, 'id'> }
    )> }> };

export type StreamsQueryVariables = Exact<{
  torexes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  first: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
}>;


export type StreamsQuery = { streams: Array<(
    Pick<Stream, 'createdAtTimestamp' | 'updatedAtTimestamp' | 'currentFlowRate' | 'streamedUntilUpdatedAt'>
    & { sender: Pick<Account, 'id'>, receiver: Pick<Account, 'id'>, token: Pick<Token, 'id' | 'symbol'> }
  )> };

export type TotalAmountFlowDistributedQueryVariables = Exact<{
  poolId: Scalars['ID']['input'];
}>;


export type TotalAmountFlowDistributedQuery = { pool?: Maybe<Pick<Pool, 'totalAmountFlowedDistributedUntilUpdatedAt'>> };

export type GdaPoolFragmentFragment = (
  Pick<Pool, 'id' | 'createdAtTimestamp' | 'createdAtBlockNumber' | 'updatedAtTimestamp' | 'updatedAtBlockNumber' | 'flowRate' | 'totalMembers' | 'totalUnits' | 'totalAmountDistributedUntilUpdatedAt' | 'adjustmentFlowRate' | 'totalAmountFlowedDistributedUntilUpdatedAt' | 'totalAmountInstantlyDistributedUntilUpdatedAt' | 'totalBuffer' | 'totalConnectedMembers' | 'totalConnectedUnits' | 'totalDisconnectedMembers' | 'totalDisconnectedUnits'>
  & { admin: Pick<Account, 'id'>, token: Pick<Token, 'id' | 'symbol'>, poolCreatedEvent: Pick<PoolCreatedEvent, 'id'> }
);

export type TokenFragmentFragment = Pick<Token, 'decimals' | 'id' | 'isListed' | 'isNativeAssetSuperToken' | 'isSuperToken' | 'name' | 'symbol'>;

export type TransferEventFragmentFragment = Pick<TransferEvent, 'id' | 'transactionHash' | 'gasPrice' | 'gasUsed' | 'timestamp' | 'name' | 'addresses' | 'blockNumber' | 'logIndex' | 'order' | 'value' | 'token'>;

export type ReferralsQueryVariables = Exact<{
  userData: Scalars['Bytes']['input'];
  torexes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ReferralsQuery = { streams: Array<{ sender: Pick<Account, 'id'>, receiver: Pick<Account, 'id'>, token: Pick<Token, 'decimals' | 'id' | 'isListed' | 'isNativeAssetSuperToken' | 'isSuperToken' | 'name' | 'symbol'> }> };

export const GdaPoolFragmentFragmentDoc = gql`
    fragment GDAPoolFragment on Pool {
  id
  createdAtTimestamp
  createdAtBlockNumber
  updatedAtTimestamp
  updatedAtBlockNumber
  flowRate
  totalMembers
  totalUnits
  totalAmountDistributedUntilUpdatedAt
  adjustmentFlowRate
  totalAmountFlowedDistributedUntilUpdatedAt
  totalAmountInstantlyDistributedUntilUpdatedAt
  totalBuffer
  totalConnectedMembers
  totalConnectedUnits
  totalDisconnectedMembers
  totalDisconnectedUnits
  admin {
    id
  }
  token {
    id
    symbol
  }
  poolCreatedEvent {
    id
  }
}
    ` as unknown as DocumentNode<GdaPoolFragmentFragment, unknown>;
export const TokenFragmentFragmentDoc = gql`
    fragment TokenFragment on Token {
  decimals
  id
  isListed
  isNativeAssetSuperToken
  isSuperToken
  name
  symbol
}
    ` as unknown as DocumentNode<TokenFragmentFragment, unknown>;
export const TransferEventFragmentFragmentDoc = gql`
    fragment TransferEventFragment on TransferEvent {
  id
  transactionHash
  gasPrice
  gasUsed
  timestamp
  name
  addresses
  blockNumber
  logIndex
  order
  value
  token
}
    ` as unknown as DocumentNode<TransferEventFragmentFragment, unknown>;
export const LiquidityMovementsDocument = gql`
    query LiquidityMovements($poolId: String!, $timestamp_gte: BigInt, $first: Int = 1000) {
  events: instantDistributionUpdatedEvents(
    first: $first
    orderDirection: desc
    orderBy: timestamp
    where: {pool: $poolId}
  ) {
    timestamp
    transactionHash
    pool {
      id
    }
    operator
  }
}
    ` as unknown as DocumentNode<LiquidityMovementsQuery, LiquidityMovementsQueryVariables>;
export const PortfolioDocument = gql`
    query Portfolio($sender: String!, $poolIds: [ID!]!, $torexes: [String!]!) {
  streams(where: {and: [{sender: $sender}, {receiver_in: $torexes}]}) {
    createdAtTimestamp
    updatedAtTimestamp
    currentFlowRate
    streamedUntilUpdatedAt
    sender {
      id
    }
    receiver {
      id
    }
    token {
      ...TokenFragment
    }
  }
  pools(where: {id_in: $poolIds}) {
    id
    totalUnits
    flowRate
    updatedAtTimestamp
    totalAmountDistributedUntilUpdatedAt
    poolMembers(where: {account: $sender}) {
      account {
        id
      }
      units
      poolTotalAmountDistributedUntilUpdatedAt
      totalAmountReceivedUntilUpdatedAt
    }
  }
  transferEvents(
    where: {or: [{from_contains_nocase: $sender, to_in: $torexes}, {from_in: $torexes, to_contains_nocase: $sender}]}
    orderBy: order
    orderDirection: asc
  ) {
    value
    token
    to {
      id
    }
    from {
      id
    }
  }
}
    ${TokenFragmentFragmentDoc}` as unknown as DocumentNode<PortfolioQuery, PortfolioQueryVariables>;
export const ReferralRewardsDocument = gql`
    query ReferralRewards($referees: [String!]!, $rewardToken: String!) {
  pools(where: {token: $rewardToken}) {
    poolMembers(where: {account_in: $referees}) {
      account {
        id
      }
      units
      syncedPerUnitFlowRate
      updatedAtTimestamp
      poolTotalAmountDistributedUntilUpdatedAt
      totalAmountReceivedUntilUpdatedAt
    }
  }
}
    ` as unknown as DocumentNode<ReferralRewardsQuery, ReferralRewardsQueryVariables>;
export const StreamsDocument = gql`
    query Streams($torexes: [String!]!, $first: Int!, $skip: Int!) {
  streams(
    where: {receiver_in: $torexes}
    orderBy: createdAtTimestamp
    orderDirection: asc
    first: $first
    skip: $skip
  ) {
    createdAtTimestamp
    updatedAtTimestamp
    currentFlowRate
    streamedUntilUpdatedAt
    sender {
      id
    }
    receiver {
      id
    }
    token {
      id
      symbol
    }
  }
}
    ` as unknown as DocumentNode<StreamsQuery, StreamsQueryVariables>;
export const TotalAmountFlowDistributedDocument = gql`
    query TotalAmountFlowDistributed($poolId: ID!) {
  pool(id: $poolId) {
    totalAmountFlowedDistributedUntilUpdatedAt
  }
}
    ` as unknown as DocumentNode<TotalAmountFlowDistributedQuery, TotalAmountFlowDistributedQueryVariables>;
export const ReferralsDocument = gql`
    query Referrals($userData: Bytes!, $torexes: [String!]!) {
  streams(where: {and: [{userData: $userData}, {receiver_in: $torexes}]}) {
    sender {
      id
    }
    receiver {
      id
    }
    token {
      ...TokenFragment
    }
  }
}
    ${TokenFragmentFragmentDoc}` as unknown as DocumentNode<ReferralsQuery, ReferralsQueryVariables>;







export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    LiquidityMovements(variables: LiquidityMovementsQueryVariables, options?: C): Promise<LiquidityMovementsQuery> {
      return requester<LiquidityMovementsQuery, LiquidityMovementsQueryVariables>(LiquidityMovementsDocument, variables, options) as Promise<LiquidityMovementsQuery>;
    },
    Portfolio(variables: PortfolioQueryVariables, options?: C): Promise<PortfolioQuery> {
      return requester<PortfolioQuery, PortfolioQueryVariables>(PortfolioDocument, variables, options) as Promise<PortfolioQuery>;
    },
    ReferralRewards(variables: ReferralRewardsQueryVariables, options?: C): Promise<ReferralRewardsQuery> {
      return requester<ReferralRewardsQuery, ReferralRewardsQueryVariables>(ReferralRewardsDocument, variables, options) as Promise<ReferralRewardsQuery>;
    },
    Streams(variables: StreamsQueryVariables, options?: C): Promise<StreamsQuery> {
      return requester<StreamsQuery, StreamsQueryVariables>(StreamsDocument, variables, options) as Promise<StreamsQuery>;
    },
    TotalAmountFlowDistributed(variables: TotalAmountFlowDistributedQueryVariables, options?: C): Promise<TotalAmountFlowDistributedQuery> {
      return requester<TotalAmountFlowDistributedQuery, TotalAmountFlowDistributedQueryVariables>(TotalAmountFlowDistributedDocument, variables, options) as Promise<TotalAmountFlowDistributedQuery>;
    },
    Referrals(variables: ReferralsQueryVariables, options?: C): Promise<ReferralsQuery> {
      return requester<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, variables, options) as Promise<ReferralsQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;